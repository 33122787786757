import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthProvider , { requireAuth as RequireAuth, useAuth } from './provider/AuthProvider'
import Login from './views/auth/Login';
import Dashboard from './views/dashbord/Dashboard';
import ListPatient from './views/dashbord/patient/List';
import DashboardIndex from './views/dashbord/index';
import ListRdv from './views/dashbord/rdv/List';
import TableRDV from './views/dashbord/rdv/Table';
import CalendrierRDV from './views/dashbord/rdv/Calendrier';
import ListActe from './views/dashbord/acte/List';
import ListService from './views/dashbord/service/List';
import ListConsultation from './views/dashbord/consultation/List';
import AddConsultation from './views/dashbord/consultation/Add';
import ListHospitalisation from './views/dashbord/hospitalisation/List';
import ListSoin from './views/dashbord/soin/List';
import ChronogieActe from './views/dashbord/acte/Chronogie';
import TableActe from './views/dashbord/acte/Table';
import ListPersonnel from './views/dashbord/personnel/List';
import ListFacture from './views/dashbord/facture/List';
import ListRecu from './views/dashbord/recu/List';
import EntamerConsultation from './views/dashbord/consultation/Entamer';
import ListRDV from './views/dashbord/rdv/List';
import ListAssurance from './views/dashbord/assurance/List';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import AdminAppSetting from "./views/dashbord/AdminAppSetting";
import UserProfilePage from "./views/dashbord/UserProfile";
import ListRole from "./views/dashbord/role/List";
import DossierMedical from "./views/dashbord/patient/DossierMedical";
import ListTypeSoin from "./views/dashbord/typeSoin/List";
import ListBonExamen from "./views/dashbord/bon_examen/List";
import 'dayjs/locale/fr';
import dayjs from "dayjs";
import ListOrdonnance from "./views/dashbord/ordonnance/List";

dayjs.locale('fr');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
    },
  },
});
function App() {
  let auth = useAuth();
  return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={ !auth?.user ? <Navigate to="/login" /> :  <Navigate to="/dashboard" />} />
              <Route path="login" element={ !auth?.user ? <Login /> :  <Navigate to="/dashboard" />} />
              <Route path="dashboard" element={<DashboardIndex />}>
                <Route index element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
                <Route path="profil" element={<RequireAuth><UserProfilePage role={"admin"} /></RequireAuth>} />
                <Route path=":id/dossier-medical" element={<RequireAuth><DossierMedical /></RequireAuth>} />
                <Route path="list-service" element={<RequireAuth><ListService /></RequireAuth>} />
                <Route path="add-consultation/:consultationId" element={<RequireAuth><AddConsultation /></RequireAuth>} />
                <Route path="entamer-consultation" element={<RequireAuth><EntamerConsultation /></RequireAuth>} />
                <Route path="list-consultation" element={<RequireAuth><ListConsultation /></RequireAuth>} />
                <Route path="list-ordonnance" element={<RequireAuth><ListOrdonnance /></RequireAuth>} />
                <Route path="list-hospitalisation" element={<RequireAuth><ListHospitalisation /></RequireAuth>} />
                <Route path="list-patient" element={<RequireAuth><ListPatient /></RequireAuth>} />
                <Route path="list-rdv" element={<RequireAuth><ListRdv /></RequireAuth>} />
                <Route path="list-soin" element={<RequireAuth><ListSoin /></RequireAuth>} />
                <Route path="list-bons-examens" element={<RequireAuth><ListBonExamen /></RequireAuth>} />
                <Route path="list-acte" element={<RequireAuth><ListActe /></RequireAuth>} >
                  <Route path="list-acte-table" element={<RequireAuth><TableActe /></RequireAuth>} />
                  <Route path="chronologie-acte" element={<RequireAuth><ChronogieActe /></RequireAuth>} />
                </Route>
                <Route path="list-personnel" element={<RequireAuth><ListPersonnel /></RequireAuth>} />
                <Route path="list-facture" element={<RequireAuth><ListFacture /></RequireAuth>} />
                <Route path="list-recu" element={<RequireAuth><ListRecu /></RequireAuth>} />
                <Route path="list-assurance" element={<RequireAuth><ListAssurance /></RequireAuth>} />
                <Route path="list-type-soin" element={<RequireAuth><ListTypeSoin /></RequireAuth>} />
                <Route path="list-rdv" element={<RequireAuth><ListRDV /></RequireAuth>} >
                  <Route index element={<RequireAuth><TableRDV /></RequireAuth>} />
                  <Route path="calendrier-rdv" element={<RequireAuth><CalendrierRDV /></RequireAuth>} />
                </Route>
                <Route path="admin-app-setting" element={<RequireAuth><AdminAppSetting /></RequireAuth>} >
                  <Route index element={<RequireAuth><ListRole /></RequireAuth>} />
                  <Route path="list-service" element={<RequireAuth><ListService /></RequireAuth>} />
                  <Route path="list-assurance" element={<RequireAuth><ListAssurance /></RequireAuth>} />
                  <Route path="list-type-soin" element={<RequireAuth><ListTypeSoin /></RequireAuth>} />
                </Route>
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
  );
}

export default App;