export enum TypeAntecedentType {
    FAMILIAL = "FAMILIAL",
    PERSONNEL = "PERSONNEL",
    CHIRURGICAL = "CHIRURGICAL",
    ALLERGIE = "ALLERGIE",
}

export interface AntecedentType {
    id: number;
    nom: string;
    type: string;
}