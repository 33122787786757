import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#000',
        backgroundColor: '#fff',
        color: "#000",
        borderBottomWidth: 1,
        alignItems: 'center',
        textTransform: 'uppercase',
        fontStyle: 'bold',
        flexGrow: 1,
        paddingVertical: 4,
    },
    examen: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    result: {
        width: '50%',
        paddingLeft: 8,
    },
});

const ExamenTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.examen}>Examens Demandés</Text>
        <Text style={styles.result}>Résultats</Text>
    </View>
);

export default ExamenTableHeader