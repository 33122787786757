import {
    AccountBookOutlined,
    ApartmentOutlined,
    AuditOutlined,
    BankOutlined,
    CalendarOutlined,
    DashboardOutlined,
    ExperimentOutlined,
    FileOutlined,
    FileProtectOutlined,
    ForkOutlined,
    MedicineBoxOutlined,
    MoneyCollectOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Link} from "react-router-dom";
import React from 'react';
import {Role} from './auth';

export interface NavLink {
  label: React.ReactNode;
  key: string ;
  icon?: React.ReactNode;
  children?: Array<NavLink>;
  can_view?: Array<string>
}

export const navLinks: Array<NavLink>  = [
    { 
      label: <Link to="">Accueil</Link>, 
      key: "home", 
      icon: <DashboardOutlined />,
      can_view: ["All"]      
    },
    { 
      label: <Link to="list-personnel">Personnels</Link>,
      key: "personnel", 
      icon: <TeamOutlined />,
      can_view: [Role.Admin, Role.Secretaire, Role.Dga, Role.Daf]      
    },
    { 
      label: <Link to="list-patient">Patients</Link>,
      key: "patient", 
      icon: <UserOutlined />,
      can_view: [Role.Admin, Role.Secretaire, Role.Caissier, Role.Dga, Role.Daf]
    },
    { 
      label: <Link to="list-service" className="hover:text-primary" >Service</Link>,
      key: "service",
      icon: <ApartmentOutlined />,
      can_view: [Role.Secretaire, Role.Caissier, Role.Dga, Role.Daf]
    },      
    { 
      label: <Link to="list-rdv">Rendez-vous</Link>,
      key: "rdv", 
      icon: <CalendarOutlined />,
      can_view: [Role.Admin, Role.Secretaire, Role.Dga, Role.Daf]      
    },
    {
        label: <Link to="list-acte/list-acte-table">Acte</Link>,
        key: "acte",
        icon: <AccountBookOutlined />,
        can_view: [Role.Admin, Role.Medecin, Role.Infirmier, Role.Dga, Role.Dga]
    },
    {
        label: <Link to="list-bons-examens">Examen</Link>,
        key: "bon-examens",
        icon: <ForkOutlined />,
        can_view: [Role.Admin, Role.Medecin, Role.Infirmier, Role.Caissier, Role.Dga, Role.Daf]
    },
    {
        label: <Link to="list-ordonnance" className="hover:text-primary" >Ordonance</Link>,
        key: "ordonnance",
        icon: <AuditOutlined />,
        can_view: [Role.Admin, Role.Medecin, Role.Dga, Role.Daf]
    },
    { 
      label: <Link to="list-soin">Soin</Link>,
      key: "soin", 
      icon: <ExperimentOutlined />,
      can_view: [Role.Admin, Role.Medecin, Role.Infirmier, Role.Dga, Role.Daf]      
    },
    { 
      label: <Link to="list-consultation" className="hover:text-primary" >Consultation</Link>,
      key: "consultation", 
      icon: <AuditOutlined />,
      can_view: [Role.Admin, Role.Medecin, Role.Secretaire, Role.Dga, Role.Daf]      
    },
    { 
      label: <Link to="list-hospitalisation">Hospitalisation</Link>,
      key: "hospitalisation", 
      icon: <BankOutlined />,
      can_view: [Role.Admin, Role.Medecin, Role.Secretaire, Role.Dga, Role.Daf]      
    },
    { 
      label: <Link to="list-facture">Facture</Link>,
      key: "facture",
      icon: <MoneyCollectOutlined />,
      can_view: [Role.Admin, Role.Caissier, Role.Dga, Role.Daf]      
    },
    { 
      label:  <Link to="list-recu">Reçu</Link>,
      key: "recu", 
      icon: <FileOutlined />,
      can_view: [Role.Admin, Role.Caissier, Role.Dga, Role.Daf]      
    },
    { 
      label: <Link to="list-assurance" >Assurance</Link>,
      key: "assurance", 
      icon: <FileProtectOutlined />,
      can_view: [Role.Caissier, Role.Dga, Role.Daf]
    },
      {
        label: <Link to="list-type-soin" >Type Soin</Link>,
        key: "type-soin",
        icon: <MedicineBoxOutlined />,
        can_view: [Role.Infirmier, Role.Dga, Role.Daf]
      },
    {
      label: <Link to="admin-app-setting">Paramétrage</Link>,
      key: "admin-app-setting",
      icon: <SettingOutlined />,
      can_view: [Role.Admin]
    },
  ];
  