import {Document, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import React from "react";
import ExamenItemsTable from "./ExamenItemsTable";
import {BonExamenType} from "../../models/BonExamen";
import {getAge, getFullName} from "../../utilities/Utils";
import HeaderPDF from "../HeaderPDF";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
        alignSelf: 'center',
        backgroundColor: '#fff',
    },
    view: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    rows: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    label: {
        fontWeight: 'bold',
        color: '#000',
    },
    value: {
        flex: 1,
        color: '#0c4b8d',
        borderBottom: '1pt dashed #000',
        textAlign: 'center',
    },
});



interface BonExamenPdfInterface {
    bonExamen?: BonExamenType
}
const BonExamenPdf = ({bonExamen}:BonExamenPdfInterface) => {
    return (
        <Document title={`bon_examen_${bonExamen?.id}`}>
            <Page size={[468, 680]} fixed orientation={"landscape"} style={styles.page}>
                <View style={styles.view} >
                    <HeaderPDF title={"Bon d'Examen"} />
                    <View style={styles.rows}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Noms: </Text>
                            <Text style={styles.value}>{getFullName(bonExamen?.dossierMedical?.patient ?? undefined)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Age: </Text>
                            <Text style={styles.value}>
                                {
                                    bonExamen?.dossierMedical?.patient.dateDeNaissance
                                    ? getAge(bonExamen?.dossierMedical?.patient.dateDeNaissance)
                                    : ""
                                }
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Sexe (M/F): </Text>
                            <Text style={styles.value}>{bonExamen?.dossierMedical?.patient.sexe}</Text>
                        </View>
                    </View>
                    <View style={styles.rows}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Service: </Text>
                            <Text style={styles.value}>{bonExamen?.service.nom}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Salle: </Text>
                            <Text style={styles.value}>{bonExamen?.salle}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Reseignements cliniques: </Text>
                        <Text style={styles.value}>{bonExamen?.renseignementClinique}</Text>
                    </View>
                    <ExamenItemsTable examens={bonExamen?.examens}/>
                </View>
            </Page>
        </Document>
    );
};

interface BonExamenPdfViewerProps {
    bonExamen?: BonExamenType
}
const BonExamenPdfViewer = (
    {bonExamen} : BonExamenPdfViewerProps
) => {

    return (
        <div className="container flex justify-center">
            <PDFViewer
                style={{
                    border: "none",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                }}
                width="100%"
                height={600}
            >
                <BonExamenPdf bonExamen={bonExamen} />
            </PDFViewer>
        </div>
    );
};

export default BonExamenPdfViewer;
