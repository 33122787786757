import React, {useCallback, useEffect, useState} from 'react';
import {Form, FormInstance, Input, InputNumber, Modal, Radio, RadioChangeEvent, Select} from 'antd';
import {FactureType} from "../../models/Facture";
import {emptyFieldError} from "../../utilities/Utils";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: FactureType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<FactureType>;
    updated: boolean
}

export const FactureFormModal: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
    form,
    updated
                                                                        }) => {
    const [hasReduction, setHasReduction] = useState(false);
    const reduction = Form.useWatch('pourcentageReduction', form);
    const partPayeePatient = Form.useWatch('partPayeePatient', form);
    const partPayeeAssurance = Form.useWatch('partPayeeAssurance', form);
    const montantTotal = Form.useWatch('montantTotal', form);
    const [totalMontant, setTotalMontant] = useState(0);

    const handleReduction = (e: RadioChangeEvent) => {
        setHasReduction(e.target.value);
    };

    const totalRestAPayer = () => {
        try {
            if(hasReduction) {
                form.setFieldValue("totalMontant", montantTotal - (montantTotal * reduction / 100));
            }
            const total = montantTotal - (partPayeePatient + partPayeeAssurance);
            form.setFieldValue("resteAPayer", total > montantTotal ? 0 : total);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setTotalMontant(form.getFieldValue("totalMontant"))
    }, [form]);


    useEffect(() => {
        totalRestAPayer();
    }, [hasReduction, montantTotal, reduction, partPayeePatient, partPayeeAssurance]);


    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour de la facture" : "Facturation"}
            okText={updated ? "Modifier" : "Facturer"}
            cancelText="Annuler"
            onCancel={() => {
                onCancel();
                setHasReduction(false);
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            maskClosable={false}
            width={800}
        >
            <Form
                form={form}
                name="form_in_modal"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item
                    name="reference"
                    label="Reference"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    name="montantTotal"
                    label="Montant total"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <InputNumber className="w-full" addonAfter="FCFA" />
                </Form.Item>
                <Form.Item
                    name="partPayeePatient"
                    label="Part payée par le client"
                    rules={[{
                        required: true,
                        message: emptyFieldError,
                    }]}
                >
                    <InputNumber max={form.getFieldValue("montantTotal")} className="w-full" addonAfter="FCFA" />
                </Form.Item>
                <Form.Item
                    name="partPayeeAssurance"
                    label="Part payée par l'assurance"
                >
                    <InputNumber max={form.getFieldValue("montantTotal")} className="w-full" addonAfter="FCFA" />
                </Form.Item>
                <Form.Item
                    name="reductionAppliquee"
                    label="Appliquez une reduction ?"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Radio.Group onChange={handleReduction} defaultValue={false}>
                        <Radio value={false}>Non</Radio>
                        <Radio value={true}>Oui</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="pourcentageReduction"
                    label="Pourcentage de la reduction"
                    rules={[{ required: true, message: emptyFieldError}]}
                    hidden={!hasReduction}
                >
                    <InputNumber
                        className="w-full"
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value: any) => value!.replace('%', '')}
                    />
                </Form.Item>
                <Form.Item
                    name="resteAPayer"
                    label="Reste à payer"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <InputNumber min={0} className="w-full" addonAfter="FCFA" disabled />
                </Form.Item>
            </Form>
        </Modal>
    );
};