import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { usePatient} from "../../../api";
import {DossierMedicalType} from "../../../models/DossierMedical";
import {Button} from "antd";
import DossierMedicalComponent from "../../../components/patient/DossierMedicalComponent";
import {PrinterOutlined} from '@ant-design/icons';
import CusBackButton from "../../../components/CusBackButton";

function DossierMedical() {
    const { id } = useParams();
    const {customQuery} = usePatient();
    const [dossierMedical, setDossierMedical] = useState<DossierMedicalType>();

    useEffect(() => {
        if(id != null) {
            customQuery(`/${id}/dossier_medical`)
                .then((data: any) => {
                    setDossierMedical(data);
                })
                .catch(error => console.log(error));
        }
    }, []);

    return (
        <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
            <div className="flex justify-between">
                <CusBackButton />
                <Button icon={<PrinterOutlined />} className="flex items-center" >
                    Imprimer
                </Button>
            </div>
            <DossierMedicalComponent dossierMedical={dossierMedical} />
        </div>
    );
}

export default DossierMedical;