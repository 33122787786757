import { Segmented } from 'antd';
import { Timeline } from 'antd';
import React, {useEffect, useState} from 'react';
import ServiceSelectTimeline from './ServiceSelectTimeline';
import UserSearchTimeline from './UserSearchTimeline';
import {ActeType} from "../../models/Acte";
import {useActe} from "../../api";
import {truncate} from "../../utilities/Utils";

function getRandomColor (list: Array<string>) {
  return list[Math.floor((Math.random()*list.length))];
}
const ActeTimeline: React.FC = () => {
  const [actes, setActes] = useState<ActeType[]>([]);
  const [serviceIdFilter, setServiceIdFilter] = useState<number>(0);
  const [personnelIdFilter, setPersonnelIdFilter] = useState<number>(0);
  const [periodeFilter, setPeriodeIdFilter] = useState<string | number>('Journalier');
  const { customQuery } = useActe();

  useEffect(() => {
        customQuery(`?periode=${periodeFilter}&serviceId=${serviceIdFilter}&personnelId=${personnelIdFilter}/service`)
            .then((data: any) => {
                setActes(data ?? []);
        }).catch(error => console.log(error));
  }, [serviceIdFilter, periodeFilter, personnelIdFilter]);


  return (
      <>
        <div className="flex justify-between">
          <div className="flex justify-between space-x-2">
            <div className="flex items-baseline space-x-2">
              <h1>Service: </h1>
              <ServiceSelectTimeline onSelect={(value) => setServiceIdFilter(+value)}/>
            </div>
              <div className="flex items-baseline space-x-2">
                  <UserSearchTimeline onSelect={(value: number) => setPersonnelIdFilter(value)}/>
              </div>
          </div>
          <Segmented options={['Journalier', 'Hebdomadaire', 'Mensuel', 'Annuel']} className="mb-10" value={periodeFilter} onChange={setPeriodeIdFilter}/>
        </div>
        <Timeline mode="left">
          {actes.map(acte => <Timeline.Item key={acte.id} color={getRandomColor(["red", "blue", "yellow", "green"])}>{truncate(acte.observation, 100)}</Timeline.Item>) }
        </Timeline>
      </>
  );
}

export default ActeTimeline;