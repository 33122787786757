import useApi from "./Api";
import {ServiceType} from "../models/Service";
import {HospitalisationType} from "../models/Hospitalisation";
import {PatientType} from "../models/Patient";
import {RoleType} from "../models/Role";
import {DossierMedicalType} from "../models/DossierMedical";
import {ConsultationType} from "../models/Consultation";
import {PersonnelMedicalType} from "../models/PersonnelMedical";
import {SoinType} from "../models/Soin";
import {RecuType} from "../models/Recu";
import {RdvType} from "../models/Rdv";
import {BonExamenType} from "../models/BonExamen";
import {AssuranceType} from "../models/Assurance";
import {FactureType} from "../models/Facture";
import {AntecedentType} from "../models/Antecedent";
import {TypeSoinType} from "../models/TypeSoin";
import {ActeType} from "../models/Acte";
import {SymptomeType} from "../models/Symptome";
import {ExamenType} from "../models/Examen";
import {ChambreType} from "../models/Chambre";
import {OrdonnanceType} from "../models/Ordonnance";

export const useService = () => useApi<ServiceType>({apiUrl: 'services', queryKey: 'serviceQuery'});
export const useHospitalisation = () => useApi<HospitalisationType>({apiUrl: 'hospitalisations', queryKey: 'hospitalisationQuery'});
export const usePatient = () => useApi<PatientType>({apiUrl: 'patients', queryKey: 'patientsQuery'});
export const useRole = () => useApi<RoleType>({apiUrl: 'roles', queryKey: 'roleQuery'});
export const useDossierMedical = () => useApi<DossierMedicalType>({apiUrl: 'dossiermedicaux', queryKey: 'dossierMedicalQuery'});
export const useConsultation = () => useApi<ConsultationType>({apiUrl: 'consultations', queryKey: 'consultationQuery'});
export const useAssurance = () => useApi<AssuranceType>({apiUrl: 'assurances', queryKey: 'assuranceQuery'});
export const usePersonnelMedical = () => useApi<PersonnelMedicalType>({apiUrl: 'personnels', queryKey: 'personnelMedicalQuery'});
export const useSoin = () => useApi<SoinType>({apiUrl: 'soins', queryKey: 'soinQuery'});
export const useActe = () => useApi<ActeType>({apiUrl: 'actes', queryKey: 'acteQuery'});
export const useRecu = () => useApi<RecuType>({apiUrl: 'recus', queryKey: 'recuQuery'});
export const useRdv = () => useApi<RdvType>({apiUrl: 'rdvs', queryKey: 'rdvQuery'});
export const useBonExamen = () => useApi<BonExamenType>({apiUrl: 'bonexamens', queryKey: 'bonExamenQuery'});

export const useOrdonnance = () => useApi<OrdonnanceType>({apiUrl: 'ordonnances', queryKey: 'ordonnanceQuery'});
export const useFacture = () => useApi<FactureType>({apiUrl: 'factures', queryKey: 'factureQuery'});
export const useAntecedent = () => useApi<AntecedentType>({apiUrl: 'antecedents', queryKey: 'antecedentQuery'});
export const useSymptome = () => useApi<SymptomeType>({apiUrl: 'symptomes', queryKey: 'symptomeQuery'});
export const useExamen = () => useApi<ExamenType>({apiUrl: 'examens', queryKey: 'examenQuery'});
export const useChambre = () => useApi<ChambreType>({apiUrl: 'chambres', queryKey: 'chambreQuery'});
export const useTypeSoin = () => useApi<TypeSoinType>({apiUrl: 'typesoins', queryKey: 'typeSoinQuery'});