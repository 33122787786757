import {
    Form, FormInstance, Input, Select,
} from 'antd';
import {usePersonnelMedical, useService} from "../../api";
import React, {useEffect, useState} from "react";
import {emptyFieldError} from "../../utilities/Utils";
import {Role} from "../../utilities/auth";
import {PersonnelMedicalType} from "../../models/PersonnelMedical";

interface OptionValue {
    value: number,
    label: string
}

interface EntamerConsultationFormProps {
    form: FormInstance
}
const EntamerConsultationForm = (
    { form } : EntamerConsultationFormProps
) => {
    const {data: services} = useService();
    const {filteredData} = usePersonnelMedical();
    const {data: personnels} = filteredData({"role_name": Role.Medecin});
    const [doctors, setDoctors] = useState<PersonnelMedicalType[]>([]);
    const [serviceOption, setServiceOption] = useState<OptionValue[]>();
    const [personnelOption, setPersonnelOption] = useState<OptionValue[]>();

    useEffect(() => {
        const opt: Array<{value: number, label: string}> = [];
        services.forEach(service => opt.push({value: service.id, label: service.nom}));
        setServiceOption([...opt]);
    }, [services]);

    useEffect(() => {
        setDoctors(personnels ?? []);
    }, [personnels]);

    useEffect(() => {
        const opt: OptionValue[] = [];
        doctors.forEach(personnel => opt.push({value: personnel.id, label: `${personnel.nom} ${personnel.prenom}`}));
        setPersonnelOption([...opt]);
    }, [doctors]);

    const onSelectService = (value: number) => {
        const personnelByService = personnels?.filter(personnel => personnel.services?.id == value);
        const newOptions = personnelByService?.map(personnel => ({value: personnel.id, label: `${personnel.nom} ${personnel.prenom}`})) ?? [];
        const currentService = services.filter(service => service.id == value)[0];
        form.setFieldValue("prix", currentService.prixConsultation);
        setPersonnelOption([...newOptions]);
    }

  return ( 
    <Form form={form} layout="vertical">
        <Form.Item name="id" hidden={true}><Input /></Form.Item>
        <Form.Item
            name="service_id"
            label="service"
            rules={[{ required: true, message: emptyFieldError}]}
        >
            <Select onSelect={onSelectService} options={serviceOption} />
        </Form.Item>
        <Form.Item
            name="personnel_medical_id"
            label="Personnel"
            rules={[{ required: true, message: emptyFieldError}]}
        >
            <Select options={personnelOption} />
        </Form.Item>
      <Form.Item label="Lieu" name="lieu" initialValue="Cité Octra, Owendo">
          <Input />
      </Form.Item>
      <Form.Item label="Prix" name="prix">
          <Input disabled suffix="FCFA" />
      </Form.Item> 
  </Form>
)
}

export default EntamerConsultationForm;