import {Button, Form, message, Tag} from 'antd'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useDossierMedical, useService, useSoin, useTypeSoin} from "../../../api";
import {SoinType} from "../../../models/Soin";
import {getFullName, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {SoinFormModal} from "../../../components/soin/SoinFormModal";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";

const columns: ColumnConfig<SoinType>[] = [
    {
        title: 'Patient',
        dataIndex: 'patient',
        key: 'patient',
        render: record => <span className="text-primary">{record.patient}</span>
    },
    {
        title: 'Type Soin',
        dataIndex: 'typeSoin',
        key: 'typeSoin',
        render: (record) => <span>{record.typeSoin?.nom}</span>
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (record) => <span>{record.service?.nom}</span>
    },
    {
        title: 'Part Payée',
        dataIndex: 'partPayee',
        key: 'partPayee'
    },
      {
        title: 'Observation',
        dataIndex: 'observation',
        key: 'observation'
      },
      {
        title: 'Prix',
        dataIndex: 'typeSoin',
        key: 'typeSoin',
        render: (record) => <span>{record.typeSoin?.prix}</span>
      },
];
function ListSoin() {
    const {user} = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const { data, add, update, remove, isLoading } = useSoin();
    const {one: getService} = useService();
    const {one: getTypeSoin} = useTypeSoin();
    const {data: dossiersMedicaux} = useDossierMedical();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [updated, setUpdated] = useState<boolean>(false);

    const onCreate = async (values: any, updated: boolean) => {
        try {
            values.dossierMedical = dossiersMedicaux?.filter(dossier => getFullName(dossier?.patient).toLowerCase() === values.patient.toLowerCase())[0];
            if (updated) {
                values.service = await getService(values.service);
                values.typeSoin = await getTypeSoin(values.typeSoin);
                update(
                    values,
                    {
                        onSuccess: (values) => onSuccessResponse(messageApi, values, "Le soin a été modifié"),
                        onError: (values) => onErrorResponse(messageApi, values, "La modification du soin a échouée")
                    }
                );
            } else {
                const soins: any = [];
                if(values.soins) {
                    for (const soin of values.soins) {
                        soin.service = await getService(soin.service);
                        soin.typeSoin = await getTypeSoin(soin.typeSoin);
                        soins.push({
                            dossierMedical: values.dossierMedical,
                            patient: values.patient,
                            personnel: values.personnel,
                            observation: soin.observation,
                            partPayee: soin.partPayee,
                            service: soin.service,
                            typeSoin: soin.typeSoin
                        });
                    }
                    add(
                        soins,
                        {
                            onSuccess: (values) => onSuccessResponse(messageApi, values, (soins.length > 1 ? "Les soins ont été ajoutés": "Le soin a été ajouté")),
                            onError: (values) => onErrorResponse(messageApi, values, (soins.length > 1 ? "L'ajout des soins a échoué": "L'ajout du soin a échoué"))
                        }
                    );

                }
            }
            setOpen(false);
            form.resetFields();
        } catch (error) {
            console.log(error);
        }
    };

    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le soin a été supprimé"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression du soin a échouée")
            }
        );
    }

    const onUpdate = (record: SoinType) => {
        form.setFieldsValue(record);
        form.setFieldValue("typeSoin", record.typeSoin?.id);
        form.setFieldValue("prix", record.typeSoin?.prix);
        form.setFieldValue("service", record.service?.id);
        setUpdated(true);
        setOpen(true);
    }

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }
    
  return (
      <>
          {contextHolder}
        <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
          <DynamicTable<SoinType>
              queryKey="soinQuery"
              header={
                  <div className="flex justify-between">
                      <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Soins</Tag>
                      {
                          (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Infirmier) || hasRole(user.roles.name, Role.Medecin))
                          ? (
                              <Button type="primary" size="middle" className="bg-black border-black" onClick={() => {
                                  form.resetFields();
                                  setUpdated(false);
                                  setOpen(!open);
                              }}>
                                  Ajouter
                              </Button>
                          ) : null
                      }
                  </div>
              }
              columns={columns}
              data={data}
              isLoading={isLoading}
              action={
                  (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Infirmier) || hasRole(user.roles.name, Role.Medecin)) ? {
                    onUpdateRow: onUpdate,
                    onDeleteRow: onDelete
                  } : undefined
              }
          />
            <SoinFormModal
                open={open}
                onCreate={onCreate}
                onCancel={onCancel}
                updated={updated}
                form={form}
            />
        </div>
      </>
  )
}

export default ListSoin