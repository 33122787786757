import React, {useEffect, useState} from 'react';
import {Page, Text, View, Document, StyleSheet, Image, PDFViewer} from '@react-pdf/renderer';
import { Config } from '../../config';
// Images
import logoLeft from "../../assets/logos/ceep.png";
import logoRight from "../../assets/logos/cmci.png";
import {RecuType} from "../../models/Recu";
import {useRecu} from "../../api";
import {getValidDate} from "../../utilities/Utils";
import {PatientType} from "../../models/Patient";

// Styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
        alignSelf: 'center',
        backgroundColor: '#fff',
    },
    view: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30,
    },
    titleBody: {
      flex: '1',
      textAlign: 'center',
    },
    logoLeft: {
        width: 60,
        height: 35,
        backgroundColor: '#fff',
    },
    logoRight: {
        width: 35,
        height: 35,
    },
    title: {
        fontSize: 11,
        textAlign: 'center',
        fontWeight: "extrabold"
    },
    subTitle: {
        fontSize: 7,
        textAlign: 'center',
        fontWeight: "demibold"
    },
    header: {
        fontSize: 10,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    section: {
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 20,
        alignItems: 'center',
    },
    rowDate: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    rowDateContent: {
        flexDirection: 'row',
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: "space-between",
        width: "100%"
    },
    valueRowDateContent: {
        flex: 1,
        color: '#333',
        borderBottom: '1pt dashed #ccc',
        textAlign: 'center',
    },
    label: {
        fontWeight: 'bold',
        color: '#555',
    },
    value: {
        flex: 1,
        color: '#333',
        borderBottom: '1pt solid #ccc',
        textAlign: 'center',
    },
});


interface RecuPdfProps {
    recu?: RecuType,
    patient?: PatientType
}
// Component
const RecuPdf = ({recu, patient}:  RecuPdfProps) => {

    return (
        <Document title={`recu_${recu?.id}`}>
            <Page size={[400, 600]} fixed orientation={"landscape"} style={styles.page}>
                <View style={styles.view} >
                    <View style={styles.titleContainer}>
                        <Image src={logoLeft} style={styles.logoLeft} />
                        <View style={styles.titleBody}>
                            <Text style={styles.title}>{Config.app_name}</Text>
                            <Text style={styles.subTitle}>{Config.slug}</Text>
                        </View>
                        <Image src={logoRight} style={styles.logoRight} />
                    </View>
                    <View style={styles.header}>
                        <View style={{flexDirection: "row"}}>
                            <Text style={{textTransform: "uppercase", marginRight: 10}}>Reçu n°</Text>
                            <Text style={{textTransform: "uppercase", color: "#c31f26"}}>{recu?.id}</Text>
                        </View>
                        <View style={{flexDirection: "row"}}>
                            <Text style={{textTransform: "uppercase", marginRight: 10}}>FCFA</Text>
                            <Text style={{width: 80, textAlign: "center", textTransform: "uppercase", paddingBottom: 0, borderBottom: "1pt solid #ccc"}}>{recu?.montant}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Reçu de M </Text>
                            <Text style={styles.value}>{patient?.nom + " " + patient?.prenom}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Somme de </Text>
                            <Text style={styles.value}>{recu?.montant}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Motif </Text>
                            <Text style={styles.value}>{recu?.motif}</Text>
                        </View>
                        <View style={styles.rowDate}>
                            <View style={styles.rowDateContent}>
                                <Text style={styles.label}>A</Text>
                                <Text style={styles.valueRowDateContent}>{recu?.lieu}</Text>
                            </View>
                            <View style={styles.rowDateContent}>
                                <Text style={styles.label}>Le</Text>
                                <Text style={styles.valueRowDateContent}>{getValidDate(recu?.datePayment ?? (new Date()), "d MMMM")}</Text>
                            </View>
                            <View style={styles.rowDateContent}>
                                <Text style={styles.label}>20</Text>
                                <Text style={styles.valueRowDateContent}>23</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{height: 50, fontSize: 8, flexDirection: "row", justifyContent: "space-between"}}>
                        <Text>Cachet de département</Text>
                        <Text>Nom et signature Caissière</Text>
                    </View>
                    <View style={{ marginTop: 20, fontSize: 8, textAlign: 'center' }}>
                        <Text>
                            Clinique de l'espoir et de l'espérance, BP 9519, SARL au Capital de 5 000 000, RCCM: PG LBV 2018 B 214 92, NIF: 045 733F
                        </Text>
                        <Text>
                            Tél: {Config.contact}, mail: {Config.email}
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

interface RecuPdfViewerProps {
    recu?: RecuType
}

const RecuPdfViewer = ({
    recu
}: RecuPdfViewerProps) => {

    const [patient, setPatient] = useState<PatientType>()
    const { customQuery } = useRecu();

    useEffect(() => {
        if(recu) {
            customQuery(`/${recu?.id}/patient`)
            .then((data: any) => {
                setPatient(data);
            }).catch(error => console.log(error));
        }
    }, [recu]);


    return (
        <div className="w-full h-full">
                <PDFViewer
                    style={{
                        border: "none",
                        backgroundColor: "#fff",
                        overflow: "hidden",
                    }}
                    width="100%"
                    height={600}
                >
                    <RecuPdf recu={recu} patient={patient}/>
                </PDFViewer>
        </div>
    );
};

export default RecuPdfViewer;
