
import { Badge, Calendar } from 'antd';
import React, {useEffect, useState} from 'react';
import {useRdv} from "../../api";
import {EtatOperation, getValidDate} from "../../utilities/Utils";
import type { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/fr_FR'
interface EventData {
  id: number;
  date: string; // Format : 'YYYY-MM-DD'
  title: string;
  status: 'success' | 'processing' | 'default' | 'error' | 'warning';
}
const getStatus = (etat: string)  => {
  let status : 'success' | 'processing' | 'default' | 'error' | 'warning' = 'default';
  switch (etat.toLowerCase()) {case EtatOperation.TERMINEE.toLowerCase():
    status = 'success';
    break;
    case EtatOperation.EN_ATTENTE.toLowerCase():
      status = 'error';
      break;
    case EtatOperation.EN_COURS.toLowerCase():
      status = 'processing';
      break;
    case EtatOperation.ANNULEE.toLowerCase():
      status = 'warning';
  }
 return status;
}
const RdvCalendar: React.FC = () => {
  const [events, setEvents] = useState<EventData[]>([]);
  const {data: rdv} = useRdv();

  useEffect(() => {
    const evts: EventData[] = rdv.map(rdv => ({
          id: rdv.id,
          date: getValidDate(rdv.date, 'YYYY-MM-DD'),
          title: `Consultation en ${rdv.service.nom}`,
          status: getStatus(rdv.etat),
    }));
    setEvents([...evts]);
  }, [rdv]);

  const dateCellRender = (value: Dayjs) => {
    const date = value.format('YYYY-MM-DD');
    const eventsForDate = events.filter((event) => event.date === date);

    return (
        <ul className="space-y-1">
          {eventsForDate.map((event) => (
              <li key={event.id}>
                <Badge className="text-sm font-medium" status={event.status} text={event.title} />
              </li>
          ))}
        </ul>
    );
  };

  return <Calendar cellRender={dateCellRender} locale={locale}/>;
};

export default RdvCalendar;