import {Button, Form, message, Tag} from 'antd'
import {useAuth} from '../../../provider/AuthProvider'
import {hasRole, Role} from '../../../utilities/auth'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import {PersonnelMedicalType} from "../../../models/PersonnelMedical";
import React, {useEffect, useState} from "react";
import {usePersonnelMedical, useRole, useService} from "../../../api";
import {PersonnelFormModal} from "../../../components/personnel/PersonnelFormModal";
import {onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import PersonnelProfileModal from "../../../components/personnel/PersonnelProfileModal";

const columns: ColumnConfig<PersonnelMedicalType>[] = [
    {
        title: 'Nom',
        dataIndex: 'nom',
        key: 'nom',
        render: record => <span className="text-primary">{record.nom}</span>
    },
    {
        title: 'Prenom',
        dataIndex: 'prenom',
        key: 'prenom',
    },
    {
        title: 'Sexe',
        dataIndex: 'sexe',
        key: 'sexe',
    },
    {
        title: 'Rôle',
        dataIndex: 'roles',
        key: 'roles',
        render: (value) => <span>{value.roles?.name}</span>,
    },
    {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
    },
    {
        title: 'Adresse',
        dataIndex: 'adresse',
        key: 'adresse',
    },
];

function ListPersonnel() {
    const [messageApi, contextHolder] = message.useMessage();
    const { user } = useAuth();
    const { data, add, update, remove, isLoading } = usePersonnelMedical();
    const [personnels, setPersonnels] = useState<PersonnelMedicalType[]>([]);
    const {one: getService} = useService();
    const {one: getRole} = useRole();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [updated, setUpdated] = useState<boolean>(false);
    const [personnel, setPersonnel] = useState<PersonnelMedicalType>();
    const [showPersonnelModal, setShowPersonnelModal] = useState(false);

    useEffect(() => {
        if(hasRole(user.roles.name, Role.Admin)) {
            setPersonnels(data);
        }
        else if(hasRole(user.roles.name, Role.Dga) || hasRole(user.roles.name, Role.Daf)) {
            setPersonnels(data.filter(personnel => !hasRole(personnel.roles.name, Role.Admin)))
        }
        else {
            setPersonnels(data.filter(personnel => hasRole(personnel.roles.name, Role.Medecin)))
        }
    }, [data]);
    
    
    const onCreate = async (values: any, updated: boolean) => {
        try {
            const role = await getRole(values.role);
            if(values.services) {
                const service = await getService(values.services);
                values.service_id = (hasRole(role.name, Role.Medecin) ||
                    hasRole(role.name, Role.Infirmier)) ? service.id : null;
            }
            values.role_id = role.id;

            delete values.role;
            delete values.services;

        } catch (error) {
            console.log(error);
        }

        if (updated) {
            update(
                values,
                {
                    onSuccess: (values) => onSuccessResponse(messageApi, values, "Le personnel a été modifié"),
                    onError: (values) => onErrorResponse(messageApi, values, "La modification du personnel a échouée")
                }
            );
        } else {
            add(
                values,
                {
                    onSuccess: (values) => onSuccessResponse(messageApi, values, "Le personnel a été ajouté"),
                    onError: (values) => onErrorResponse(messageApi, values, "L'ajout du personnel a échoué")
                }
            );
        }
        setOpen(false);
        form.resetFields();
    };

    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le personnel a été supprimé"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression du personnel a échouée")
            }
        );
    }

    const handleOk = () => {
        setShowPersonnelModal(false);
    };

    const handleCancel = () => {
        setShowPersonnelModal(false);
    };
    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }

    const onUpdate = (record: PersonnelMedicalType) => {
        form.setFieldsValue(record);
        form.setFieldValue("role", record.roles.id);
        form.setFieldValue("services", record.services?.id);
        setUpdated(true);
        setOpen(true);
    }

  return (
    <>
        {contextHolder}
      <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
        <DynamicTable<PersonnelMedicalType>
            queryKey="personnelMedicalQuery"
            header={
                <div className="flex justify-between">
                    <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Personnels de la clinique</Tag>
                    {
                        hasRole(user.roles?.name, Role.Admin)
                            ?
                            (
                                <Button type="primary" size="middle" className="bg-black border-black" onClick={() => {
                                    setUpdated(false);
                                    setOpen(!open);
                                }}>
                                    Ajouter
                                </Button>
                            )
                            : null
                    }
                </div>
            }
            columns={columns}
            data={personnels}
            isLoading={isLoading}
            action={{
                onShowRow: (record) => {
                    setPersonnel(record);
                    setShowPersonnelModal(true);
                },
                onUpdateRow: hasRole(user.roles?.name, Role.Admin) ? onUpdate : undefined,
                onDeleteRow: hasRole(user.roles?.name, Role.Admin) ? onDelete : undefined,
            }}
        />
          <PersonnelFormModal
              open={open}
              onCreate={onCreate}
              onCancel={onCancel}
              updated={updated}
              form={form}
          />
          <PersonnelProfileModal
              personnel={personnel}
              isModalOpen={showPersonnelModal}
              handleOk={handleOk} handleCancel={handleCancel}
          />
      </div>
    </>
  )
}

export default ListPersonnel