import React from 'react';
import {Col, Form, FormInstance, Input, Modal, Radio, Row} from 'antd';
import {PatientType} from "../../models/Patient";
import {emptyFieldError} from "../../utilities/Utils";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: PatientType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<PatientType>;
    updated: boolean
}

export const PatientFormModal: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
    form,
    updated
}) => {

    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour du patient" : "Nouveau patient"}
            okText={updated ? "Modifier" : "Ajouter"}
            okType="primary"
            cancelText="Annuler"
            onCancel={() => {
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            maskClosable={false}
            width={800}
        >
            <Form
                form={form}
                name="form_in_modal"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="nom"
                            label="Nom"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="prenom"
                            label="Prenom"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="sexe"
                            label="Sexe"
                            className="collection-create-form_last-form-item"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Radio.Group className="flex justify-evenly">
                                <Radio value="M">M</Radio>
                                <Radio value="F">F</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="fonction"
                            label="Fonction"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="contact"
                            label="Contact"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="adresse"
                            label="Adresse"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <h3 className="mt-2 mb-4">Personne à contacter en cas d'urgence</h3>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="personneAContacter"
                            label="Nom(s) et prénom(s)"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lienParente"
                            label="Lien de parenté"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="fonctionPersonne"
                            label="Fonction"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="contactPersonne"
                            label="Contact"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="adressePersonne"
                            label="Adresse"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};