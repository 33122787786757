import { Calendar } from 'antd';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import React from 'react';
import {Dayjs} from "dayjs";

const DashboardCalendar: React.FC = () => {
  const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
    console.log(value.format('DD-MM-YYYY'), mode);
  };

  return (
    <Calendar fullscreen={false} onPanelChange={onPanelChange} />
  );
};

export default DashboardCalendar;