import {AuditOutlined, EyeOutlined, FileDoneOutlined, FormOutlined, PrinterOutlined} from '@ant-design/icons';
import {Button, Form, message, Tag, Tooltip} from 'antd'
import {useAuth} from '../../../provider/AuthProvider';
import {hasRole, Role} from '../../../utilities/auth'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {FactureType} from "../../../models/Facture";
import {useFacture} from "../../../api";
import {
    EtatFacture, EtatOperation,
    getEtatFactureColorAndName,
    getFullName,
    onErrorResponse,
    onSuccessResponse, truncate
} from "../../../utilities/Utils";
import {FactureFormModal} from "../../../components/facture/FacutureFormModal";
import ShowFacturePdfModal from "../../../components/facture/ShowFacturePdfModal";

const columns:  ColumnConfig<FactureType>[] = [
  {
    title: 'Reference',
    dataIndex: 'reference',
    key: 'reference',
      render: (record) => <span className="text-primary">{record.reference.slice(0, 6)}</span>
  },
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    render: (record) => <span>{getFullName(record.patient)}</span>
  },
  {
    title: 'Montant A Payer',
    dataIndex: 'montantTotal',
    key: 'montantTotal'
  },
  {
    title: 'Part Payée Patient',
    dataIndex: 'partPayeePatient',
    key: 'partPayeePatient'
  },
  {
    title: 'Reduction',
    dataIndex: 'pourcentageReduction',
    key: 'pourcentageReduction'
  },
  {
    title: 'Part Payée Assurances',
    dataIndex: 'partPayeeAssurance',
    key: 'partPayeeAssurance'
  },
  {
    title: 'Etat',
    dataIndex: 'etat',
    key: 'etat',
    render: (record) => <Tag className="capitalize" color={getEtatFactureColorAndName(record.etat).color}>
      {getEtatFactureColorAndName(record.etat).name}
    </Tag>
  },
];
function ListFacture() {
  const [messageApi, contextHolder] = message.useMessage();
  const { data, update, isLoading } = useFacture();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [facture, setFacture] = useState<FactureType>();
  const [form] = Form.useForm();
  const onCreate = async (values: any) => {
      let etat = EtatFacture.NON_PAYEE;
      values.montantTotal = +values.montantTotal;
      values.resteAPayer = +values.resteAPayer;
      if(values.resteAPayer > 0 && values.resteAPayer < values.montantTotal) {
          etat = EtatFacture.PAYEE_EN_PARTIE
      } else if(values.resteAPayer === 0) {
          etat = EtatFacture.PAYEE
      }
      values.etat = etat;
      console.log(values);
      update(
          values,
          {
            onSuccess: (values) => {
                onSuccessResponse(messageApi, values, "La facture a été payée");
                onShow(values);
            },
            onError: (values) => onErrorResponse(messageApi, values, "La facturation a échouée")
          }
      );
      setOpen(false);
      form.resetFields();
  };
  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  }
  const handleFacture = (e:  React.MouseEvent<HTMLAnchorElement>, record: FactureType) => {
    form.setFieldsValue(record);
    form.setFieldValue("reference", record.reference.slice(0, 6))
    setOpen(true);
  }

  const onShow = (record: FactureType) => {
      setFacture(record);
      showModal();
  }

  const onFacture = (record: FactureType) => (
      (record.etat.toLowerCase() !== EtatFacture.PAYEE.toLowerCase() && (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Caissier)))
          ? (
              <a className="text-green-600 hover:text-green-800" onClick={(e) => handleFacture(e, record)}>
                  <Tooltip title="Encaisser"><FileDoneOutlined style={{ fontSize: '18px' }} /></Tooltip>
              </a>
          ) : null
  );

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const customAction = (record: FactureType) => {
        if(record.etat.toLowerCase() !== EtatFacture.NON_PAYEE.toLowerCase()) {
                return (
                    <>
                        <a className="text-yellow-700 hover:text-yellow-900" onClick={(e) => onShow(record)}>
                            <EyeOutlined style={{ fontSize: '18px' }} />
                        </a>
                        {onFacture(record)}
                    </>
                )
        } else {
            return onFacture(record);
        }
    }

  return (
    <>
      {contextHolder}
      <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
        <DynamicTable<FactureType>
            queryKey="factureQuery"
            header={
                <div className="flex justify-between">
                    <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Factures</Tag>
                    {
                        <div className="flex space-x-2">
                            <Button icon={<PrinterOutlined />} className="flex items-center" >
                                Imprimer
                            </Button>
                        </div>
                    }
                </div>
            }
            columns={columns}
            data={data}
            isLoading={isLoading}
            action={{
              customAction: customAction,
            }}
        />
        <FactureFormModal
            open={open}
            onCreate={onCreate}
            onCancel={onCancel}
            updated={false}
            form={form}
        />
          <ShowFacturePdfModal facture={facture} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
      </div>
  </>
  )
}

export default ListFacture