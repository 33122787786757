import React, {useEffect, useState} from 'react';
import {AutoComplete, DatePicker, DatePickerProps, Form, FormInstance, Input, Modal, Select} from 'antd';
import {RdvType} from "../../models/Rdv";
import {usePatient, usePersonnelMedical, useService} from "../../api";
import {emptyFieldError, getValidDate} from "../../utilities/Utils";
import locale from 'antd/es/date-picker/locale/fr_FR'
import {PersonnelMedicalType} from "../../models/PersonnelMedical";
import {Role} from "../../utilities/auth";
import dayjs from "dayjs";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: RdvType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<RdvType>;
    updated: boolean
}

interface OptionValue {
    value: number,
    label: string
}

const slotOptions = [
    { value: '08H30', label: '08H30' },
    { value: '12H00', label: '12H00' },
    { value: '16H00', label: '16H00' },
];
export const RdvFormModal: React.FC<CollectionCreateFormProps> = ({
   open,
   onCreate,
   onCancel,
   form,
   updated
                                                                   }) => {
    const {data: services} = useService();
    const {data: patients} = usePatient();
    const {filteredData} = usePersonnelMedical();
    const {data: personnels} = filteredData({"role_name": Role.Medecin});
    const [doctors, setDoctors] = useState<PersonnelMedicalType[]>([]);
    const [serviceOption, setServiceOption] = useState<OptionValue[]>();
    const [personnelOption, setPersonnelOption] = useState<OptionValue[]>();
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const onSelect = (data: string) => {
        patients.forEach((patient) => {
            const fullName = `${patient.nom} ${patient.prenom}`.toLowerCase();
            if(fullName === data.toLowerCase()) {
                form.setFieldValue("sexe", patient.sexe);
                form.setFieldValue("contact", patient.contact);
            }
        });
    };

    const onSelectService = (data: number) => {
        const personnelByService = personnels?.filter(personnel => personnel.services?.id == data);
        const newOptions = personnelByService?.map(personnel => ({value: personnel.id, label: `${personnel.nom} ${personnel.prenom}`})) ?? [];
        setPersonnelOption([...newOptions]);
    }
    const handleSlotChange = (value: string) => {
        form.setFieldValue("heure", value);
    }

    useEffect(() => {
        setDoctors(personnels ?? []);
        console.table(personnels)
    }, [personnels]);


    useEffect(() => {
        const ops: { value: string }[] = [];
        patients.forEach((patient) => ops.push({value: `${patient.nom} ${patient.prenom}`}));
        setOptions([...ops]);
    }, [patients]);


    useEffect(() => {
        const opt: OptionValue[] = [];
        services.forEach(service => opt.push({value: service.id, label: service.nom}));
        setServiceOption([...opt]);
    }, [services]);


    useEffect(() => {
        const opt: OptionValue[] = [];
        doctors.forEach(personnel => opt.push({value: personnel.id, label: `${personnel.nom} ${personnel.prenom}`}));
        setPersonnelOption([...opt]);
    }, [doctors]);


    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour du rendez-vous" : "Prendre rendez-vous"}
            okText={updated ? "Modifier" : "Programmer"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            maskClosable={false}
            width={600}
        >
            <Form
                form={form}
                name="form_in_modal"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item
                    label="Patient"
                    name="patient"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <AutoComplete
                        options={options}
                        onSelect={onSelect}
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item name="sexe" label="Sexe"><Input /></Form.Item>
                <Form.Item
                    name="contact"
                    label="Contact"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="service_id"
                    label="service"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Select onSelect={onSelectService} options={serviceOption} />
                </Form.Item>
                <Form.Item
                    name="personnel_medical_id"
                    label="Personnel"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Select options={personnelOption} />
                </Form.Item>
                <Form.Item label="Date"
                           name="date"
                           rules={[{ required: true, message: emptyFieldError }]}
                >
                    <DatePicker className="w-full" locale={locale} />
                </Form.Item>
                <Form.Item label="Heure"
                           name="heure"
                           rules={[{ required: true, message: emptyFieldError }]}
                >
                    <Select
                        onChange={handleSlotChange}
                        options={slotOptions}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};