import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Form, FormInstance, Input, Modal, Radio, Select} from 'antd';
import {emptyFieldError} from "../../utilities/Utils";
import {ServiceType} from "../../models/Service";
import {useService} from "../../api";
import {PersonnelMedicalType} from "../../models/PersonnelMedical";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: ServiceType) => void;
    onCancel: () => void;
    form: FormInstance<ServiceType>;
    updated: boolean;
}

export const ServiceFormModal: React.FC<CollectionCreateFormProps> = ({
   open,
   onCreate,
   onCancel,
    form,
    updated
}) => {
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const { customQuery } = useService();
    const onSelect = (data: string) => {
    };

    useEffect(() => {
        return () => {
            customQuery("/file").then((data: any) => {
                const ops: { value: string }[] = [];
                data.forEach((option: string) => ops.push({value: option}));
                setOptions(ops);
            }).catch(error => console.log(error));
        };
    }, []);


    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour d'un service" : "Nouveau service"}
            okText={updated ? "Modifier" : "Ajouter"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item
                    name="nom"
                    label="Nom"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <AutoComplete
                        options={options}
                        onSelect={onSelect}
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="prixConsultation"
                    label="Prix de la consultation"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Input suffix="FCFA"/>
                </Form.Item>
            </Form>
        </Modal>
    );
};