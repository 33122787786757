import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {ExamenType} from "../../models/Examen";

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        fontStyle: 'bold',
    },
    examen: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        paddingTop: 4,
        color: '#0c4b8d',
    },
    result: {
        width: '50%',
        textAlign: 'right',
        paddingRight: 8,
        paddingTop: 4,
        color: '#0c4b8d',
    },
});

interface ExamenTabRowInterface {
    examens?: ExamenType[]
}

const ExamenTableRow = ({examens}: ExamenTabRowInterface) => {
    const rows = examens?.map( (examen, index) =>
        <View style={styles.row} key={index}>
            <Text style={styles.examen}>{examen.nom}</Text>
            <Text style={styles.result}>{examen.resultat}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};

export default ExamenTableRow