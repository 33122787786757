import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useEffect, useState} from "react";
import {RdvType} from "../../../models/Rdv";
import {usePersonnelMedical, useRdv, useService} from "../../../api";
import {EtatOperation, getValidDate, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {Form, message} from "antd";
import {RdvFormModal} from "../../../components/rdv/RdvFormModal";
import moment from "moment";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";
import dayjs from "dayjs";

export const RdvColumns: ColumnConfig<RdvType>[] = [
    {
        title: 'Patient',
        dataIndex: 'patient',
        key: 'patient',
        render: record => <span className="text-primary">{record?.patient}</span>
    },
    {
        title: 'Sexe',
        dataIndex: 'sexe',
        key: 'sexe',
    },
    {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact',
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (render) => <span>{render?.service?.nom}</span>
    },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (record) => <span className="capitalize">{getValidDate(record?.date ?? "")}</span>

  },
  {
    title: 'Heure',
    dataIndex: 'heure',
    key: 'heure',
  },
];

function TableRDV() {
  const {user} = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const { update, remove,customQuery, filteredData: filteredRdv } = useRdv();
  const {one: getService} = useService();
  const {one: getPersonnel} = usePersonnelMedical();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [rdv, setRdv] = useState<Array<RdvType>>([]);
  const {data: rdvFiltered, isLoading} = filteredRdv({"etat": EtatOperation.EN_ATTENTE});

    useEffect(() => {
        let rdv: RdvType[] | undefined;
        rdv = rdvFiltered?.filter((rdv: RdvType) => dayjs(rdv.date).isAfter(dayjs().toDate()));
        setRdv(rdv ?? []);
    }, [rdvFiltered]);

  const onCreate = async (values: any) => {
    values.etat = EtatOperation.EN_ATTENTE;
    values.date = dayjs(values.date).format("YYYY-MM-DD")

    update(
          values,
          {
              onSuccess: (values) => onSuccessResponse(messageApi, values, "Le rdv a été modifié"),
              onError: (values) => onErrorResponse(messageApi, values, "La modification du rdv a échouée")
          }
    );
    setOpen(false);
    form.resetFields();
  };

  const onDelete = (id: number) => {
    remove(
        id,
        {
          onSuccess: (values) => onSuccessResponse(messageApi, values, "Le rdv a été supprimé"),
          onError: (values) => onErrorResponse(messageApi, values, "La suppression du rdv a échouée")
        }
    );
  }

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  }

  const onUpdate = (record: RdvType) => {
      form.resetFields();
      form.setFieldsValue(record);
      form.setFieldValue("date", moment(record.date));
      form.setFieldValue("service_id", record.service?.id);
      customQuery(`/${record.id}/personnel`).then((data: any) => {
          form.setFieldValue("personnel_medical_id", data?.id);
      }).catch(error => console.log(error));
      setOpen(true);
  }
  
  return (
      <>
        {contextHolder}
        <DynamicTable<RdvType>
            queryKey="rdvQuery"
            columns={RdvColumns}
            data={rdv}
            isLoading={isLoading}
            action={
                (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Secretaire)) ? {
                    onUpdateRow: onUpdate,
                    onDeleteRow: onDelete
                } : undefined
            }
        />
        <RdvFormModal
            open={open}
            onCreate={onCreate}
            onCancel={onCancel}
            updated={true}
            form={form}
        />
      </>

  )
}

export default TableRDV