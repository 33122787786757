import { List } from 'antd';
import React, {useEffect, useState} from 'react';
import {useAssurance} from "../../api";
import {AssuranceType} from "../../models/Assurance";

const AssuranceList: React.FC = () => {
    const {data} = useAssurance();
    const [assurances, setAssurances] = useState<AssuranceType[]>([]);
    useEffect(() => {
        setAssurances(data?.slice(0, 3));
    }, [data]);

    return (
        <List
            itemLayout="horizontal"
            dataSource={assurances}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<img src={`/images/${item.image}`} alt={item.nom} className="w-12 h-12"/>}
                        title={<span>{item.nom}</span>}
                        description={item.description}
                    />
                </List.Item>
            )}
        />
    );
}

export default AssuranceList;