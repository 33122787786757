import React, {useEffect, useState} from 'react';
import {Descriptions, Table, Tag} from "antd";
import {AntecedentType, TypeAntecedentType} from "../../models/Antecedent";

interface AntecedentSectionProps {
    antecedents?: AntecedentType[]
}
const AntecedentSection = ({
    antecedents
                           }: AntecedentSectionProps) => {
    const [personnelAntecedents, setPersonnelAntecedents] = useState<AntecedentType[]>([]);
    const [familialAntecedents, setFamilialAntecedents] = useState<AntecedentType[]>([]);
    const [chirurgicalAntecedents, setChirurgicalAntecedents] = useState<AntecedentType[]>([]);
    const [allergieAntecedents, setAllergieAntecedents] = useState<AntecedentType[]>([]);

    useEffect(() => {
        const personnels: AntecedentType[] = [];
        const familials: AntecedentType[] = [];
        const chirurgicals: AntecedentType[] = [];
        const allergies: AntecedentType[] = [];
        antecedents?.forEach((antecedent) => {
            switch (antecedent.type.toLowerCase()) {
                case TypeAntecedentType.CHIRURGICAL.toLowerCase():
                    chirurgicals.push(antecedent);
                    break;
                case TypeAntecedentType.PERSONNEL.toLowerCase():
                    personnels.push(antecedent);
                    break;
                case TypeAntecedentType.FAMILIAL.toLowerCase():
                    familials.push(antecedent);
                    break;
                case TypeAntecedentType.ALLERGIE.toLowerCase():
                    allergies.push(antecedent);
                    break;
            }
        });
        setPersonnelAntecedents([...personnels]);
        setChirurgicalAntecedents([...chirurgicals]);
        setFamilialAntecedents([...familials]);
        setAllergieAntecedents([...allergies]);
    }, [antecedents]);
    

    return (
        <div className="my-8">
            <Descriptions title="Antécédents" bordered column={1}>
                <Descriptions.Item label="Antécédents Personnels" labelStyle={{width: "25%"}}>
                    <div className="w-full">
                        {personnelAntecedents.map(antecedent => <Tag className="mr-1">{antecedent.nom}</Tag>)}
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="Antécédents Familiaux">
                    <div className="w-full">
                        {familialAntecedents.map(antecedent => <Tag className="mr-1">{antecedent.nom}</Tag>)}
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="Antécédents Chirurgicaux">
                    <div className="w-full">
                        {chirurgicalAntecedents.map(antecedent => <Tag className="mr-1">{antecedent.nom}</Tag>)}
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="Allergies">
                    <div className="w-full">
                        {allergieAntecedents.map(antecedent => <Tag className="mr-1">{antecedent.nom}</Tag>)}
                    </div>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}

export default AntecedentSection;