import {ServiceType} from "../../../models/Service";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useService} from "../../../api";
import {ServiceFormModal} from "../../../components/service/ServiceFormModal";
import {Form, message, Tag} from "antd";
import {onErrorResponse, onSuccessResponse, truncate} from "../../../utilities/Utils";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";

const columns: ColumnConfig<ServiceType>[] = [
    {
        title: 'Nom',
        dataIndex: 'nom',
        key: 'nom',
        render: record => <span className="text-primary">{record.nom}</span>
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (record) => <span>{truncate(record?.description ?? "", 80)}</span>
    },
    {
        title: 'Prix Consultation',
        dataIndex: 'prixConsultation',
        key: 'prixConsultation',
        render: (record) => <span>{record?.prixConsultation} FCFA</span>
    }
];

function ListService() {
    const {user} = useAuth();
    const { data, isLoading, update, remove } = useService();
    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const onCreate = (values: any) => {
        update(
            values,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le service a été modifié"),
                onError: (values) => onErrorResponse(messageApi, values, "La modification du service a échouée")
            }
        );
        setOpen(false);
    }

    const onUpdate = (record: ServiceType) => {
        form.setFieldsValue(record);
        setOpen(true );
    }
    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le service a été supprimé"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression du service a échouée")
            }
        );
    }


  return (
    <>
        {contextHolder}
      <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
          <DynamicTable<ServiceType>
              queryKey="serviceQuery"
              header={
                  <div className="flex justify-between">
                      <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Services</Tag>
                  </div>
              }
              columns={columns}
              data={data}
              isLoading={isLoading}
              action={
                  hasRole(user.roles.name, Role.Admin) ? {
                    onDeleteRow: onDelete,
                    onUpdateRow: onUpdate
                  } : undefined
              }
          />
      </div>
        <ServiceFormModal
            open={open}
            onCreate={onCreate}
            onCancel={() => setOpen(false)}
            form={form}
            updated={true}
        />
    </>
  )
}

export default ListService