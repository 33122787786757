import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Typography, List, Tabs, Tag, Tooltip, Button, Badge} from 'antd';
import { AuditOutlined, FormOutlined} from '@ant-design/icons';
import TabPane from "antd/lib/tabs/TabPane";
import {faCalendar, faUser, faStaffSnake, faStethoscope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useConsultation, useOrdonnance, usePatient, usePersonnelMedical, useRdv} from "../../api";
import {RdvType} from "../../models/Rdv";
import DynamicTable, {ColumnConfig} from "../../components/DynamicTable";
import {RdvColumns} from "./rdv/Table";
import {ConsultationType} from "../../models/Consultation";
import {useAuth} from "../../provider/AuthProvider";
import {EtatOperation, getEtatOperationColorAndName, getFullName} from "../../utilities/Utils";
import {hasRole, Role} from "../../utilities/auth";
import {useNavigate} from "react-router-dom";
import PatientBySexChart from "../../components/personnel/PatientBySexChart";
import dayjs from "dayjs";

const { Title } = Typography;

const patientStatistics = [
    { title: 'Nombre total de patients', value: '500' },
    { title: 'Âge moyen des patients', value: '35 ans' },
    { title: 'Répartition par sexe', value: 'Mâle: 40%, Femelle: 60%' },
    // Autres statistiques des patients
];

const doctorAvailability = 'Lundi - Vendredi, de 9h00 à 17h00';
const doctorContact = 'Numéro de téléphone : 066854423, E-mail : doctor@example.com';
const additionalServices = "Laboratoire d'analyse, Radiologie";
// Autres informations pratiques spécifiques

const importantMessages = [
    'Rappel : Campagne de vaccination ce samedi',
    'Fermeture exceptionnelle le 15 juin pour maintenance',
];

const consulationColumns: ColumnConfig<ConsultationType>[] = [
    {
        title: 'Patient',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span>{getFullName(record?.dossierMedical.patient)}</span>
    },
    {
        title: 'Médecin',
        dataIndex: 'personnelMedical',
        key: 'personnelMedical',
        render: (record) => <span>{getFullName(record.personnelMedical)}</span>
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (record) => <span>{record.service.nom}</span>
    },
    {
        title: 'Etat',
        dataIndex: 'etat',
        key: 'etat',
        render: (record) => <Tag
            color={getEtatOperationColorAndName(record.etat).color}>
            {getEtatOperationColorAndName(record.etat).name}
        </Tag>
    }
];

const DashboardMedecin: React.FC = () => {
    const {user} = useAuth();
    const {count: totalPrescription} = useOrdonnance();
    const {filteredData: filteredConsultations, count: totalConsultation} = useConsultation();
    const {count: totalPatient} = usePatient();
    const {customQuery} = usePersonnelMedical();
    const [consultations, setConsultations] = useState<ConsultationType[]>([]);
    const [rdv, setRdv] = useState<Array<RdvType>>([]);
    const {filteredData: filteredRdv, isLoading: rdvIsLoading, count: totalRdv  } = useRdv();
    const {data: rdvFiltered} = filteredRdv({"etat": EtatOperation.EN_ATTENTE});
    const {data: consulationsFiltered} = filteredConsultations({"etat": EtatOperation.EN_ATTENTE});
    const navigate = useNavigate();

    useEffect(() => {
        setConsultations(consulationsFiltered ?? []);
    }, [consulationsFiltered]);

    useEffect(() => {
        let rdv: RdvType[] | undefined;
        rdv = rdvFiltered?.filter((rdv: RdvType) => dayjs(rdv.date).isAfter(dayjs().toDate()) && rdv.personnelMedical?.username.toLowerCase() === user?.username.toLowerCase() );
        setRdv(rdv ?? []);
    }, [rdvFiltered]);

    const handleConsulter = (e:  React.MouseEvent<HTMLAnchorElement>, record: ConsultationType) => {
        navigate(`/dashboard/add-consultation/${record.id}`);
    }

    const onCustomRow = (record: ConsultationType) => (
        record.etat.toLowerCase() === EtatOperation.EN_ATTENTE.toLowerCase()
            ? (
                (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Medecin))?
                    (
                        <a className="text-green-600 hover:text-green-800" onClick={(e) => handleConsulter(e, record)}>
                            <Tooltip title="Consulter"><AuditOutlined  style={{ fontSize: '18px' }} /></Tooltip>
                        </a>
                    ) : null
            ) : null
    );

    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-4 gap-8">
                <div className="col-span-2 flex flex-grow">
                    <DynamicTable<ConsultationType>
                        queryKey="consultationQuery"
                        header={
                            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Patients en attente</Tag>
                        }
                        columns={consulationColumns}
                        data={consultations}
                        action={{
                            customAction: (hasRole(user.roles?.name, Role.Medecin) || hasRole(user.roles?.name, Role.Admin)) ? onCustomRow : undefined
                        }}
                    />
                </div>
                <div className="col-span-2">

                        <DynamicTable<RdvType>
                            header={
                                <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Rendez-vous</Tag>
                            }
                            queryKey="rdvQuery"
                            columns={RdvColumns}
                            data={rdv}
                            isLoading={rdvIsLoading}
                        />
                </div>
            </div>

            <div className="grid grid-cols-3 gap-8 mt-8">
                <div className="col-span-1">
                    <Card title="Statistiques" style={{ height: '100%' }}>
                        <Row gutter={[16, 16]} >
                            <Col span={12}>
                                <Card>
                                    <Title level={4}>Patients</Title>
                                    <div className="flex justify-between items-baseline">
                                        <Badge
                                            count={totalPatient ?? 0}
                                            showZero className="text-blue-700"
                                        />
                                        <FontAwesomeIcon className="text-green-700 w-6 h-6" icon={faUser} />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Title level={4}>Consultations</Title>
                                    <div className="flex justify-between items-baseline">
                                        <Badge
                                            count={totalConsultation ?? 0}
                                            showZero className="text-yellow-700"
                                        />
                                        <FontAwesomeIcon className="text-yellow-700 w-6 h-6" icon={faStethoscope} />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Title level={4}>Rendez-vous</Title>
                                    <div className="flex justify-between items-baseline">
                                        <Badge
                                            count={totalRdv ?? 0}
                                            showZero className="text-blue-700"
                                        />
                                        <FontAwesomeIcon className="text-blue-700 w-6 h-6" icon={faCalendar} />
                                    </div>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Title level={4}>Prescriptions</Title>
                                    <div className="flex justify-between items-baseline">
                                        <Badge
                                            count={totalPrescription ?? 0}
                                            showZero className="text-secondary-light"
                                        />
                                        <FontAwesomeIcon className="text-secondary-light w-6 h-6" icon={faStaffSnake} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div>
                <div className="col-span-2">
                    <Card title="Autres informations" style={{ height: '100%' }}>
                        <Tabs defaultActiveKey="statistics">
                            <TabPane tab="Patient par sexe" key="statistics">
                                <div className="p-4">
                                    <PatientBySexChart />
                                </div>
                            </TabPane>
                            <TabPane tab="Informations pratiques" key="practical">
                                <div className="p-4">
                                    <Title level={4}>Informations pratiques</Title>
                                    <p>Horaires de disponibilité : {doctorAvailability}</p>
                                    <p>Coordonnées de contact : {doctorContact}</p>
                                    <p>Services supplémentaires : {additionalServices}</p>
                                </div>
                            </TabPane>
                            <TabPane tab="Messages importants" key="messages">
                                <div className="p-4">
                                    <Title level={4}>Messages importants</Title>
                                    <List
                                        dataSource={importantMessages}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <p>{item}</p>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DashboardMedecin;