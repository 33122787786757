import React from 'react';
import {Col, Row} from "antd";
import logoClinic from "../assets/logos/ceep.png";
import logoCmci from "../assets/logos/cmci.png";
import { Config } from '../config';
import { Typography } from 'antd';

const { Title } = Typography;

function HeaderDocument1() {
    return (
        <>
            <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                    <img src={logoClinic} alt="" className="lg:object-fill h-14 w-24" />
                </Col>
                <Col className="gutter-row text-center" span={12} >
                    <Title level={3} className="font-bold">{Config.app_name}</Title>
                    <Title level={4} className="uppercase font-semibold">{Config.slug}</Title>
                </Col>
                <Col className="gutter-row flex justify-end" span={6}>
                    <img src={logoCmci} alt="" className="lg:object-fill h-14 w-24" />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <h1 className="w-full bg-secondary text-lg text-white text-center my-5 p-2 uppercase font-bold">Dossier médical du patient</h1>
                </Col>
            </Row>
        </>
    );
}

export default HeaderDocument1;