import React, {useEffect} from 'react'
import { useLocation, Navigate, } from 'react-router-dom';
import {getUser, removeToken, removeUser, saveUser, User} from '../utilities/auth';

interface AuthProp {
    children: React.ReactNode
}

interface AuthContextType {
    user: User;
    signin: (user: User, callback: VoidFunction) => void;
    signout: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: AuthProp) {
    const [user, setUser] = React.useState<any>(null);

    useEffect(() => {
        const savedUser = getUser();
        if(savedUser != null) {
            setUser(savedUser);
        }
    }, []);

    const signin = (newUser: User, callback: VoidFunction) => {
          setUser(newUser);
          saveUser(newUser);
          callback();
      };
    
      const signout = (callback: VoidFunction) => {
          setUser(null);
          removeUser();
          removeToken();
          callback();
      };
    
    const value = { user, signin, signout };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
}
function CusUseAuth() {
    return React.useContext(AuthContext);
  }
  
function AuthStatus() {
    let auth = CusUseAuth();
  
    if (auth?.user == null) {
      return <p>Vous n'êtes pas connecté.</p>;
    }
  
    return <>Bienvenue {auth?.user.username}!{" "}</>;
    
  }
  
function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = CusUseAuth();

    if (auth?.user == null) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they log in, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" replace />;
    }
  
    return children;
  }

export const useAuth = CusUseAuth;
export const authStatus = AuthStatus;
export const requireAuth = RequireAuth;
export default AuthProvider;