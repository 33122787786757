import {Document, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import React from "react";
import {OrdonnanceType} from "../../models/Ordonnance";
import {getAge, getFullName} from "../../utilities/Utils";
import HeaderPDF from "../HeaderPDF";
import dayjs from "dayjs";
import { Watermark } from "antd";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
        alignSelf: 'center',
        backgroundColor: '#fff',
    },
    view: {
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    rows: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    label: {
        fontWeight: 'bold',
        color: '#000',
    },
    value: {
        flex: 1,
        color: '#000',
        borderBottom: '1pt dashed #000',
        textAlign: 'center',
    },
    prescriptionsView: {
        marginVertical: 20
    },
    prescriptionsContainer: {
        marginTop: 10
    },
    prescriptionValue: {
        marginVertical: 2,
        color: '#000',
    },

});



interface OrdonnancePdfInterface {
    ordonnance?: OrdonnanceType
}
const OrdonnancePdf = ({ordonnance}:OrdonnancePdfInterface) => {
    return (
        <Document title={`ordonnance_${ordonnance?.id}`}>
            <Page size={[450, 660]} fixed orientation={"landscape"} style={styles.page}>
                <View style={styles.view} >
                    <HeaderPDF title={"Ordonnance"} />
                    <View style={styles.rows}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Noms:</Text>
                            <Text style={styles.value}>{ordonnance?.dossierMedical ? getFullName(ordonnance?.dossierMedical.patient) : ""}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Service: </Text>
                            <Text style={styles.value}>{ordonnance?.service?.nom}</Text>
                        </View>
                    </View>
                    <View style={styles.prescriptionsView}>
                        <Text style={styles.label}>Prescriptions: </Text>
                        <View style={styles.prescriptionsContainer}>
                            {ordonnance?.prescriptions?.map((prescription) =>
                                <View style={{flexDirection: "row", justifyContent: "space-between"}}>
                                    <Text style={styles.prescriptionValue}>{prescription.medicament}</Text>
                                    <Text style={styles.prescriptionValue}>{prescription.dose}</Text>
                                    <Text style={styles.prescriptionValue}>{prescription.nbPriseParJour} / Jour</Text>
                                    <Text style={styles.prescriptionValue}>{prescription.dureeTraitement}</Text>
                                </View>    
                            )}
                        </View>
                    </View>
                    <View style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Text>Prescripteur</Text>
                        <View style={{flexDirection: "row"}}>
                            <Text style={{marginRight: 4}}>Libreville le:</Text>
                            <View style={{width: 100, flexDirection: "row", justifyContent: 'space-evenly', borderBottom: '1px dashed #000'}}>
                                <Text>{dayjs(ordonnance?.dateCreation).get("days") + 1} /</Text>
                                <Text style={{marginHorizontal: 4}}>{dayjs(ordonnance?.dateCreation).get("month") + 1} /</Text>
                                <Text>{dayjs(ordonnance?.dateCreation).get("year")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

interface OrdonnancePdfViewerProps {
    ordonnance?: OrdonnanceType
}
const OrdonnancePdfViewer = (
    {ordonnance} : OrdonnancePdfViewerProps
) => {

    return (
        <div className="container flex justify-center">
            <PDFViewer
                style={{
                    border: "none",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                }}
                width="100%"
                height={600}
            >
                <OrdonnancePdf ordonnance={ordonnance} />
            </PDFViewer>
        </div>
    );
};

export default OrdonnancePdfViewer;
