import React from 'react';
import SearchInput from './SearchInput';
import logo from "../assets/logos/main-logo.jpeg";
import ProfileDropdown from './ProfileDropdown';
import { Layout } from "antd";

const { Header } = Layout;

function HeaderBar() {
    return (
        <Header className="flex items-center justify-between bg-primary-dark">
            <img src={logo} alt="" className="lg:object-fill h-10 w-24" />
            <SearchInput />
            {/*<Button type='primary' icon={<MessageOutlined />} className=" bg-secondary flex items-center">*/}
            {/*    Messagerie*/}
            {/*</Button>*/}
            <ProfileDropdown />
        </Header>
    );
}

export default HeaderBar;