import { OrderedListOutlined, ProfileOutlined } from '@ant-design/icons';
import {Button, Form, MenuProps, message, Tag} from 'antd';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../../provider/AuthProvider';
import {hasRole, Role} from '../../../utilities/auth';
import {EtatOperation, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {usePersonnelMedical, useRdv, useService} from "../../../api";
import {RdvFormModal} from "../../../components/rdv/RdvFormModal";
import dayjs from "dayjs";

const items: MenuProps['items'] = [
    { label: <Link to="calendrier-rdv">Calendrier</Link>, key: "calendrier-acte", icon: <OrderedListOutlined /> },
    { label: <Link to="">Liste</Link>, key: "list-rdv-table", icon: <ProfileOutlined /> },    
];

function ListRDV() {
    const [messageApi, contextHolder] = message.useMessage();
    const [current, setCurrent] = useState('');
    const { user } = useAuth();
    const {add} = useRdv();
    const {one: getService} = useService();
    const {one: getPersonnel} = usePersonnelMedical();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const onClick: MenuProps['onClick'] = e => {
        setCurrent(e.key);
    };
    const onCreate = async (values: any) => {
        values.etat = EtatOperation.EN_ATTENTE;
        values.date = dayjs(values.date).format("YYYY-MM-DD")
        add(
            values,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le rdv a été programmé"),
                onError: (values) => onErrorResponse(messageApi, values, "L'ajout du rdv a échoué")
            }
        );
        setOpen(false);
        form.resetFields();
    };

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }

    useEffect(() => {
        setCurrent('');
    }, []);

   return (
    <>
        {contextHolder}
      <div className="grid grid-cols-5 gab-0 bg-white p-4 rounded border border-solid border-transparent border-100">
        <Menu
                mode="inline"
                onClick={onClick} 
                selectedKeys={[current]} 
                items={items} 
                className="col-span-1"
        />
        <div className="col-span-4 pl-4 pt-4">
          <div className="flex justify-between">
            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Rendez-vous</Tag>
            {
              (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Secretaire))
              ?
                (
                  <Button type="primary" size="middle" className="bg-black border-black" onClick={() => setOpen(!open)}>
                    Programmer
                  </Button>
                )
              : null
            }
          </div>
          <Outlet />
        </div>
          <RdvFormModal
              open={open}
              onCreate={onCreate}
              onCancel={onCancel}
              updated={false}
              form={form}
          />
      </div>
    </>
 );
}

export default ListRDV;