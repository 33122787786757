import {PatientType} from "../../models/Patient";
import {Avatar, Descriptions, Modal} from "antd";
import React from "react";
import {UserOutlined} from "@ant-design/icons";

interface PatientProfileModalProps {
    patient?: PatientType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}

function PatientProfileModal({
                                   patient, isModalOpen, handleOk, handleCancel
                               } : PatientProfileModalProps) {
    return (
        // eslint-disable-next-line react/jsx-no-undef
        <Modal
            open={isModalOpen}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            centered={true}
        >
            <div className="mt-8 flex flex-col items-center">
                <Avatar size={128} icon={<UserOutlined />} className="mb-4" />
                <Descriptions title="Patient" layout="vertical">
                    <Descriptions.Item label="Nom(s) et prénom(s):"><span className="font-bold">{patient?.nom + " " + patient?.prenom}</span></Descriptions.Item>
                    <Descriptions.Item label="Fonction"><span className="font-bold">{patient?.fonction}</span></Descriptions.Item>
                    <Descriptions.Item label="Sexe"><span className="font-bold">{patient?.sexe}</span></Descriptions.Item>
                    <Descriptions.Item label="Contact"><span className="font-bold">{patient?.contact}</span></Descriptions.Item>
                    <Descriptions.Item label="Email"><span className="font-bold">{patient?.email}</span></Descriptions.Item>
                    <Descriptions.Item label="Adresse"><span className="font-bold">{patient?.adresse}</span></Descriptions.Item>
                </Descriptions>
            </div>
        </Modal>
    );
}

export default PatientProfileModal;