import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        fontStyle: 'bold',
    },
    examen: {
        width: '50%',
        height: 40,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 10
    },
    result: {
        width: '50%',
        height: 40,
        paddingLeft: 10
    },
});


const ExamenTableFooter = () => {
    return(
        <View style={styles.row}>
            <View style={styles.examen}>
                <View style={{flexDirection: "row"}}>
                    <Text style={{marginRight: 4}}>Date:</Text>
                    <View style={{width: 100, flexDirection: "row", justifyContent: 'space-evenly', borderBottom: '1px dashed #000'}}>
                        <Text>{""} /</Text>
                        <Text style={{marginHorizontal: 4}}>{""} /</Text>
                        <Text>{""}</Text>
                    </View>
                </View>
                <Text style={{marginTop: 5}}>Le Demandeur</Text>
            </View>
            <View style={styles.result}>
                <View style={{flexDirection: "row"}}>
                    <Text style={{marginRight: 4}}>Date:</Text>
                    <View style={{width: 100, flexDirection: "row", justifyContent: 'space-evenly', borderBottom: '1px dashed #000'}}>
                        <Text>{""} /</Text>
                        <Text style={{marginHorizontal: 4}}>{""} /</Text>
                        <Text>{""}</Text>
                    </View>
                </View>
                <Text style={{marginTop: 5}}>Le Spécialiste</Text>
            </View>
        </View>
    )
};

export default ExamenTableFooter