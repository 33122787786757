import { Select } from 'antd';
import React from 'react';
import {useActe, useService} from "../../api";

const { Option } = Select;

interface ServiceSelectTimelineProps {
    onSelect: (value: string) => void;
}

const ServiceSelectTimeline = ({
    onSelect
} : ServiceSelectTimelineProps ) => {
    const { data } = useService();
    return (
        <Select defaultValue="service" className="w-40" onSelect={(value) => onSelect(value)}>
            <Option value="service">Tout</Option>
            {
                data.map((service) => <Option key={service.id} value={service.id}>{service.nom}</Option> )
            }
        </Select>
    );
}

export default ServiceSelectTimeline;