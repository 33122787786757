import React from 'react';
import {Modal} from "antd";
import {OrdonnanceType} from "../../models/Ordonnance";
import OrdonnancePdfViewer from "./OrdonnancePdf";

interface ShowPdfModalProps {
    ordonnance?: OrdonnanceType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}
function ShowOrdonnancePdfModal({
                                      ordonnance, isModalOpen, handleOk, handleCancel
                                  }: ShowPdfModalProps) {
    return (
        <Modal
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            closable={false}
            width={850}
            bodyStyle={{padding: 0, margin: 0}}
            footer={null}
        >
            <OrdonnancePdfViewer ordonnance={ordonnance} />
        </Modal>
    );
}

export default ShowOrdonnancePdfModal;