import React, {useEffect, useState} from 'react';
import {Carousel, Image} from "antd";
import {AssuranceType} from "../../models/Assurance";

interface AussuranceSectionProps {
    assurances?: AssuranceType[]
}

function AssuranceSection({assurances} : AussuranceSectionProps) {
    const [insurances, setInsurances] = useState<AssuranceType[]>();
    useEffect(() => {
        setInsurances(assurances);
    }, [assurances]);

    return (
        <div className="my-8">
            <h2 className="text-xl font-bold mb-4">Assurances</h2>
            <div className="flex">
                {insurances?.map((insurance, index) => (
                    <div key={index} className="mr-2">
                        <img className="w-40 h-14 object-fill" src={insurance.image} alt={insurance.nom} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AssuranceSection;