import jwtDecode from "jwt-decode";
import {PersonnelMedicalType} from "../models/PersonnelMedical";

export enum Role {
    SUPER_ADMIN = "super_admin",
    Admin = "admin",
    Secretaire = "secretaire",
    Caissier = "caissier",
    Daf = "daf",
    Dga = "dga",
    Medecin = "doctor",
    Infirmier = "infirmier",
    Patient = "patient"
}

export enum Right {
  Read = "VOIR",
  Add = "AJOUTER",
  Update = "MODIFIER",
  Delete = "SUPPRIMER"
}

export enum Ressources {
  Hospitalisation = "HOSPITALISATION",
  Soin = "SOIN",
  Consultation = "CONSULTATION",
  Ordonnance = "ORDONNANCE",
  Patient = "PATIENT",
  DossierPatient = "DOSSIER_PATIENT",
  Personnel = "PERSONNEL",
  Service = "SERVICE",
  Acte = "ACTE",
  Facture = "FACTURE",
  Recu = "RECU",
  BonExamen = "BON_EXAMEN",
  Examen = "EXAMEN",
  Role = "ROLE"
}

type Ressource = {
    name: string,
    rights: Array<string>
  }

export interface User extends PersonnelMedicalType {
    ressources?: Array<Ressource>
}

export const isAuthenticated = (user: User) => !!user;

export const isAllowed = (user: User, ressource: {name: string, right: string}) =>
{
    let res = false;
    const { right } = ressource;
    if(user?.ressources!.some(res => res.name === ressource.name)) {
      res = user.ressources!.some(res => res.rights!.some(rightElt => rightElt === right ));
    }
    return res;
}

export const isRoleExists = (role: string)  => Object.values(Role).includes(role as Role);

export const hasRole = (userRole: string, role: string) =>
  role?.toLowerCase() === userRole?.toLowerCase();

const USER_STORAGE_KEY = 'user';

const API_TOKEN = "token"

export const saveUser = (user: User) => sessionStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));

export function getUser(): User | null {
    const user = sessionStorage.getItem(USER_STORAGE_KEY);
    return user ? JSON.parse(user) : null;
}
export const removeUser = () => sessionStorage.removeItem(USER_STORAGE_KEY);

export const saveToken = (token: string) => sessionStorage.setItem(API_TOKEN, token);

export const getToken = () => sessionStorage.getItem(API_TOKEN);

export const removeToken = () => sessionStorage.removeItem(API_TOKEN);

export const checkTokenValidity = () => {
    const token = getUser() && getToken();
    // @ts-ignore
    if (jwtDecode(token).exp < Date.now() / 1000) {
        removeToken();
    }
};
