import { PrinterOutlined  } from '@ant-design/icons';
import {Button, Tag} from 'antd'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useRecu} from "../../../api";
import {RecuType} from "../../../models/Recu";
import {getFullName, getValidDate} from "../../../utilities/Utils";
import ShowRecuPdfModal from "../../../components/recu/ShowRecuPdfModal";
import {useAuth} from "../../../provider/AuthProvider";

const columns: ColumnConfig<RecuType>[] = [
  {
    title: 'Patient',
    dataIndex: 'facture',
    key: 'facture',
    render: (record) => <span className="text-primary">{record.facture?.patient ? getFullName(record.facture?.patient) : ""}</span>
  },
  {
    title: 'Montant',
    dataIndex: 'montant',
    key: 'montant'
  },
  {
    title: 'Date De Paiement',
    dataIndex: 'datePayment',
    key: 'datePayment',
    render: (record) => <span className="capitalize">{getValidDate(record.datePayment)}</span>
  },
];
function ListRecu() {
  const {user} = useAuth();
  const {data, isLoading} = useRecu();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recu, setRecu] = useState<RecuType>();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
      <DynamicTable<RecuType>
          queryKey="recuQuery"
          header={
            <div className="flex justify-between">
              <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Reçus</Tag>
              <Button icon={<PrinterOutlined />} className="flex items-center" >
                Imprimer
              </Button>
            </div>
          }
          columns={columns}
          data={data}
          isLoading={isLoading}
          action={{
            onShowRow: (record ) => {
              setRecu(record);
              showModal();
            }
          }}
      />
      <ShowRecuPdfModal recu={recu} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
    </div>
  </>
  )
}

export default ListRecu