import React from 'react';
import {AutoComplete, Button, Col, Form, FormInstance, Input, Row} from "antd";
import {emptyFieldError} from "../../utilities/Utils";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {OrdonnanceType} from "../../models/Ordonnance";

interface PrescriptionFormProps {
    form: FormInstance<OrdonnanceType>
}
function PrescriptionForm({form}:PrescriptionFormProps) {
    return (
        <Form form={form} layout="vertical">
            <h1 className="my-4">Prescritions</h1>
            <Form.List name="prescriptions">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row key={key} className="w-full" align={"middle"} gutter={10}>
                                <Col xs={12} sm={12} md={8}>
                                    <Form.Item
                                        {...restField}
                                        label="Médicament"
                                        name={[name, 'medicament']}
                                        rules={[{ required: true, message: emptyFieldError }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={6}>
                                    <Form.Item
                                        {...restField}
                                        label="Dose"
                                        name={[name, 'dose']}
                                        rules={[{ required: true, message: emptyFieldError }]}
                                    >
                                        <Input placeholder="2 comprimés" />
                                    </Form.Item>
                                </Col>
                                <Col  xs={10} sm={10} md={4}>
                                    <Form.Item
                                        {...restField}
                                        label="Nombre Prise"
                                        name={[name, 'nbPriseParJour']}
                                        rules={[{ required: true, message: emptyFieldError }]}
                                    >
                                        <Input placeholder="2"  suffix="Jour"/>
                                    </Form.Item>
                                </Col>
                                <Col  xs={10} sm={10} md={4}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'dureeTraitement']}
                                        label="Durée traitement"
                                        rules={[{ required: true, message: emptyFieldError }]}
                                    >
                                        <Input placeholder="2 jours" />
                                    </Form.Item>
                                </Col>
                                <Col  span={2} className="flex justify-center">
                                    <MinusCircleOutlined className="text-secondary" style={{fontSize: 20}} onClick={() => remove(name)} />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" className="hover:text-primary hover:border-primary" onClick={() => add()} block icon={<PlusOutlined />}>
                                Ajouter une prescription
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
}

export default PrescriptionForm;