import React from 'react';
import {Modal} from "antd";
import FacturePdfViewer from "./FacturePdf";
import {FactureType} from "../../models/Facture";

interface ShwoPdfModalProps {
    facture?: FactureType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}
function ShowFacturePdfModal({
                              facture, isModalOpen, handleOk, handleCancel
                          }: ShwoPdfModalProps) {
    return (
        <Modal
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            closable={false}
            width={1000}
            bodyStyle={{padding: 0, margin: 0}}
            footer={null}
        >
            <FacturePdfViewer facture={facture} />
        </Modal>
    );
}

export default ShowFacturePdfModal;