import React, {useCallback, useEffect, useState} from 'react';
import InformationGeneraleSection from "./InformationGeneraleSection";
import AntecedentSection from "./AntecedentSection";
import HistoriqueMedicalSection from "./HistoriqueMedicalSection";
import HeaderDocument1 from "../../widgets/HeaderDocument1";
import AssuranceSection from "./AssuranceSection";
import {DossierMedicalType} from "../../models/DossierMedical";
import {PatientType} from "../../models/Patient";
import {ConsultationType} from "../../models/Consultation";
import {SoinType} from "../../models/Soin";
import {BonExamenType} from "../../models/BonExamen";
import {useDossierMedical} from "../../api";

interface DossierMedicalComponentProps {
    dossierMedical?: DossierMedicalType | null

}
const DossierMedicalComponent = ({
    dossierMedical
                                 }: DossierMedicalComponentProps) => {

    const [consultations, setConsultations] = useState<ConsultationType[]>([]);
    const [soins, setSoins] = useState<SoinType[]>([]);
    const [bonExamens, setBonExamens] = useState<BonExamenType[]>([]);
    const {customQuery} = useDossierMedical();

    const getData = useCallback(async (dataType: string, callback: (data: any) => void) => {
        if(dossierMedical?.id) {
            try {
                const data = await customQuery(`/${dossierMedical?.id}/${dataType}`);
                callback(data);
            } catch(error) {
                console.log(error);
            }
        }
    }, [dossierMedical]);

    useEffect(() => {
        getData("consultations", (data: any) => setConsultations(data));
        getData("bon_examens", (data: any) => setBonExamens(data));
        getData("soins", (data: any) => setSoins(data));

    }, [getData]);


    return (
        <div className="container mx-auto px-4 py-8">
            <HeaderDocument1 />

            <InformationGeneraleSection patient={dossierMedical?.patient} electrophorese={dossierMedical?.electrophorese ?? ""} groupeSanguin={dossierMedical?.groupeSanguin ?? ""}/>

            <AntecedentSection antecedents={dossierMedical?.antecedents} />

            <HistoriqueMedicalSection consultations={consultations} soins={soins} bonExamens={bonExamens} />

            <AssuranceSection assurances={dossierMedical?.assurances}/>
        </div>
    );
};

export default DossierMedicalComponent;
