import { Link } from 'react-router-dom';
import {Card} from "antd";

interface Props {
    title: string,
    desc: string,
    icon: React.ReactNode
}

function DashboardServiceItem({ title, desc, icon  }: Props) {
  return (
    <div className="col-span-1 w-full h-20 group block max-w-xs mx-auto rounded p-2 ring-1 ring-slate-900/5 shadow space-y-3 hover:bg-secondary-light hover:ring-secondary-light">
        <div className="flex items-center space-x-3">
        {icon}
        <h3 className="text-slate-900 group-hover:text-white text-sm font-semibold">{title}</h3>
        </div>
        <p className="text-slate-500 group-hover:text-white text-sm">{desc}</p>
    </div>
  )
}

export default DashboardServiceItem