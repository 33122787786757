import React, {useEffect, useState} from 'react';
import {PatientType} from "../../models/Patient";
import DynamicTable, {ColumnConfig} from "../DynamicTable";
import {useConsultation, usePatient} from "../../api";
import {EtatOperation, getFullName} from "../../utilities/Utils";
import {ConsultationType} from "../../models/Consultation";
import {Tag} from "antd";

const columns: ColumnConfig<ConsultationType>[] = [
    {
        title: 'Patient',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span>{`${getFullName(record?.dossierMedical?.patient)}`}</span>
    },
    {
        title: 'Sexe',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span>{`${record?.dossierMedical?.patient.sexe}`}</span>
    },
    {
        title: 'Contact',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span>{`${record?.dossierMedical?.patient.contact}`}</span>
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (record) => <span>{`${record?.service.nom}`}</span>
    },
    {
        title: 'Médecin',
        dataIndex: 'personnelMedical',
        key: 'personnelMedical',
        render: (record) => <span>{`${getFullName(record?.personnelMedical)}`}</span>
    },
];
function PatientWaitingList() {
    const {filteredData } = useConsultation();
    const {data, isLoading} = filteredData({"etat": EtatOperation.EN_ATTENTE});

    return (
        <DynamicTable<ConsultationType>
            header={
                <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Patient en salle d'attente</Tag>
            }
            queryKey="consultationQuery"
            columns={columns}
            data={data ?? []}
            isLoading={isLoading}
        />
    );
}

export default PatientWaitingList;