import React, {useEffect, useState} from 'react';
import {AutoComplete, Form, FormInstance, Input, Modal,} from 'antd';
import {emptyFieldError} from "../../utilities/Utils";
import {TypeSoinType} from "../../models/TypeSoin";
import { useSoin } from "../../api";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: TypeSoinType) => void;
    onCancel: () => void;
    form: FormInstance<TypeSoinType>;
    updated: boolean;
}

export const TypeSoinFormModal: React.FC<CollectionCreateFormProps> = ({
          open,
          onCreate,
          onCancel,
          form,
          updated
                                                                      }) => {
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const { customQuery } = useSoin();
    const onSelect = (data: string) => {
    };

    useEffect(() => {
        return () => {
            customQuery("/file").then((data: any) => {
                const ops: { value: string }[] = [];
                data.forEach((option: string) => ops.push({value: option}));
                setOptions(ops);
            }).catch(error => console.log(error));
        };
    }, []);

    return (
        <Modal
            open={open}
            title={updated ? "Modification d'un type de soin" : "Nouveau type de soin"}
            okText={updated ? "Modifier" : "Enregistrer"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item
                    name="nom"
                    label="Nom"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <AutoComplete
                        options={options}
                        onSelect={onSelect}
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="prix"
                    label="Prix"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};