import React from 'react';
import {Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import logoLeft from "../assets/logos/main-logo.jpeg";
import {Config} from "../config";

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    titleBody: {
        flex: '1',
        textAlign: 'center',
    },
    logoLeft: {
        width: 60,
        height: 35,
    },
    title: {
        fontSize: 12,
        marginVertical: 4,
        textAlign: 'center',
        fontWeight: "bold",
        textDecoration: "underline"
    },
    subTitle: {
        fontSize: 8,
        textAlign: 'center',
    },
    titleInfo: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        fontSize: 7
    },
    header: {
        fontSize: 10,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginVertical: 5
    }
});

interface HeaderPdfProps {
    title: string
}

function HeaderPdf({title}:HeaderPdfProps) {
    return (
            <>
                <View style={styles.titleContainer}>
                    <Image src={logoLeft} style={styles.logoLeft} />
                    <View style={styles.titleBody}>
                        <Text style={styles.subTitle}>{Config.slug}</Text>
                        <Text style={styles.title}>{Config.app_name}</Text>
                        <View style={styles.titleInfo}>
                            <Text>Siège: CITE-OCTRA, OWENDO.</Text>
                            <Text>B.P. 9519 Libreville</Text>
                            <Text>Tel: +241 01705246 / 06361219 / 04595510</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.header}>
                    <Text style={{textTransform: "uppercase", textDecoration: "underline"}}>{title}</Text>
                </View>
            </>
    );
}

export default HeaderPdf;