import React from 'react';
import {Modal} from "antd";
import BonExamenPdfViewer from "../bonExamen/BonExamenPdf";
import {BonExamenType} from "../../models/BonExamen";
import {ConsultationType} from "../../models/Consultation";
import ConsultationPdfViewer from "./ConsultationPdff";

interface ShwoPdfModalProps {
    consultation?: ConsultationType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}
function ShowConsultationPdfModal({
        consultation, isModalOpen, handleOk, handleCancel
    }: ShwoPdfModalProps) {
    return (
        <Modal
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            closable={false}
            width={850}
            bodyStyle={{padding: 0, margin: 0}}
            footer={null}
        >
            <ConsultationPdfViewer consultation={consultation} />
        </Modal>
    );
}

export default ShowConsultationPdfModal;