import React from 'react';
import {Form, FormInstance, Input, Modal} from 'antd';
import {emptyFieldError} from "../../utilities/Utils";
import {AssuranceType} from "../../models/Assurance";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: AssuranceType) => void;
    onCancel: () => void;
    form: FormInstance<AssuranceType>;
    updated: boolean;
}

export const AssuranceFormModal: React.FC<CollectionCreateFormProps> = ({
  open,
  onCreate,
  onCancel,
  form,
  updated
                                                                      }) => {

    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour d'une assurance" : "Nouvelle assurance"}
            okText={updated ? "Modifier" : "Ajouter"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item
                    name="nom"
                    label="Nom"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};