import { useAuth } from '../../provider/AuthProvider';
import DashboardSecretaire from './DashboardSecretaire';
import DashboardAdmin from './DashboardAdmin';
import DashboardCaissier from './DashboardCaissier';
import DashboardMedecin from "./DashboardMedecin";
import DashboardInfirmier from "./DashboardInfirmier";
import {Role} from "../../utilities/auth";

function renderDashboard(role: string = "") {
  switch(role) {
    case Role.Admin:
      return <DashboardAdmin />;
    case Role.Secretaire:
        return <DashboardSecretaire />;
    case Role.Caissier:
          return <DashboardCaissier />;
    case Role.Medecin:
      return <DashboardMedecin />;
    case Role.Infirmier:
      return <DashboardInfirmier />;
    case Role.Daf:
      return <DashboardAdmin />;
    case Role.Dga:
      return <DashboardAdmin />;
  }
}

function Dashboard() {
  const { user } = useAuth();
  
  return (
    <>
      {renderDashboard(user?.roles?.name)}
    </>
  )
}

export default Dashboard