import {AssuranceType} from "../../../models/Assurance";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useAssurance, useService} from "../../../api";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";
import {Form, message, Tag} from "antd";
import {onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {ServiceType} from "../../../models/Service";
import {ServiceFormModal} from "../../../components/service/ServiceFormModal";
import {AssuranceFormModal} from "../../../components/assurance/AssuranceFormModal";

const columns: ColumnConfig<AssuranceType>[] = [
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (record) => <img src={`/images/${record.image}`} alt={record.nom} className="w-12 h-12" />,
  },
  {
    title: 'Nom',
    dataIndex: 'nom',
    key: 'nom',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Taux',
    dataIndex: 'taux',
    key: 'taux',
    render: (record) => <span>{record.taux} %</span>
  },
];
function ListAssurance() {
  const {user} = useAuth();
    const { data, isLoading, update, remove } = useAssurance();
    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();
    const onCreate = (values: any) => {
        update(
            values,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "L'assurance a été modifiée"),
                onError: (values) => onErrorResponse(messageApi, values, "La modification de l'assurance a échouée")
            }
        );
        setOpen(false);
    }

    const onUpdate = (record: AssuranceType) => {
        form.setFieldsValue(record);
        setOpen(true );
    }
    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "L'assurance a été supprimée"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression de l'assurance a échouée")
            }
        );
    }

  return (
    <>
      {contextHolder}
      <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
        <DynamicTable<AssuranceType>
            queryKey="assuranceQuery"
            header={
              <div className="flex justify-between">
                  <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Assurances</Tag>
              </div>
            }
            columns={columns}
            data={data}
            isLoading={isLoading}
            action={
                hasRole(user.roles.name, Role.Admin) ? {
                  onUpdateRow: onUpdate,
                  onDeleteRow: onDelete
                } : undefined
            }
        />
      </div>
        <AssuranceFormModal
            open={open}
            onCreate={onCreate}
            onCancel={() => setOpen(false)}
            form={form}
            updated={true}
        />
    </>
  )
}

export default ListAssurance