import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Form, FormInstance, Input, InputNumber, Modal, Row, Select} from 'antd';
import {SoinType} from "../../models/Soin";
import {useTypeSoin, useService} from "../../api";
import {emptyFieldError} from "../../utilities/Utils";
import TextArea from "antd/lib/input/TextArea";
import PatientAutoComplete from "../patient/PatientAutoComplete";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: SoinType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<SoinType>;
    updated: boolean
}

interface OptionValue {
    value: number,
    label: string
}
export const SoinFormModal: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
    form,
    updated
                                                                        }) => {
    const {data: typeSoins} = useTypeSoin();
    const {data: services} = useService();
    const [typeSoinOption, setTypeSoinOption] = useState<OptionValue[]>();
    const [serviceOption, setServiceOption] = useState<OptionValue[]>();
    const [initialPatient, setInitialPatient] = useState<string>();

    const onTypeSoinChange = (value: number, name?: any) => {
        const typeSoin = typeSoins.filter(typeSoin => typeSoin.id === value)[0];
        if(name) {
            form.setFieldValue(name, typeSoin.prix);
        } else {
            form.setFieldValue("prix", typeSoin.prix);
        }
    }

    const onSelectPatient = (name: string) => {
        form.setFieldValue("patient", name);
    }


    useEffect(() => {
        if(updated) setInitialPatient(form.getFieldValue("patient"));
    }, [form, updated]);


    useEffect(() => {
        const opt: OptionValue[] = [];
        typeSoins.forEach(typeSoin => opt.push({value: typeSoin.id, label: typeSoin.nom}));
        setTypeSoinOption([...opt]);
    }, [typeSoins]);

    useEffect(() => {
        const opt: OptionValue[] = [];
        services.forEach(service => opt.push({value: service.id, label: service.nom}));
        setServiceOption([...opt]);
    }, [services]);

    const SoinItem = () => (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="typeSoin"
                        label="Type de soin"
                        rules={[{ required: true, message: emptyFieldError}]}
                    >
                        <Select
                            onChange={onTypeSoinChange}
                            options={typeSoinOption}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="service"
                        label="service"
                        rules={[{ required: true, message: emptyFieldError}]}
                    >
                        <Select options={serviceOption} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="Observation" name="observation" rules={[{ required: true, message: emptyFieldError}]}>
                <Input />
            </Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Prix" name="prix">
                        <Input prefix="XAF" suffix="FCFA" disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Part Payée" name="partPayee">
                        <InputNumber className="w-full" max={form.getFieldValue('prix') ?? 0} prefix="XAF" suffix="FCFA" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );

    const SoinItems = () => (
        <Form.List name="soins">
            {(fields, {add, remove}) => (
                <>
                    {fields.map(({key, name, ...restField}) => (
                        <Row key={key} className="w-full -align-baseline" gutter={10}>
                            <Col span={11}>
                                <Form.Item
                                    {...restField}
                                    label="Type de soin"
                                    name={[name, 'typeSoin']}
                                    rules={[{required: true, message: emptyFieldError}]}
                                >
                                    <Select
                                        onChange={(value) => onTypeSoinChange(value, ["soins", name, 'prix'])}
                                        options={typeSoinOption}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    {...restField}
                                    label="Service"
                                    name={[name, 'service']}
                                    rules={[{required: true, message: emptyFieldError}]}
                                >
                                    <Select options={serviceOption}/>
                                </Form.Item>
                            </Col>
                            <Col span={2} className="flex justify-center">
                                <MinusCircleOutlined className="text-secondary" style={{fontSize: 20}}
                                                     onClick={() => remove(name)}/>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    {...restField}
                                    label="Observation"
                                    name={[name, 'observation']}
                                    rules={[{required: true, message: emptyFieldError}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    {...restField}
                                    label="Prix"
                                    name={[name, 'prix']}
                                >
                                    <Input prefix="XAF" suffix="FCFA" disabled/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    {...restField}
                                    label="Part payee"
                                    name={[name, 'partPayee']}
                                >
                                    <InputNumber className="w-full" prefix="XAF" max={form.getFieldValue(["soins", name, 'prix'])} suffix="FCFA"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    ))}
                    <Form.Item>
                        <Button type="dashed" className="hover:text-primary hover:border-primary"
                                onClick={() => add()} block icon={<PlusOutlined/>}>
                            Ajouter un soin
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );


    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour du soin" : "Administrer un soin"}
            okText={updated ? "Modifier" : "Enregistrer"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            maskClosable={false}
            width={900}
        >
            <Form
                form={form}
                name="form_in_modal"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item name="personnel" hidden={true}><Input /></Form.Item>
                <Form.Item
                    label="Patient"
                    name="patient"
                    rules={[{ required: true, message: emptyFieldError}]}
                    initialValue={updated ? form.getFieldValue("patient") : ""}
                >
                    <PatientAutoComplete
                        onSelect={onSelectPatient}
                        value={updated ? initialPatient : " "}
                    />
                </Form.Item>
                {   updated ? <SoinItem /> : <SoinItems /> }
            </Form>
        </Modal>
    );
};