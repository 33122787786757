import React, {ReactNode, useEffect, useState} from 'react';
import {Table, Input, Button, Space, Popconfirm, message, Row, Col} from 'antd';
import {DeleteOutlined, FormOutlined, SearchOutlined, EyeOutlined, ReloadOutlined} from '@ant-design/icons';

import {ColumnType} from "antd/lib/table";
import {HospitalisationType} from "../models/Hospitalisation";
import {useQueryClient} from "@tanstack/react-query";

interface ActionType<T> {
    onShowRow?: (record: T) => void;
    onUpdateRow?: (record: T) => void;
    onDeleteRow?: (index: number) => void;
    customAction?: (record: T) => React.ReactNode;
}
interface ColumnConfig<T> {
    title: string;
    dataIndex: keyof T;
    key: keyof T;
    filterable?: boolean;
    sortable?: boolean;
    confirmAction?: boolean;
    onConfirm?: (record: T) => void;
    tagStyle?: (value: any) => React.CSSProperties;
    headerTitle?: string;
    render?: ((record: T) => React.ReactNode) | ((record: T) => Promise<ReactNode>);
}

interface TableProps<T> {
    columns: ColumnConfig<T>[];
    action?: ActionType<T>;
    data: T[];
    isLoading?: boolean;
    queryKey?: string;
    header?: React.ReactNode;
}

const DynamicTable = <T extends Record<string, any>>({ columns, data = [], action, isLoading, header, queryKey }: TableProps<T>) => {
    const queryClient = useQueryClient();
    const [searchText, setSearchText] = useState('');
    const [searchColumn, setSearchColumn] = useState<keyof T | undefined>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(isLoading ?? false);
    }, [isLoading]);

    const refreshData = () => {
        setLoading(true);
        if(queryKey) {
            queryClient.invalidateQueries([queryKey]);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };
    const getColumnSearchProps = (column: ColumnConfig<T>) => ({
        filterDropdown: () => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Rechercher ${column.title}`}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={() => handleSearch(searchText, column.dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(searchText, column.dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Rechercher
                </Button>
                <Button onClick={() => handleReset(column.dataIndex)} size="small" style={{ width: 90 }}>
                    Réinitialiser
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: T) =>
            record[column.dataIndex] &&
            record[column.dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setSearchColumn(column.dataIndex);
            }
        },
    });
    const handleSearch = (searchText: string, dataIndex: keyof T) => {
        setSearchText(searchText);
        setSearchColumn(dataIndex);
    };

    const handleReset = (dataIndex: keyof T) => {
        setSearchText('');
        setSearchColumn(undefined);
    };

    const handleSelectAllRows = (selected: boolean) => {
        const allRowKeys = data.map((item) => item.key); // Replace `key` with the actual unique key of each row item
        setSelectedRowKeys(selected ? allRowKeys : []);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleShow = (e: React.MouseEvent<HTMLAnchorElement>, record: T) => {
        e.preventDefault();
        if(action?.onShowRow)
            action?.onShowRow(record);
    };

    const handleUpdate = (e:  React.MouseEvent<HTMLAnchorElement>, record: T) => {
        e.preventDefault();
        if(action?.onUpdateRow)
            action?.onUpdateRow(record);
    };
    const handleDelete = (index: number) => {
        if(action?.onDeleteRow)
            action?.onDeleteRow(index);
    };

    let mappedColumns: ColumnType<T>[] = columns.map((column) => ({
        ...column,
        ...(column.filterable ? getColumnSearchProps(column) : {}),
        ...(column.sortable ? { sorter: (a: T, b: T) => ((a[column.dataIndex as keyof T] as any) > (b[column.dataIndex as keyof T] as any)) ? 1 : -1 } : {}),
        render: (text: string, record: T) => (column.render ? column.render(record) : text),
    })) as ColumnType<T>[];

    if(action != null) {
        mappedColumns = [
            ...mappedColumns,
            {
                title: 'Actions',
                dataIndex: 'action',
                key: 'action',
                render: (_: any, record: T) => {
                    return (
                        <Space size="middle">
                            {
                                action?.onShowRow != null && (
                                    <a className="text-yellow-700 hover:text-yellow-900" onClick={(e) => handleShow(e, record)}>
                                        <EyeOutlined style={{ fontSize: '18px' }} />
                                    </a>
                                )
                            }
                            {
                                action?.customAction != null && action?.customAction(record)
                            }
                            {
                                action?.onUpdateRow != null && (
                                    <a className="text-primary hover:text-primary-dark" onClick={(e) => handleUpdate(e, record)}><FormOutlined style={{ fontSize: '18px' }} /></a>
                                )
                            }
                            {
                                action?.onDeleteRow != null && (
                                    <Popconfirm
                                        title={`Voulez-vous supprimer ${record?.nom ?? ""}?`}
                                        onConfirm={() => handleDelete(record.id)}
                                        okText="Oui"
                                        cancelText="Non"
                                    >
                                        <a className="text-secondary hover:text-secondary-dark"><DeleteOutlined style={{ fontSize: '18px' }}  /></a>
                                    </Popconfirm>
                                )
                            }
                        </Space>
                    );
                },
            },
        ]
    }

    return (
        <>
            <Table<T>
                title={() => <Row className="w-full" gutter={2}>
                    <Col span={23}>
                        {header ?? null}
                    </Col>
                    <Col span={1} className="flex justify-end">
                        <Button
                            type="primary"
                            className="bg-black border-black"
                            icon={<ReloadOutlined />}
                            loading={loading}
                            onClick={() => refreshData()}
                        />
                    </Col>
                </Row>
                }
                columns={mappedColumns}
                dataSource={data}
                loading={loading}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 'max-content' }}
                rowKey={(record) => record.id as string}
                rowSelection={rowSelection}
                style={{minHeight: 500}}
                className="bg-white w-full"
            />
        </>
    );
};
export default DynamicTable;
export type { ColumnConfig };
