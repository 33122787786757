import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileInvoice,
    faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons'

import DashboardStatCard, {DashboardStatCardItemType} from '../../components/dashboard/DashboardStatCard';
import ServiceList from '../../components/service/ServiceList';
import AssuranceList from '../../components/assurance/AssuranceList';
import ListFacture from "./facture/List";
import React, {useEffect, useState} from "react";
import { useFacture, useRecu} from "../../api";
import {EtatFacture} from "../../utilities/Utils";

function DashboardCaissier() {
    const [items, setItems] = useState<Array<DashboardStatCardItemType>>([]);
    const {customQuery: customFactureQuery} = useFacture();
    const {count: totalRecu} = useRecu();
    const [totalFactureNonPayee, setTotalFactureNonPayee] = useState(0);
    const [totalFactureEnCours, setTotalFactureEnCours] = useState(0);

    useEffect(() => {
        customFactureQuery(`/count/${EtatFacture.PAYEE_EN_PARTIE}`)
            .then((data:any) => setTotalFactureEnCours(data))

        customFactureQuery(`/count/${EtatFacture.NON_PAYEE}`)
            .then((data:any) => setTotalFactureNonPayee(data))
    }, []);

    useEffect(() => {
        setItems([
            {
                key: 0,
                title: "Facture",
                description: "Non payée",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faFileInvoiceDollar} />,
                value: totalFactureNonPayee
            },
            {
                key: 1,
                title: "Facture",
                description: "En cours de paiement",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faFileInvoiceDollar} />,
                value: totalFactureEnCours
            },
            {
                key: 2,
                title: "Reçu de paye",
                description: "Généré",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faFileInvoice} />,
                value: totalRecu
            }
        ]);
    }, [totalFactureEnCours, totalFactureNonPayee, totalRecu]);

  return (
    <>
        <div className="mb-10 grid grid-cols-3 gap-2 md:grid-cols-3 lg:gap-5">
          <div className="col-span-2">
            <div className="mb-6 grid grid-cols-1 gap-2 md:gap-6 md:grid-cols-3">
              <DashboardStatCard items={items} />
            </div>
            <div className="p-4 pb-6 bg-white shadow-sm rounded border border-solid border-transparent">
              <h1 className="text-lg font-bold mb-6">Services</h1>  
              <div className="grid grid-cols-3 gap-6">
                <ServiceList />
              </div>
            </div>
          </div>
            <div className="col-span-1 p-4 bg-white shadow-sm rounded border border-transparent border-solid">
              <div className="mb-6">
                <h1 className="text-lg font-bold mb-6">Assurances</h1>
                <AssuranceList />
              </div>
            </div>            
        </div>
        <div className="mt-10 p-4 overflow-auto bg-white shadow-sm rounded border border-transparent border-solid">
          <ListFacture />
        </div>        
    </>
  )
}

export default DashboardCaissier