import {Document, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import React from "react";
import {ConsultationType} from "../../models/Consultation";
import {getAge, getFullName} from "../../utilities/Utils";
import HeaderPDF from "../HeaderPDF";
import dayjs from "dayjs";
import { Watermark } from "antd";

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
        alignSelf: 'center',
        backgroundColor: '#fff',
    },
    view: {
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    rows: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    label: {
        fontWeight: 'bold',
        color: '#000',
    },
    value: {
        flex: 1,
        color: '#000',
        borderBottom: '1pt dashed #000',
        textAlign: 'center',
    },
    symptomesView: {
        marginVertical: 20
    },
    symptomesContainer: {
        marginTop: 10
    },
    symptomeValue: {
        marginVertical: 2,
        color: '#000',
    },

});



interface ConsultationPdfInterface {
    consultation?: ConsultationType
}
const ConsultationPdf = ({consultation}:ConsultationPdfInterface) => {
    return (
        <Document title={`consultation_${consultation?.id}`}>
            <Page size={[450, 660]} fixed orientation={"landscape"} style={styles.page}>
                <View style={styles.view} >
                    <HeaderPDF title={"Consultation"} />
                    <View style={styles.rows}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Noms: </Text>
                            <Text style={styles.value}>{getFullName(consultation?.dossierMedical?.patient ?? undefined)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Age: </Text>
                            <Text style={styles.value}>
                                {
                                    consultation?.dossierMedical?.patient.dateDeNaissance
                                        ? getAge(consultation?.dossierMedical?.patient.dateDeNaissance)
                                        : ""
                                }
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Sexe (M/F): </Text>
                            <Text style={styles.value}>{consultation?.dossierMedical?.patient.sexe}</Text>
                        </View>
                    </View>
                    <View style={styles.rows}>
                        <View style={styles.row}>
                            <Text style={styles.label}>Service: </Text>
                            <Text style={styles.value}>{consultation?.service.nom}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.label}>Lieu: </Text>
                            <Text style={styles.value}>{consultation?.lieu}</Text>
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Motif: </Text>
                        <Text style={styles.value}>{consultation?.motif}</Text>
                    </View>
                    <View style={styles.symptomesView}>
                        <Text style={styles.label}>Symptomes: </Text>
                        <View style={styles.symptomesContainer}>
                            {consultation?.symptomes?.map((symptome) => <Text style={styles.symptomeValue}>{symptome.observation}</Text>)}
                        </View>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Diagnostic: </Text>
                        <Text>{consultation?.diagnostic}</Text>
                    </View>
                    <View style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Text>Prescripteur</Text>
                        <View style={{flexDirection: "row"}}>
                            <Text style={{marginRight: 4}}>Libreville le:</Text>
                            <View style={{width: 100, flexDirection: "row", justifyContent: 'space-evenly', borderBottom: '1px dashed #000'}}>
                                <Text>{dayjs(consultation?.dateCreation).get("days") + 1} /</Text>
                                <Text style={{marginHorizontal: 4}}>{dayjs(consultation?.dateCreation).get("month") + 1} /</Text>
                                <Text>{dayjs(consultation?.dateCreation).get("year")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

interface ConsultationPdfViewerProps {
    consultation?: ConsultationType
}
const ConsultationPdfViewer = (
    {consultation} : ConsultationPdfViewerProps
) => {

    return (
        <div className="container flex justify-center">
            <PDFViewer
                style={{
                    border: "none",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                }}
                width="100%"
                height={600}
            >
                <ConsultationPdf consultation={consultation} />
            </PDFViewer>
        </div>
    );
};

export default ConsultationPdfViewer;
