import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Form, FormInstance, Input, Modal, Row, Select} from 'antd';
import {BonExamenType} from "../../models/BonExamen";
import {useExamen, usePatient, useService} from "../../api";
import {emptyFieldError, getFullName} from "../../utilities/Utils";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import PatientAutoComplete from "../patient/PatientAutoComplete";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: BonExamenType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<BonExamenType>;
    updated: boolean
}

interface OptionValue {
    value: number,
    label: string
}
export const BonExamenFormModal: React.FC<CollectionCreateFormProps> = ({
   open,
   onCreate,
   onCancel,
   form,
   updated
                                                                   }) => {
    const {data: services} = useService();
    const [serviceOption, setServiceOption] = useState<OptionValue[]>();
    const [examenOptions, setExamenOptions] = useState<{ value: string }[]>([]);
    const { customQuery: examenQuery } = useExamen();
    const [isExamen, setIsExamen] = useState(false);

    useEffect(() => {
        return () => {
            examenQuery("/file")
                .then((data: any) => {
                    const ops: { value: string }[] = [];
                    data.forEach((option: string) => ops.push({value: option}));
                    setExamenOptions(ops);
                })
                .catch(e => console.log(e))
        };
    }, []);


    useEffect(() => {
        const opt: OptionValue[] = [];
        services.forEach(service => opt.push({value: service.id, label: service.nom}));
        setServiceOption([...opt]);
    }, [services]);

    const onSelect = (nom: string) => {
        form.setFieldValue("patient", nom)
    }

    const onClear = () => {
        form.setFieldValue("patient", null);
    }

    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour du bon d'examen" : "Administrer des Examens"}
            okText={"Enregistrer"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                            onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okButtonProps={{ disabled: !isExamen && !updated }}
            maskClosable={false}
            width={800}

        >
            <Form
                form={form}
                name="bon_examens"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                {
                    !updated ? (
                        <Form.Item label="Patient"
                                   name="patient"
                                   rules={[{ required: true, message: "Patient requis!" }]}
                                   initialValue={form.getFieldValue("patient")}
                        >
                            <PatientAutoComplete onSelect={onSelect} onClear={onClear}/>
                        </Form.Item>
                    ) : null
                }
                <Form.Item
                    label="Service"
                    name='service'
                    rules={[{required: true, message: emptyFieldError}]}
                >
                    <Select options={serviceOption}/>
                </Form.Item>
                {
                    !updated ? (
                        <Form.Item label="Renseignement clinique"
                            name="renseignementClinique"
                            rules={[{required: true, message: "Le motif est requis!"}]}
                        >
                            <Input/>
                        </Form.Item>
                    ) : null
                }
                <Form.Item label="Salle"
                           name="salle"
                >
                    <Input />
                </Form.Item>
                <Form.List name="examens">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row key={key} className="w-full -align-baseline" gutter={10}>
                                    <Col span={11}>
                                        <Form.Item
                                            {...restField}
                                            label="Examen"
                                            name={[name, 'nom']}
                                            rules={[{ required: true, message: emptyFieldError }]}
                                        >
                                            <AutoComplete
                                                options={examenOptions}
                                                filterOption={(inputValue, option) =>
                                                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                disabled={updated}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            {...restField}
                                            label="Résultat"
                                            name={[name, 'resultat']}
                                            rules={[{ required: true, message: emptyFieldError }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    {
                                        !updated ? (
                                            <Col span={2} className="flex justify-center">
                                                <MinusCircleOutlined className="text-secondary" style={{fontSize: 20}}
                                                                 onClick={() => {
                                                                     remove(name);
                                                                     setIsExamen((fields.length - 1) > 0);
                                                                 }}
                                                />
                                            </Col>
                                        ) : null
                                    }
                                </Row>
                            ))}
                            {
                                !updated ? (
                                    <Form.Item>
                                        <Button type="dashed" className="hover:text-primary hover:border-primary"
                                                onClick={() => {
                                                    add();
                                                    setIsExamen(fields.length >= 0);
                                                }} block icon={<PlusOutlined/>}>
                                            Ajouter un examen
                                        </Button>
                                    </Form.Item>
                                ) : null
                            }
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};