import {View, StyleSheet} from "@react-pdf/renderer";
import FactureTableHeader from "./FactureTableHeader";
import FactureTableRow, {FactureItemsType} from "./FactureTableRow";
import FactureTableFooter from "./FactureTableFooter";
import FactureTableBlankSpace from "./FactureTableBlankSpace";

const tableRowsCount = 10;
const borderColor = '#3a0202'
const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderWidth: 1,
        borderColor: borderColor,
        borderRadius: 8,
    },
});

interface FactureItemsTableInterface {
    items?: FactureItemsType[]
}
const FactureItemsTable = ({items}: FactureItemsTableInterface) => (
    <View style={styles.tableContainer}>
        <FactureTableHeader />
        <FactureTableRow items={items} />
        <FactureTableBlankSpace rowsCount={ tableRowsCount - (items?.length ?? 0)} />
        <FactureTableFooter items={items} />
    </View>
);

export default FactureItemsTable