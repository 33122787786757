import {Button, Form, message, Tag, Tooltip} from 'antd'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useOrdonnance, useDossierMedical, useService} from "../../../api";
import {OrdonnanceType} from "../../../models/Ordonnance";
import {getFullName, getValidDate, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";
import {OrdonnanceFormModal} from "../../../components/ordonnance/OrdonnanceFormModal";
import ShowOrdonnancePdfModal from "../../../components/ordonnance/ShowOrdonnancePdfModal";

const columns: ColumnConfig<OrdonnanceType>[] = [
    {
        title: 'Date',
        dataIndex: 'dateCreation',
        key: 'dateCreation',
        render: record => <span>{getValidDate(record.dateCreation)}</span>
    },
    {
        title: 'Patient',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span className="text-primary">{getFullName(record?.dossierMedical?.patient)}</span>
    }
];
function ListOrdonnance() {
    const {user} = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const { data, add, update, remove, isLoading } = useOrdonnance();
    const {data: dossiersMedicaux} = useDossierMedical();
    const {one: getService} = useService();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ordonnance, setOrdonnance] = useState<OrdonnanceType>();

    const onCreate = async (values: any, updated: boolean) => {
        try {
            values.service = await getService(values.service);
            values.dossierMedical = dossiersMedicaux?.filter(dossier => getFullName(dossier?.patient).toLowerCase() === values.patient.toLowerCase())[0];
            add(
                values,
                {
                    onSuccess: (values) => onSuccessResponse(messageApi, values, "L'ordonnance a été ajoutée"),
                    onError: (values) => onErrorResponse(messageApi, values, "L'ajout de l'ordonnance a échoué")
                }
            );
            setOpen(false);
            form.resetFields();
        } catch (error) { console.log(error) }
    };

    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "L'ordonnace a été supprimée"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression de l'ordonnance a échouée")
            }
        );
    }

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onShow = (record: OrdonnanceType) => {
        setOrdonnance(record);
        showModal();
    }


    return (
        <>
            {contextHolder}
            <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
                <DynamicTable<OrdonnanceType>
                    queryKey="ordonnanceQuery"
                    header={
                        <div className="flex justify-between">
                            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Ordonnance</Tag>
                            {
                                (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Infirmier) || hasRole(user.roles.name, Role.Medecin))
                                    ? (
                                        <Button type="primary" size="middle" className="bg-black border-black" onClick={() => {
                                            setOpen(!open);
                                        }}>
                                            Ajouter
                                        </Button>
                                    ) : null
                            }
                        </div>
                    }
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    action={{
                        onShowRow: onShow,
                        onDeleteRow: hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Medecin) ? onDelete : undefined
                    }}
                />
                <OrdonnanceFormModal
                    open={open}
                    onCreate={onCreate}
                    onCancel={onCancel}
                    updated={false}
                    form={form}
                />
                <ShowOrdonnancePdfModal ordonnance={ordonnance} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
            </div>
        </>
    )
}

export default ListOrdonnance