import { OrderedListOutlined, ProfileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {Menu} from 'antd';
import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

const items: MenuProps['items'] = [
    { label: <Link to="chronologie-acte">Chronologie</Link>, key: "chronologie-acte", icon: <OrderedListOutlined /> },
    { label: <Link to="list-acte-table">Liste</Link>, key: "list-acte-table", icon: <ProfileOutlined /> },    
  ];

function ListActe() {
    const [current, setCurrent] = useState('');
    const onClick: MenuProps['onClick'] = e => {
        setCurrent(e.key);
    };

   return (
       <>
            <div className="grid grid-cols-5 gab-0 bg-white p-4 rounded border border-transparent border-solid border-100">
               <Menu
                        mode="inline"
                        onClick={onClick}
                        selectedKeys={[current]}
                        items={items}
                        className="col-span-1"
               />
               <div className="col-span-4 pl-4 pt-4">
                <Outlet />
            </div>
       </div>
        </>
   );
}

export default ListActe;