import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {api, Config} from "../config";
import {FilterType} from "../utilities/Utils";

interface ApiProps<DataType> {
    apiUrl: string;
    queryKey: string;
}

const useApi = <DataType extends { id: number }>({ apiUrl, queryKey }: ApiProps<DataType>) => {
    const queryClient = useQueryClient();
    const networkMode = "always";

    const uri = `${Config.local_api_url}/${apiUrl}`;

    const customRequest = async <T>(method: string, url: string, data?: any): Promise<T> => {
        try {
            const response = await api.request<T>({
                method,
                url,
                data,
            });
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la requête : ${error}`);
        }
    };
    const fetchAllApi = async (): Promise<DataType[]> => {
        try {
            const response = await api.get<DataType[]>(uri);
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la récupération des: ${apiUrl}`);
        }
    };



    const fetchAllWithFiltersApi = async (filters : FilterType): Promise<DataType[]> => {
        try {
            const queryParams = Object.entries(filters)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');

            const filterUri = `${uri}/filter?${queryParams}`;

            const response = await api.get<DataType[]>(filterUri);
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la récupération des: ${apiUrl}`);
        }
    };
    const fetchByIdApi = async (id: number): Promise<DataType> => {
        try {
            const response = await api.get<DataType>(`${uri}/${id}`);
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la récupération des données: ${apiUrl}`);
        }
    };

    const fetchCountApi = async (): Promise<number> => {
        try {
            const response = await api.get<number>(`${uri}/count`);
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la récupération du nombre total: ${apiUrl}`);
        }
    };

    const createApi = async (newData: Partial<DataType>): Promise<DataType> => {
        try {
            const response = await api.post<DataType>(uri, newData);
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la création des ${apiUrl}`);
        }
    };

    const updateApi = async (updatedData: DataType): Promise<DataType> => {
        try {
            const response = await api.put<DataType>(`${uri}/${updatedData.id}`, updatedData);
            return response.data;
        } catch (error) {
            throw new Error(`Erreur lors de la mise à jour des: ${apiUrl}`);
        }
    };

    const deleteApi = async (id: number): Promise<number> => {
        try {
            await api.delete(`${uri}/${id}`);
            return id;
        } catch (error) {
            throw new Error(`Erreur lors de la suppression des données ${apiUrl}`);
        }
    };

    const FilteredData =  (filter: FilterType) => useQuery<DataType[]>({
            queryKey: [queryKey, filter],
            queryFn: () => fetchAllWithFiltersApi(filter),
            networkMode: networkMode
    });


    const { data: apiData, isLoading, error, refetch  } = useQuery<DataType[]>( [queryKey], fetchAllApi,  { networkMode: networkMode });

    const {data: count} = useQuery<number>([queryKey, 6], fetchCountApi, { networkMode: networkMode });
    const customQuery = async <T>(url: string): Promise<T> => {
        return customRequest<T>('GET', `${uri}${url}`);
    };

    const customMutation = async <T>(method: string, url: string, data?: any): Promise<T> => {
        return customRequest<T>(method, `${uri}${url}`, data);
    };


    const createMutation = useMutation(createApi, {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
        networkMode: networkMode
    });

    const updateMutation = useMutation(updateApi, {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
        networkMode: networkMode
    });

    const deleteMutation = useMutation(deleteApi, {
        onSuccess: () => {
            queryClient.invalidateQueries([queryKey]);
        },
        networkMode: networkMode
    });

    return {
        data: apiData ?? [],
        filteredData: FilteredData,
        isLoading,
        error,
        count: count ?? 0,
        one: (id: number) => fetchByIdApi(id),
        add: createMutation.mutate,
        update: updateMutation.mutate,
        remove: deleteMutation.mutate,
        customQuery: customQuery,
        customMutation: customMutation,
    };
};

export default useApi;
