import {useEffect, useState} from "react";
import {usePatient} from "../../api";

import { Column } from '@ant-design/charts';
import {Config} from "../../config";

interface GenderStatistics {
    gender: string;
    count: number;
}

interface GenderStatisticsChartProps {
    data: GenderStatistics[];
}

const GenderStatisticsChart: React.FC<GenderStatisticsChartProps> = ({ data }) => {
    const chartData = data.map((item) => ({
        gender: item.gender,
        count: item.count,
    }));

    const config = {
        data: chartData,
        xField: 'gender',
        yField: 'count',
        meta: {
            gender: {
                alias: 'Genre',
            },
            count: {
                alias: 'Nombre de patients',
            },
        },
        colorField: 'gender',
    };

    return <Column {...config} />;
}

const PatientsBySex = () => {
    const [totalHomme, setTotalHomme] = useState<number>(0);
    const [totalFemme, setTotalFemme] = useState<number>(0);
    const { customQuery } = usePatient();
    const [data, setData] = useState<GenderStatistics[]>([]);

    useEffect(() => {
        customQuery("/count/sex").then((data: any) => {
            const {M, F} = data;
            setTotalHomme(M ? + M : 0);
            setTotalFemme(F ? + F : 0);
        }).catch(error => console.log(error));
    }, [customQuery]);

    useEffect(() => {
        setData([
            {
                gender: 'Homme',
                count: totalHomme,
            },
            {
                gender: 'Femme',
                count: totalFemme,
            }
        ]);
    }, [totalFemme, totalHomme]);


    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return <GenderStatisticsChart data={data} />;
};


function PatientBySexChart() {
    return (<PatientsBySex />);
}

export default PatientBySexChart;