import {AutoComplete, Select, Spin} from 'antd';
import type { SelectProps } from 'antd/es/select';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {usePersonnelMedical, useService} from "../../api";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}
interface UserSearchTimelineProps {
  onSelect: (value: number) => void;
}

const UserSearchTimeline = ({
    onSelect
} : UserSearchTimelineProps) => {
  const [options, setOptions] = useState<{ value: number, label: string }[]>([]);
  const { data } = usePersonnelMedical();

  useEffect(() => {
      const ops: { value: number, label: string }[] = [];
      data.forEach((personnel) => ops.push({value: personnel.id, label: personnel.nom + " " + personnel.prenom}));
      setOptions(ops);
  }, []);


  return (
    <Select
        placeholder="Selection Personnel"
        className="w-48"
        onChange={onSelect}
        options={options}
    />
  );
};

export default UserSearchTimeline;