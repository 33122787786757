import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {api, Config} from "../config";
import {User} from "../utilities/auth";

const currentApi = `${Config.local_api_url}/login/`;

interface LoginType {
    email: string,
    password: string
}

interface UserType {
    username: string
}
export interface LoginResponseType {
    user: UserType,
    token: string
}
export function useLogin() {

    return useMutation(async ({email, password}: LoginType) => {
            console.log([email, password])
            try {
                const response = await api.post<User>(`${currentApi}`, {
                    "email": email,
                    "password": password
                });
                return response.data;
            } catch (error) {
                console.log(error);
                throw new Error(`Erreur lors de l'authentification`);
            }
        },
    );
}