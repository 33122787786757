import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Menu, MenuProps, message, Space} from "antd";
import {Outlet, useNavigate} from "react-router-dom";
import {
    ApartmentOutlined, DownOutlined,
    FileProtectOutlined,
    MedicineBoxOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {RoleFormModal} from "../../components/role/RoleFormModal";
import {useAssurance, useRole, useService, useTypeSoin} from "../../api";
import {ServiceFormModal} from "../../components/service/ServiceFormModal";
import {useForm} from "antd/lib/form/Form";
import {onErrorResponse, onSuccessResponse} from "../../utilities/Utils";
import {TypeSoinFormModal} from "../../components/typeSoin/TypeSoinFormModal";

const items: MenuProps['items'] = [
    { label: "Rôle", key: "role", icon: <TeamOutlined /> },
    { label: "Service", key: "service", icon: <ApartmentOutlined /> },
    { label: "Assurance", key: "assurance", icon: <FileProtectOutlined /> },
    { label: "Type Soin", key: "type-soin", icon: <MedicineBoxOutlined /> },
];

function AdminAppSetting() {
    const navigation = useNavigate();
    const [current, setCurrent] = useState('');
    const [currentModal, setCurrentModal] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const [openRoleModal, setOpenRoleModal] = useState(false);
    const [openServiceModal, setOpenServiceModal] = useState(false);
    const [openAssuranceModal, setOpenAssuranceModal] = useState(false);
    const [openTypeSoinModal, setOpenTypeSoinModal] = useState(false);
    const [serviceForm] = useForm();
    const [typeSoinForm] = useForm();
    const {add: addRole} = useRole();
    const {add: addAssurance} = useAssurance();
    const {add: addService} = useService();
    const {add: addTypeSoin} = useTypeSoin();

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        console.log('click left button', e);

    };

    const onClick: MenuProps['onClick'] = e => {
        setCurrent(e.key);
        switch (e.key) {
            case "service":
                navigation("list-service");
                break;
            case "role":
               navigation("");
                break;
            case "assurance":
                navigation("list-assurance");
                break;
            case "type-soin":
                navigation("list-type-soin");
                break;
        }
    };

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        setCurrentModal(e.key);
        handleModal(e.key);
    };
    const onCreate = (values: any) => {
        switch (currentModal) {
            case "service":
                addService(
                    values,
                    {
                        onSuccess: () => onSuccessResponse(messageApi, values, "Le service a été ajouté") ,
                        onError: () => onErrorResponse(messageApi, values, "Le servie n'a pas été ajouté")
                    }
                );
                setOpenServiceModal(false);
                break;
            case "role":
                addRole(
                    values,
                    {
                        onSuccess: (values) => onSuccessResponse(messageApi, values, "Le rôle a été ajouté") ,
                        onError: (values) => onErrorResponse(messageApi, values, "Le rôle n'a pas été ajouté")
                    }
                );
                setOpenRoleModal(false);
                break;
            case "assurance":
                addAssurance(
                    values,
                    {
                        onSuccess: (values) => onSuccessResponse(messageApi, values, "L'assurance a été ajoutée") ,
                        onError: (values) => onErrorResponse(messageApi, values, "L'assurance n'a pas été ajoutée")
                    }
                );
                setOpenAssuranceModal(false);
                break;
            case "type-soin":
                addTypeSoin(
                    values,
                    {
                        onSuccess: (values) => onSuccessResponse(messageApi, values, "Le type de soin a été ajouté") ,
                        onError: (values) => onErrorResponse(messageApi, values, "Le type de soin n'a pas été ajouté")
                    }
                );
                setOpenTypeSoinModal(false);
                break;
        }
    }

    const handleModal = (current: string) => {
        switch (current) {
            case "service":
                setOpenServiceModal(!openServiceModal);
                break;
            case "role":
                setOpenRoleModal(!openRoleModal);
                break;
            case "assurance":
                setOpenAssuranceModal(!openAssuranceModal);
                break;
            case "type-soin":
                setOpenTypeSoinModal(!openTypeSoinModal);
                break;
        }
    }

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    useEffect(() => {
        setCurrent('');
    }, []);

    return (
        <>
            <div className="grid grid-cols-5 gab-0 bg-white p-4 rounded border border-solid border-transparent border-100">
                {contextHolder}
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    items={items}
                    className="col-span-1"
                    defaultSelectedKeys={['list-role']}></Menu>
                <div className="col-span-4 pl-4 pt-4">
                    <div className="flex justify-between">
                        <h1 className="text-base font-bold mb-4 mr-4">Administration</h1>
                        <div>
                            <Dropdown menu={menuProps}>
                                <Button type="primary" className="bg-primary border-primary" onClick={handleButtonClick}>
                                    <Space>
                                        Ajouter
                                        <DownOutlined />
                                    </Space>
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
            <RoleFormModal
                open={openRoleModal}
                onCreate={onCreate}
                onCancel={() => handleModal("role")}
            />
            <ServiceFormModal
                open={openServiceModal}
                onCreate={onCreate}
                onCancel={() => {
                    handleModal("service");
                    serviceForm.resetFields();
                }}
                form={serviceForm}
                updated={false}
            />
            <TypeSoinFormModal
                open={openTypeSoinModal}
                onCreate={onCreate}
                onCancel={() => {
                    handleModal("type-soin");
                    typeSoinForm.resetFields();
                }}
                form={typeSoinForm}
                updated={false}
            />
        </>
    );
}

export default AdminAppSetting;