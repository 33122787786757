import { Timeline } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import {useRdv} from "../../api";
import {EtatOperation, getFullName, getValidDate} from "../../utilities/Utils";
import {RdvType} from "../../models/Rdv";
import moment from "moment";

const DashboardRDVTimeline: React.FC = () => {
    const [rdv, setRdv] = useState<Array<RdvType>>([]);
    const {filteredData} = useRdv();
    const {data} = filteredData({"etat": EtatOperation.EN_ATTENTE});

    useEffect(() => {
        setRdv(data ? data.slice(0, 5) : []) ;
    }, [data]);
    return (
      <Timeline>
          {rdv.map(r => 
              <Timeline.Item  className="mb-2"  key={r.id} color="green" dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}>
                {`Consultation en ${r.service?.nom} avec le Dr ${getFullName(r.personnelMedical)}, le ${getValidDate(r.date)} à ${r.heure}`}
              </Timeline.Item>
          ) }
      </Timeline>
    );
}


export default DashboardRDVTimeline;