import {Button, Form, message, Tag, Tooltip} from 'antd'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useBonExamen, useDossierMedical, useService} from "../../../api";
import {BonExamenType} from "../../../models/BonExamen";
import {getFullName, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";
import {BonExamenFormModal} from "../../../components/bonExamen/BonExamenFormModal";
import ShowBonExamenPdfModal from "../../../components/bonExamen/ShowBonExamenPdfModal";
import {ForkOutlined} from "@ant-design/icons";

const columns: ColumnConfig<BonExamenType>[] = [
    {
        title: 'Patient',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span className="text-primary">{getFullName(record?.dossierMedical?.patient)}</span>
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (record) => <span>{record.service?.nom}</span>
    },
    {
        title: 'Salle',
        dataIndex: 'salle',
        key: 'salle',
    },
    {
        title: 'Renseignement Clinique',
        dataIndex: 'renseignementClinique',
        key: 'renseignementClinique',
    }
];
function ListBonExamen() {
    const {user} = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const { data, add, update, remove, isLoading } = useBonExamen();
    const {data: dossiersMedicaux} = useDossierMedical();
    const {one: getService} = useService();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [updated, setUpdated] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bonExamen, setBonExamen] = useState<BonExamenType>();

    const onCreate = async (values: any, updated: boolean) => {
        try {
            values.service = await getService(values.service);
            if (updated) {
                update(
                    values,
                    {
                        onSuccess: (values) => {
                            onSuccessResponse(messageApi, values, "Le bon d'examen a été modifié")
                            onShow(values);
                        },
                        onError: (values) => onErrorResponse(messageApi, values, "La modification du bon d'examen a échouée")
                    }
                );
            } else {
                values.dossierMedical = dossiersMedicaux?.filter(dossier => getFullName(dossier?.patient).toLowerCase() === values.patient.toLowerCase())[0];
                add(
                    values,
                    {
                        onSuccess: (values) => onSuccessResponse(messageApi, values, "Le bon d'examen a été ajouté"),
                        onError: (values) => onErrorResponse(messageApi, values, "L'ajout du bon d'examen a échoué")
                    }
                );
            }
            setOpen(false);
            form.resetFields();
        } catch (error) { console.log(error) }
    };

    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le bon d'examen a été supprimé"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression du bon d'examen a échouée")
            }
        );
    }

    const handleExaminer = (record: BonExamenType) => {
        form.setFieldsValue(record);
        form.setFieldValue("service", record.service?.id);
        setUpdated(true);
        setOpen(true);
    }

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onShow = (record: BonExamenType) => {
        setBonExamen(record);
        showModal();
    }

    const onCustomRow = (record: BonExamenType) => (
        (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Infirmier) || hasRole(user.roles.name, Role.Medecin))
            ?
            (
                <a className="text-green-600 hover:text-green-800" onClick={(e) => handleExaminer(record)}>
                    <Tooltip title="Examiner"><ForkOutlined  style={{ fontSize: '18px' }} /></Tooltip>
                </a>
            ) : null
    );



    return (
        <>
            {contextHolder}
            <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
                <DynamicTable<BonExamenType>
                    queryKey="bonExamenQuery"
                    header={
                        <div className="flex justify-between">
                            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Bons d'examens</Tag>
                            {
                                (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Infirmier) || hasRole(user.roles.name, Role.Medecin))
                                    ? (
                                        <Button type="primary" size="middle" className="bg-black border-black" onClick={() => {
                                            setUpdated(false);
                                            setOpen(!open);
                                        }}>
                                            Ajouter
                                        </Button>
                                    ) : null
                            }
                        </div>
                    }
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    action={{
                            onShowRow: onShow,
                            customAction: onCustomRow,
                            onDeleteRow: hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Infirmier) || hasRole(user.roles.name, Role.Medecin) ? onDelete : undefined
                        }}
                />
                <BonExamenFormModal
                    open={open}
                    onCreate={onCreate}
                    onCancel={onCancel}
                    updated={updated}
                    form={form}
                />
                <ShowBonExamenPdfModal bonExamen={bonExamen} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
            </div>
        </>
    )
}

export default ListBonExamen