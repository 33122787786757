import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3a0202'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: 'rgba(58,2,2,0.38)',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        marginVertical: 0,
        paddingVertical: 0,
    },
    desc: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        marginVertical: 0,
        paddingVertical: 0,
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        marginVertical: 0,
        paddingVertical: 0,
    },
    price: {
        width: '30%',
        marginVertical: 0,
        paddingVertical: 0,
    },
});

const FactureTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.desc}>Description</Text>
        <Text style={styles.qty}>Qté</Text>
        <Text style={styles.price}>Prix</Text>
    </View>
);

export default FactureTableHeader