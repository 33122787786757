import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Alert, Button, Checkbox, Divider, Form, Input, message, Typography} from "antd";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import logo from "../../assets/logos/main-logo-transparent.png";
import "./Login.css";
import {useLogin} from "../../api/Authentication";
import {useAuth as useAuth} from "../../provider/AuthProvider";
import {Role, saveToken, User} from "../../utilities/auth";
import {RoleType} from "../../models/Role";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faKey, faRightToBracket, faUser} from '@fortawesome/free-solid-svg-icons';
import {onErrorResponse} from "../../utilities/Utils";

type LocationProps = {
  state: {
    from: Location;
  };
};

const users = [
  {
    id: 1,
    photo: 'servais.jpg',
    name: 'admin',
    password: 'admin',
    role: Role.Admin,
    ressources: [
      {
        name: "HOSPITALISATION",
        rights: ["VOIR", "AJOUTER", "MODIFIER", "SUPPRIMER"]
      }
    ]
  },
  {
    id: 2,
    name: 'infirmier',
    password: 'infirmier',
    role: Role.Infirmier,
  },
  {
    id: 3,
    name: 'medecin',
    password: 'medecin',
    role: Role.Medecin,
  },
  {
    id: 4,
    name: 'daf',
    password: 'daf',
    role: Role.Daf,
  },
  {
    id: 5,
    name: 'dga',
    password: 'dga',
    role: Role.Dga,
  },
  {
    id: 6,
    photo: 'secretaire.jpg',
    name: 'secretaire',
    password: 'secretaire1234',
    role: Role.Secretaire,
    ressources: [
      {
        name: "HOSPITALISATION",
        rights: ["VOIR", "AJOUTER", "MODIFIER", "SUPPRIMER"]
      }
    ]
  },
  {
    id: 7,
    photo: 'caissier.jpg',
    name: 'caissier',
    password: 'caissier',
    role: Role.Caissier,
    ressources: [
      {
        name: "FACTURE",
        rights: ["VOIR", "AJOUTER", "MODIFIER", "SUPPRIMER"]
      }
    ]
  },
]

const Login: React.FC = () => {
  const location = useLocation() as unknown as LocationProps;
  let navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const authMutation = useLogin();

  const from = location.state?.from?.pathname || "/dashboard";
  const auth = useAuth();

  const onFinish = (values: any) => {
    // let authenticatedUser: any | null = null;
    //   for(let user of users) {
    //   if(values.username === user.name && values.password === user.password) {
    //     authenticatedUser = user;
    //     break;
    //   }
    // }
    // if(authenticatedUser != null) {
    //   const role: RoleType =  {
    //     id: 1,
    //     nom: authenticatedUser.role
    //   };
    //   auth.signin({
    //     id: authenticatedUser?.id,
    //     username: authenticatedUser.name,
    //     adresse: "",
    //     contact: "",
    //     dateDeNaissance: new Date(),
    //     email: "",
    //     lastname: "",
    //     photo: "",
    //     firstname: "",
    //     sexe: "",
    //     role: role,
    //     ressources: authenticatedUser.ressources
    //   }, () => {
    //     navigate(from, { replace: true });
    //   });
    // } else {
    //   onErrorResponse(messageApi, "", "Le nom d'utilisateur ou le mot de passe est incorrect" );
    // }

      authMutation.mutate(
        {email: values.email, password: values.password},
        {
          onSuccess: (data: any, variables) => {
            console.log("in success");
            console.log(data);
            return auth.signin({
              id: data?.user.id,
              adresse: data?.user.adresse,
              contact: data?.user.contact,
              dateDeNaissance: data?.user.dateDeNaissance,
              email: data?.user.email,
              nom: data?.user.lastname,
              prenom: data?.user.firstname,
              sexe: data?.user.sexe,
              photo: data?.photo,
              username: data?.user.username,
              roles: data?.user.roles[0],
              ressources: []
            }, () => {
              saveToken(data.token);
              navigate(from, {replace: true});
          },
          )
        },
          onError: (data) => {
            console.log("in error");
            onErrorResponse(messageApi, "", "Le nom d'utilisateur ou le mot de passe est incorrect")
          }
        }
    );
  }

  const onFinishFailed = (errorInfo: any) => {
    onErrorResponse(messageApi, "", "Une erreur s'est produite lors de l'authentification!" )
  };

  return (
    <main className="w-full h-full flex flex-col justify-center items-center brightness-75 bg-no-repeat bg-cover bg-[url('../public/images/samu_ong.jpg')]"
    >
      {contextHolder}
      <Form
        name="login"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="login-form w-80 p-4 shadow-2xl rounded-lg bg-white bg-opacity-95"
      >
        <img
          className="w-full h-20 mb-2 object-cover"
          src={logo}
          alt=""
          width="400"
          height="400"
        />
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Entrez votre nom d'utilisateur!" }]}
          className="mt-4 mb-5"
        >
          <Input
              className="p-2 text-base text-black placeholder-black"
              prefix={<FontAwesomeIcon icon={faUser} className="mx-2 text-gray-500" />}
              placeholder="Entrer votre nom d'utilisateur"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Entrez votre mot de passe!" }]}
          className="mb-5"
        >
          <Input.Password
            prefix={<FontAwesomeIcon icon={faKey} className="mx-2 text-gray-500" />}
            type="password"
            className="p-2 text-base text-black placeholder-black"
            placeholder="Entrer votre mot de passe"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="h-10 login-form-button text-base bg-blue-400 border-400 text-white hover:bg-blue-600 hover:border-blue-600 hover:text-white"
          >
            Se connecter
            <FontAwesomeIcon icon={faRightToBracket} className="ml-2" />
          </Button>
        </Form.Item>
        <div className="login-form-footer">
          <h1 className="text-4xl font-bold text-center mb-2">E-SANTE</h1>
          <p className="text-center">© {(new Date()).getFullYear()} Tous droits Réservés.</p>
        </div>
      </Form>
    </main>
  );
};

export default Login;
function useAuthentication() {
    throw new Error("Function not implemented.");
}

