import {
  Button, message, Steps, Form
} from 'antd';
import React, {useEffect, useState} from 'react';
import InfoBasicConsultation from "../../../components/consultation/InfoBasicConsultation";
import BonExamenFormConsultation from "../../../components/consultation/BonExamenFormConsultation";
import PrescriptionForm from "../../../components/consultation/PrescriptionForm";
import {AuditOutlined,  FileTextOutlined, NodeIndexOutlined} from "@ant-design/icons";
import DossierMedicalDrawer from "../../../components/consultation/DossierMedicalDrawer";
import {useNavigate, useParams} from "react-router-dom";
import {EtatOperation, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {useBonExamen, useConsultation, useOrdonnance} from "../../../api";
import {ConsultationType} from "../../../models/Consultation";
import CusBackButton from "../../../components/CusBackButton";
import {BonExamenType} from "../../../models/BonExamen";
import {OrdonnanceType} from "../../../models/Ordonnance";

const { Step } = Steps;

const AddConsultation: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [current, setCurrent] = useState(0);
  const [openDossierMedical, setOpenDossierMedical] = useState(false);
  const [consultation, setConsultation] = useState<ConsultationType | null>(null);
  const [newConsultation, setNewConsultation] = useState<ConsultationType | null>(null);
  const [newBonExamen, setNewBonExamen] = useState<BonExamenType | null>(null);
  const [newOrdonnance, setNewOrdonnance] = useState<OrdonnanceType | null>(null);
  const { consultationId } = useParams();
  const [formStep1] = Form.useForm();
  const [formStep2] = Form.useForm();
  const [formStep3] = Form.useForm();
  const [formData, setFormData] = useState({});
  const {add: addOrdonnance, update: updateOrdonnance, remove: removeOrdonnance} = useOrdonnance();
  const {add: addBonExamen, update: updateBonExamen, remove: removeBonExamen} = useBonExamen();
  const {update: updateConsultation, one: obtainConsultation} = useConsultation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    if(consultationId) {
      obtainConsultation(parseInt(consultationId))
        .then((data:any) => setConsultation(data))
        .catch(error => console.log(error));
      }
  }, [isLoading]);


  const steps = [
    {
      title: 'Consultation',
      form: formStep1,
      description: 'Motif',
      content: <InfoBasicConsultation form={formStep1} />,
      icon: <AuditOutlined />
    },
    {
      title: "Bon d'examen",
      form: formStep2,
      description: 'Examens à compléter',
      content: <BonExamenFormConsultation form={formStep2} />,
      icon: <NodeIndexOutlined />
    },
    {
      title: 'Ordonnance',
      form: formStep3,
      description: 'Traitement',
      content: <PrescriptionForm form={formStep3} />,
      icon: <FileTextOutlined />
    }
  ];


  const showDrawerDossierMedical = () => {
    setOpenDossierMedical(true);
  };

  const onCloseDossierMedical = () => {
    setOpenDossierMedical(false);
  };

  const createOrdonnance = (prescriptions = []) => {
      if(prescriptions.length > 0) {
          const ordonnance: any = {
              prescriptions: prescriptions,
              consultation: newConsultation!,
              service: newConsultation!.service,
              dossierMedical: newConsultation!.dossierMedical,
          };

          const options: any = {
              onSuccess: (values: OrdonnanceType) => {
                  onSuccessResponse(messageApi, values, "L'ordonnance a été enregistrée");
                  setNewOrdonnance(values);
                  setTimeout(() => navigate("/dashboard/list-consultation"), 1500);
              },
              onError: (values: OrdonnanceType) =>onErrorResponse(messageApi, values, "L'ordonnance n'a pas été enregistrée, veuillez réessayer")
          }

          if(!newOrdonnance) {
              addOrdonnance(ordonnance, options);
          } else {
              ordonnance.id = newOrdonnance.id;
              updateOrdonnance(ordonnance, options);
          }

      } else {
          if(newOrdonnance) {
              removeOrdonnance(newOrdonnance.id);
              setNewOrdonnance(null);
          }
          setTimeout(() => navigate("/dashboard/list-consultation"), 1500);
      }
  }
  const createBonExamen = ( examens = []) => {
      if(examens.length > 0) {
          const bonExamen: any = {
              salle: "",
              renseignementClinique: newConsultation!.motif,
              examens: examens,
              service: newConsultation!.service,
              dossierMedical: newConsultation!.dossierMedical,
              consultation: consultation
          };

          const options: any = {
              onSuccess: (values: BonExamenType) => {
                  onSuccessResponse(messageApi, values, "Le bon d'examen a bien été enregistré");
                  setNewBonExamen(values);
                  setTimeout(() => setCurrent(current + 1), 1000);
              },
              onError: (values: BonExamenType) =>onErrorResponse(messageApi, values, "Le bon d'exaen n'a pas été enregistré, veuillez réessayer")
          }

          if(!newBonExamen) {
              addBonExamen(bonExamen, options);
          } else {
              bonExamen.id = newBonExamen.id;
              updateBonExamen(bonExamen, options);
          }

      } else {
          if(newBonExamen) {
              removeBonExamen(newBonExamen.id);
              setNewBonExamen(null);
          }
          setCurrent(current + 1);
      }
  }

  const onCreateConsultation = (values: any) => {
    const newConsultation: any = {
      id: consultationId ? +consultationId : 0,
      motif: values.motif,
      diagnostic: values.diagnostic,
      symptomes: values.symptomes,
      lieu: "Owendo, cité Octra",
      etat: EtatOperation.TERMINEE,
    }

    updateConsultation(
        newConsultation,
        {
          onSuccess: async (values) => {
              onSuccessResponse(messageApi, values, "La consultation a été enregistrée");
              setNewConsultation(values);
              setTimeout(() => setCurrent(current + 1), 1000);
          },
          onError: (values) => onErrorResponse(messageApi, values, "L'ajout de la consultation a échoué")
        }
    );
  }

    const prev = () => {
        setCurrent(current - 1);
    };

  const next = () => {
    const currentForm = steps[current];
    currentForm.form.validateFields()
        .then((values) => {
          const allValues: any = { ...formData, ...values };
          if(currentForm.title === "Consultation") {
            onCreateConsultation(values);
          }
          if(currentForm.title === "Bon d'examen") {
            let examens: any =  [];
            if(allValues?.examens) {
              examens = [...values.examens];
            }
            createBonExamen(examens);
          }
          setFormData(allValues);
        })
        .catch(errInfo => console.log(errInfo));
  };
  const submit = () => {
    const finalForm = steps[steps.length - 1].form;

    finalForm.validateFields()
        .then(values => {
          const allValues = {...formData, ...values };

          let prescriptions: any = [];

          if(allValues?.prescriptions) {
            prescriptions = [...allValues.prescriptions]
          }
          createOrdonnance(prescriptions);
        })
        .catch(errInfo => console.log(errInfo))
  }

  return (
      <>
        {contextHolder}
        <div className="flex flex-col items-center bg-white p-4 rounded border border-solid border-transparent border-100">
          <div className="self-start">
            <CusBackButton />
          </div>
          <div className="flex justify-between items-center w-3/4">
            <h1 className="text-lg font-bold mt-4 mb-8">Nouvelle consultation</h1>
            <Button type="primary" size="middle" className="bg-green-500 border-green-500" onClick={showDrawerDossierMedical}>
              Dossier Médical
            </Button>
          </div>
          <Steps current={current} className="py-4 w-3/4">
            {steps.map(item => (
                <Step key={item.title} title={item.title} icon={item.icon} description={item.description} />
            ))}
          </Steps>
          <div className="steps-content py-4 w-3/4">
              {steps[current].content}
          </div>
          <div className="steps-action w-3/4 flex justify-end space-x-4">
            {current > 0 && (
                <Button type="default" className="text-primary border-primary" onClick={() => prev()}>
                  Précedant
                </Button>
            )}
            {current < steps.length - 1 && (
                <Button type="primary" className="bg-primary border-primary" onClick={() => next()}>
                  Suivant
                </Button>
            )}
            {current === steps.length - 1 && (
                <Button type="primary"  className="bg-green-500 border-green-500" onClick={() => submit()}>
                  Enregistrer
                </Button>
            )}
          </div>
        </div>
        <DossierMedicalDrawer
            dossierMedical={consultation ? consultation.dossierMedical : null}
            open={openDossierMedical}
            onClose={onCloseDossierMedical}
            isLoading={isLoading}
            setIsLoading={(isLoading) => setIsLoading(isLoading)}
        />
      </>
  );
};

export default AddConsultation;