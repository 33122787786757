import {Button, Form, message, Tag} from 'antd'
import {PatientType} from "../../../models/Patient";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {usePatient} from "../../../api";
import {useNavigate} from "react-router-dom";
import {getAge, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {PatientFormModal} from "../../../components/patient/PatientFormModal";
import {hasRole, Role} from "../../../utilities/auth";
import {useAuth} from "../../../provider/AuthProvider";
import dayjs from "dayjs";
import PatientProfileModal from "../../../components/patient/PatientProfileModal";


export const patientColumns: ColumnConfig<PatientType>[] = [
    {
        title: 'Nom',
        dataIndex: 'nom',
        key: 'nom',
        render: record => <span className="text-primary">{record.nom}</span>
    },
    {
        title: 'Prenom',
        dataIndex: 'prenom',
        key: 'prenom'
    },
    {
        title: 'Sexe',
        dataIndex: 'sexe',
        key: 'sexe'
    },
    {
        title: 'Age',
        dataIndex: 'dateDeNaissance',
        key: 'dateDeNaissance',
        render: (record) => <span>{record.dateDeNaissance ? `${getAge(record.dateDeNaissance)} ans`: '' }</span>
    },
    {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'contact'
    },
    {
        title: 'Adresse',
        dataIndex: 'adresse',
        key: 'adresse'
    }
];
function ListPatient() {
    const navigation = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const { user } = useAuth();
    const { data, add, update, remove, isLoading } = usePatient();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [updated, setUpdated] = useState<boolean>(false);
    const [patient, setPatient] = useState<PatientType>();
    const [showPatientModal, setShowPatientModal] = useState(false);

    const onCreate = async (values: any, updated: boolean) => {
        if (updated) {
            update(
                values,
                {
                    onSuccess: (values) => onSuccessResponse(messageApi, values, "Le patient a été modifié"),
                    onError: (values) => onErrorResponse(messageApi, values, "La modification du patient a échouée")
                }
            );
        } else {
            add(
                values,
                {
                    onSuccess: (values) => onSuccessResponse(messageApi, values, "Le patient a été ajouté"),
                    onError: (values) => onErrorResponse(messageApi, values, "L'ajout du patient a échoué")
                }
            );
        }
        setOpen(false);
        form.resetFields();
    };

    const handleOk = () => {
        setShowPatientModal(false);
    };

    const handleCancel = () => {
        setShowPatientModal(false);
    };

    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le patient a été supprimé"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression du patient a échouée")
            }
        );
    }

    const onUpdate = (record: PatientType) => {
        form.setFieldsValue(record);
        form.setFieldValue("dateDeNaissance", dayjs(record.dateDeNaissance).format("DD-MM-YYYY"));
        setUpdated(true);
        setOpen(true);
    }

    const onCancel = () => {
        form.resetFields();
        setOpen(false);
    }

    const onShow = (record: PatientType) => {
        if (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Medecin)) {
            navigation(`/dashboard/${record.id}/dossier-medical`);
        } else {
            setPatient(record);
            setShowPatientModal(true);
        }
    }
    
  return (
    <>
        {contextHolder}
      <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
          <DynamicTable<PatientType>
              queryKey="patientQuery"
              header={
                  <div className="flex justify-between">
                      <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Patients</Tag>
                      {
                          (hasRole(user.roles.name, Role.Admin) || hasRole(user.roles.name, Role.Secretaire))
                          ? (
                              <Button type="primary" size="middle" className="bg-black border-black" onClick={() => {
                                  setUpdated(false);
                                  setOpen(!open);
                              }}>
                                  Ajouter
                              </Button>
                          ) : null
                      }
                  </div>
              }
              columns={patientColumns}
              data={data}
              isLoading={isLoading}
              action={{
                  onShowRow:  onShow,
                  onUpdateRow:  (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Secretaire)) ? onUpdate : undefined,
                  onDeleteRow: hasRole(user.roles?.name, Role.Admin) ? onDelete : undefined
              }}
          />
          <PatientFormModal
              open={open}
              onCreate={onCreate}
              onCancel={onCancel}
              updated={updated}
              form={form}
          />
          <PatientProfileModal
              patient={patient}
              isModalOpen={showPatientModal}
              handleOk={handleOk} handleCancel={handleCancel}
          />
      </div>
    </>
  )
}

export default ListPatient