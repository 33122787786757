import React from 'react';
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";

function CusBackButton() {
    const navigation = useNavigate();

    return (
        <Button icon={<ArrowLeftOutlined />}
                className="flex items-center"
                onClick={() =>  navigation(-1)}
        >
            Retour
        </Button>
    );
}

export default CusBackButton;