import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3a0202'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        color: 'white',
        marginVertical: 0,
        paddingVertical: 0
    },
    desc: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        marginVertical: 0,
        paddingVertical: 0
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        marginVertical: 0,
        paddingVertical: 0
    },
    price: {
        width: '30%',
        marginVertical: 0,
        paddingVertical: 0
    },

});

// @ts-ignore
const FactureTableBlankSpace = ({rowsCount}) => {
    if(rowsCount <= 0) return  null;

    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map( (x, i) =>
        <View style={styles.row} key={`BR${i}`}>
            <Text style={styles.desc}>-</Text>
            <Text style={styles.qty}>-</Text>
            <Text style={styles.price}>-</Text>
        </View>
    )
    return (<>{rows}</> )
};

export default FactureTableBlankSpace