import React from 'react';
import {Descriptions} from "antd";
import {DossierMedicalType} from "../../models/DossierMedical";
import {PatientType} from "../../models/Patient";
import {getAge} from "../../utilities/Utils";

interface InformationGeneraleSectionProps {
    groupeSanguin: string

    electrophorese: string;
    patient?: PatientType
}
function InformationGeneraleSection({
    patient, groupeSanguin, electrophorese
                                    }:InformationGeneraleSectionProps) {
    return (
        <>
            <Descriptions title="Informations générales" className="my-8">
                <Descriptions.Item label="Nom(s) et prénom(s)" className="font-bold">{patient?.nom + " " + patient?.prenom}</Descriptions.Item>
                <Descriptions.Item label="Sexe" className="font-bold">{patient?.sexe}</Descriptions.Item>
                <Descriptions.Item label="Age" className="font-bold">{patient?.dateDeNaissance ? getAge(patient?.dateDeNaissance) : ""}</Descriptions.Item>
                <Descriptions.Item label="Téléphone" className="font-bold">{patient?.contact}</Descriptions.Item>
                <Descriptions.Item label="Groupe sanguin" className="font-bold">{groupeSanguin}</Descriptions.Item>
                <Descriptions.Item label="Electrophorèse" className="font-bold">{electrophorese}</Descriptions.Item>
                <Descriptions.Item label="Fonction" className="font-bold">{patient?.fonction}</Descriptions.Item>
                <Descriptions.Item label="Adresse" className="font-bold">{patient?.adresse}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Personne à contacter" className="my-8">
                <Descriptions.Item label="Nom(s) et prénom(s)">{patient?.personneAContacter}</Descriptions.Item>
                <Descriptions.Item label="Lien de parenté">{patient?.lienParente}</Descriptions.Item>
                <Descriptions.Item label="Fonction">{patient?.fonctionPersonne}</Descriptions.Item>
                <Descriptions.Item label="Téléphone">{patient?.contactPersonne}</Descriptions.Item>
                <Descriptions.Item label="Adresse">{patient?.adressePersonne}</Descriptions.Item>
            </Descriptions>
    </>
    );
}

export default InformationGeneraleSection;