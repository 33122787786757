import {View, StyleSheet} from "@react-pdf/renderer";
import ExamenTableFooter from "./ExamenTableFooter";
import ExamenTableBlankSpace from "./ExamenTableBlaknSpace";
import ExamenTableHeader from "./ExamenTableHeader";
import ExamenTableRow from "./ExamenTableRow";
import {ExamenType} from "../../models/Examen";

const tableRowsCount = 10;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
        borderTopWidth: 1,
        borderTopColor: '#000',
    },
});

interface ExamenItemsTableInterface {
    examens?: ExamenType[]
}
const ExamenItemsTable = ({examens}: ExamenItemsTableInterface) => (
    <View style={styles.tableContainer}>
        <ExamenTableHeader />
        <ExamenTableRow examens={examens} />
        <ExamenTableBlankSpace rowsCount={ tableRowsCount - (examens?.length ?? 0)} />
        <ExamenTableFooter />
    </View>
);

export default ExamenItemsTable