import React, {useEffect, useState} from 'react';
import {AutoComplete, Select} from "antd";
import {usePatient} from "../../api";
import {getFullName} from "../../utilities/Utils";

interface PatientAutoCompleteProps {
    value?: string;
    onSelect: (name: string) => void;

    onClear?: () => void;
}

interface PatientValue {
    label: string;
    value: string | number;
}
function PatientAutoComplete({
    onSelect, onClear, value
                             } : PatientAutoCompleteProps) {
    const [options, setOptions] = useState<PatientValue[]>([]);
    const {data} = usePatient();

    useEffect(() => {
        const ops: PatientValue[] = [];
        data.forEach((patient) => {
            const fullName = getFullName(patient);
            ops.push({value: fullName, label: fullName})
        });
        setOptions(ops);
    }, [data]);

    return (
        <Select
            className="w-full"
            value={value}
            showSearch
            placeholder="Selectionner un patient"
            onChange={onSelect}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={options}
            allowClear
            onClear={onClear}
        />
    );
}

export default PatientAutoComplete;