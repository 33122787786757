import React, {Suspense, useEffect, useState} from 'react'
import {Spin, Tag} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBedPulse, faClockRotateLeft, faStethoscope, faSyringe, faTruckMedical, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import {
    useActe,
    useConsultation,
    useHospitalisation,
    usePatient,
    usePersonnelMedical,
    useRdv,
    useRole
} from "../../api";
import {DashboardStatCardItemType} from "../../components/dashboard/DashboardStatCard";
import {RdvType} from "../../models/Rdv";
import DynamicTable from "../../components/DynamicTable";
import {RdvColumns} from "./rdv/Table";
import {EtatOperation} from "../../utilities/Utils";
import dayjs from "dayjs";
import {hasRole, Role} from "../../utilities/auth";
const DashboardStatCard = React.lazy(() => import('../../components/dashboard/DashboardStatCard'));
const DoctorList = React.lazy(() => import('../../components/personnel/DoctorList'));
const SoinPieChart = React.lazy(() => import('../../components/soin/SoinPieChart.'));
const PatientLineChart  = React.lazy(() => import('../../components/patient/PatientLineChart'));

function DashboardAdmin() {
    const {customQuery: customPersonnelQuery } = usePersonnelMedical();
    const [totalMedecin, setTotalMedecin] = useState(0);
    const {count: totalPatients} = usePatient();
    const {count: totalConsultations} = useConsultation();
    const {count: totalHospitalisations} = useHospitalisation();
    const {count: totalActes} = useActe();
    const {count: totalRdv} = useRdv();
    const {data: roles} = useRole();
    const [items, setItems] = useState<Array<DashboardStatCardItemType>>([]);
    const [rdv, setRdv] = useState<Array<RdvType>>([]);
    const {filteredData: filteredRdv, isLoading} = useRdv();
    const {data: rdvFiltered} = filteredRdv({"etat": EtatOperation.EN_ATTENTE});

    useEffect(() => {
        const roleMedecin =  roles.filter((role) => hasRole(role.name, Role.Medecin))[0];
        if(roleMedecin) {
            customPersonnelQuery(`/count/${roleMedecin?.id}`)
                .then((data: any) => setTotalMedecin(data))
                .catch((error) => console.log(error))
        }
    }, [customPersonnelQuery, roles]);


    useEffect(() => {
        let rdv: RdvType[] | undefined;
        rdv = rdvFiltered?.filter((rdv: RdvType) => dayjs(rdv.date).isAfter(dayjs().toDate()));
        setRdv(rdv ?? []);
    }, [rdvFiltered]);


    useEffect(() => {
        setItems([
            {
                key: 0,
                title: "Docteurs",
                icon:  <FontAwesomeIcon className="w-7 h-7" icon={faUserDoctor} />,
                value: totalMedecin
            },
            {
                key: 1,
                title: "Patients",
                icon:  <FontAwesomeIcon className="w-7 h-7" icon={faTruckMedical} />,
                value: totalPatients
            },
            {
                key: 2,
                title: "Consulations",
                icon:  <FontAwesomeIcon className="w-7 h-7" icon={faStethoscope} />,
                value: totalConsultations
            },
            {
                key: 3,
                title: "Rendez-vous",
                icon:  <FontAwesomeIcon className="w-7 h-7" icon={faClockRotateLeft} />,
                value: totalRdv
            },
            {
                key: 4,
                title: "Hospitalisations",
                icon:  <FontAwesomeIcon className="w-7 h-7" icon={faBedPulse} />,
                value: totalHospitalisations
            },
            {
                key: 5,
                title: "Actes",
                icon:  <FontAwesomeIcon className="w-7 h-7" icon={faSyringe} />,
                value: totalActes
            },
        ]);
    }, [totalMedecin, totalPatients, totalConsultations, totalHospitalisations, totalRdv, totalActes]);

  return (
    <Suspense fallback={<Spin className="flex justify-center items-center" size="large" />} >
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-6 lg:gap-10">
          <DashboardStatCard items={items} />
        </div>

        <div className="my-10 grid grid-cols-6 gap-10">
          <div className="col-span-3 md:col-span-2 bg-white p-4 rounded border border-transparent border-solid border-100">
            <h1 className="text-base font-bold mb-4">Médecins</h1>
            <DoctorList />
          </div>
          <div className="col-span-3 md:col-span-4 bg-white p-4 rounded border border-transparent border-solid border-100">
              <DynamicTable<RdvType>
                  queryKey="rdvQuery"
                  header={
                      <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Rendez-vous</Tag>
                  }
                  columns={RdvColumns}
                  data={rdv}
                  isLoading={isLoading}
              />
          </div>
        </div>

        <div className="mt-10 grid grid-cols-6 gap-10">
          <div className="col-span-3 lg:col-span-4 bg-white p-4 rounded border border-transparent border-solid border-100">
            <h1 className="text-base font-bold mb-4">Total des Patients</h1>
            <PatientLineChart />
          </div>
          <div className="col-span-3 lg:col-span-2 bg-white p-4 rounded border border-transparent border-solid border-100">
            <h1 className="text-base font-bold mb-4">Rapport Par Service</h1>
            <SoinPieChart />
          </div>
        </div>
    </Suspense>
  )
}

export default DashboardAdmin;