import axios from "axios";
import {getToken} from "./utilities/auth";

export const Config = {
    local_api_url: "http://127.0.0.1:8000/api",
    online_api_url: "https://api-sante.l2h-services.com/api",
    app_name: "Samu Social Gabonais",
    slug: "Ensemble, luttons contre l'exclusion sociale",
    lieu_par_default: "Libreville, Gabon",
    contact: "1488",
    email: "wenceslas.yaba@gmail.com",
    logo: '',
    primaryColorLight: '#f19a24',
    primaryColor: '#f08f02',
    primaryColorDark: '#ee7b09',
    secondaryColor: '#000'
}

export const api = axios.create({
    baseURL: `${Config.local_api_url}`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: true,
});

api.interceptors.request.use(
    (config) => {
        const jwtToken = getToken();
        if (jwtToken) {
            config.headers.Authorization = `Bearer ${jwtToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);