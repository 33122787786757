import {
    Form, Descriptions, Row, Col, message, Button, Select, FormInstance, Input,
} from 'antd';
import { useAuth } from '../../provider/AuthProvider';
import {PatientType} from "../../models/Patient";
import PatientAutoComplete from "../patient/PatientAutoComplete";
import {usePatient} from "../../api";
import React, {useEffect, useState} from "react";
import {PatientFormModal} from "../patient/PatientFormModal";
import {getFullName, onErrorResponse, onSuccessResponse} from "../../utilities/Utils";
import {ConsultationType} from "../../models/Consultation";

interface InfoPatientProp {
    patient?: PatientType
}
const InfoPatient = ({patient}:InfoPatientProp) => (
    <Descriptions title="Info patient" className="mt-8 p-4 bg-gray-50" size="middle">
        <Descriptions.Item label="Nom(s) et prénom(s)" span={2}><span className="font-bold">{patient?.nom + " " + patient?.prenom}</span></Descriptions.Item>
        <Descriptions.Item label="Fonction"><span className="font-bold">{patient?.fonction}</span></Descriptions.Item>
        <Descriptions.Item label="Sexe"><span className="font-bold">{patient?.sexe}</span></Descriptions.Item>
        <Descriptions.Item label="Contact"><span className="font-bold">{patient?.contact}</span></Descriptions.Item>
        <Descriptions.Item label="Adresse"><span className="font-bold">{patient?.adresse}</span></Descriptions.Item>
    </Descriptions>
);

interface InfoFormPatientProp {
    form: FormInstance<ConsultationType>
}
const InfoFormPatient = (
    { form } : InfoFormPatientProp
) => {
    const [patient, setPatient] = useState<PatientType | null>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const { data, add, customQuery } = usePatient();
    const [open, setOpen] = useState(false);
    const [addPatientForm] = Form.useForm();

    useEffect(() => {
        const patient = form.getFieldValue("patient");
        if(patient) {
            setPatientValue(patient);
        }
    }, []);


    const setPatientValue = (nom: string) => {
        const patient = data.filter(patient => getFullName(patient).toLowerCase() === nom.toLowerCase())[0];
        setPatient(patient);
        form.setFieldValue("patient", patient ? getFullName(patient) : "");
        customQuery(`/${patient?.id}/dossier_medical`)
            .then((data: any) => {
                form.setFieldValue("dossier_medical_id", data.id);
            })
            .catch(error => console.log(error));
    }
    const onSelect = (nom: string) => {
        if(nom) {
            setPatientValue(nom);
        }
    }

    const onCreate = async (values: any) => {
        add(
            values,
            {
                onSuccess: (values) => {
                    onSuccessResponse(messageApi, values, "Le patient a été ajouté");
                    setPatient(values);
                    form.setFieldValue("patient", getFullName(values));
                    customQuery(`/${values?.id}/dossier_medical`)
                        .then((data: any) => {
                            form.setFieldValue("dossier_medical_id", data.id);
                        })
                        .catch(error => console.log(error));
                },
                onError: (values) => onErrorResponse(messageApi, values, "L'ajout du patient a échoué")
            }
        );
        setOpen(false);
        addPatientForm.resetFields();
    };

    const onCancel = () => {
        addPatientForm.resetFields();
        setOpen(false);
    }

    const onClear = () => {
        setPatient(null);
        form.setFieldValue("patient", null);
    }

    return ( 
    <>
        {contextHolder}
        <Form form={form} className="flex justify-center">
            <Row gutter={10} className="w-full md:w-3/5" align={"middle"}>
                <Col className="gutter-row" xs={12} sm={12} md={12} lg={16}>
                    <Form.Item name="dossier_medical_id" hidden><Input /></Form.Item>
                    <Form.Item label="Patient"
                               name="patient"
                               rules={[{ required: true, message: "Patient requis!" }]}
                    >
                        <PatientAutoComplete onSelect={onSelect} onClear={onClear}/>
                    </Form.Item>
                </Col>
                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8}>
                    <div className="flex items-center">
                        <span className="mr-2">S'il n'existe pas</span>
                        <Button type="primary" size="middle" className="bg-black border-black" onClick={() => setOpen(!open)}>
                            Ajouter
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
        <div className="h-52">
            {patient ? <InfoPatient patient={patient} /> : null}
        </div>
        <PatientFormModal
            open={open}
            onCreate={onCreate}
            onCancel={onCancel}
            updated={false}
            form={addPatientForm}
        />
  </>
  )
}
  
export default InfoFormPatient;