import React from 'react'
import ActeTimeline from '../../../components/acte/ActeTimeline';

function ChronogieActe() {
  return (
      <>
        <h1 className="text-base font-bold mb-4 mr-4">Actes</h1>
        <ActeTimeline />
      </>
  );
}

export default ChronogieActe