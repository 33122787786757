import React from 'react';
import {Badge} from "antd";

export interface DashboardStatCardItemType {
    key: number,
    title: string,
    description?: string | null,
    icon: React.ReactNode,
    value: number
}
interface Props {
    items: Array<DashboardStatCardItemType>
}

const colors = [
    "green",
    "gold",
    "blue",
    "purple",
    "red",
    "magenta",
    "lime",
    "pink",
    "volcano",
    "red",
]

function dashboardStatCard({ items }: Props) {
  const cards =  items.map((item, index) =>
        <div key={`${item.key}`}
             className={`col-span-1 w-full h-32 flex flex-col justify-center items-center transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 shadow-sm rounded border border-solid border-gray-100 text-black bg-white `}
        >
            <span className="mb-1">{item.icon}</span>
            <h1 className="text-base font-medium">{item.title}</h1>
            {
                item?.description 
                ?   (<h2 className="text-sm font-light">{item?.description}</h2>) 
                :   null
            }
            <span>
               <Badge
                   count={item.value ?? 0}
                   showZero color={colors[index] ?? 'red'}
               />
            </span>
        </div>
    ); 
    
  return <>{cards}</>
}

export default dashboardStatCard