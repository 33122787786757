import React, {useEffect, useState} from 'react';
import {DossierMedicalType} from "../../models/DossierMedical";
import DossierMedicalComponent from "../patient/DossierMedicalComponent";
import {useDossierMedical, usePatient} from "../../api";
import {Button, Drawer, Form, message, notification, Space} from "antd";
import UpdateDossierMedicalDrawerForm from "./UpdateDossierMedicalDrawerForm";
import dayjs from 'dayjs';

import {
    antecedentsToTags,
    getAntecedentsByType,
    tagsToAntecedents
} from "../../utilities/Utils";
import {TypeAntecedentType} from "../../models/Antecedent";
import {useQueryClient} from "@tanstack/react-query";

interface DossierMedicalDrawerProp {
    open: boolean,
    onClose: () => void;
    dossierMedical?: DossierMedicalType | null;
    isLoading: boolean;
    setIsLoading: (state: boolean) => void
}
function DossierMedicalDrawer({ dossierMedical, onClose, open, isLoading, setIsLoading }: DossierMedicalDrawerProp) {
    const queryClient = useQueryClient();
    const [messageApi, contextHolder] = message.useMessage();
    const [notificationApi, notificationContextHolder] = notification.useNotification();
    const {update: updateDossier} = useDossierMedical();
    const {update: updatePatient} = usePatient();
    const [updateDossierMedical, setUpdateDossierMedical] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setUpdateDossierMedical(false);
    }, []);

    const notificationErrorResponse = (message?: string) => {
        notificationApi.error({
            message: "Erreur",
            description: message ?? "La modification du dossier médical a échouée",
            placement: "topLeft",
        });
    };

    const notificationSuccessResponse = () => {
        notificationApi.success({
            message: "Succès",
            description: "Le dossier médical a été modifié",
            placement: "topLeft",
        });
    };

    const onCancelUpdate = () => {
        setUpdateDossierMedical(false);
        form.resetFields();
    }

    const onUpdate = (values: any) => {
        setIsLoading(true);
        const antecedents = [
            ...(tagsToAntecedents(values?.antecedentsPersonnels, TypeAntecedentType.PERSONNEL)),
            ...(tagsToAntecedents(values?.antecedentsFamiliaux, TypeAntecedentType.FAMILIAL)),
            ...(tagsToAntecedents(values?.antecedentsChurirgicaux, TypeAntecedentType.CHIRURGICAL)),
            ...(tagsToAntecedents(values?.allergies, TypeAntecedentType.ALLERGIE)),
        ];

        const dossierValues : any = {
            id: parseInt(values.dossierId),
            antecedents: antecedents,
            electrophorese: values.electrophorese,
            groupeSanguin: values.groupeSanguin
        };

        delete values.dossierId;
        delete values.antecedentsPersonnels;
        delete values.antecedentsFamiliaux;
        delete values.antecedentsChurirgicaux;
        delete values.allergies;
        delete values.antecedents;
        delete values.electrophorese;
        delete values.groupeSanguin;

        console.log(dossierValues);

        updateDossier(
             dossierValues
            ,
            {
                onSuccess: () => {
                    values.id = values.patientId;
                    delete values.patientId;
                    updatePatient(
                        values,
                        {
                            onSuccess: async () => {
                                await queryClient.invalidateQueries(["dossierMedicalQuery"]);
                                setIsLoading(false);
                                notificationSuccessResponse();
                                onCancelUpdate();
                            },
                            onError: () => {
                                setIsLoading(false);
                                notificationErrorResponse("Veuillez réessayer")
                            }
                        }
                    );
                },
                onError: () => {
                    setIsLoading(false);
                    notificationErrorResponse()
                }
            }
        );
    }

    const onSaveUpdate = () => {
        form
            .validateFields()
            .then((values) => {
                onUpdate(values);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const handleUpdate = () => {
        setUpdateDossierMedical(true);
        form.setFieldsValue({
            ...dossierMedical,
            ...dossierMedical?.patient
        });
        form.setFieldValue("patientId", dossierMedical?.patient.id);
        form.setFieldValue("dossierId", dossierMedical?.id);
        if(dossierMedical?.patient?.dateDeNaissance) {
            form.setFieldValue("dateDeNaissance", dayjs(dayjs(dossierMedical?.patient?.dateDeNaissance).format('DD-MM-YYYY'), 'DD-MM-YYYY'));
        }
        const antecedentsPeronnels =  antecedentsToTags(getAntecedentsByType(dossierMedical?.antecedents, TypeAntecedentType.PERSONNEL));
        if(antecedentsPeronnels.length > 0) {
            form.setFieldValue("antecedentsPersonnels", antecedentsPeronnels);
        }
        const antecedentsFamiliaux = antecedentsToTags(getAntecedentsByType(dossierMedical?.antecedents, TypeAntecedentType.FAMILIAL));
        if (antecedentsFamiliaux.length > 0) {
            form.setFieldValue("antecedentsFamiliaux", antecedentsFamiliaux);
        }
        const antecedentsChurirgicaux = antecedentsToTags(getAntecedentsByType(dossierMedical?.antecedents, TypeAntecedentType.CHIRURGICAL));
        if(antecedentsChurirgicaux.length > 0) {
            form.setFieldValue("antecedentsChurirgicaux", antecedentsChurirgicaux);
        }
        const allergies = antecedentsToTags(getAntecedentsByType(dossierMedical?.antecedents, TypeAntecedentType.ALLERGIE));
        if(allergies.length > 0) {
            form.setFieldValue("allergies", allergies);
        }
    }

    return (
        <>
            {contextHolder}
            {notificationContextHolder}
            <Drawer
                title="Dossier Médical"
                placement="right" onClose={() => {
                    onClose();
                    onCancelUpdate();
            }}
                open={open} width={900}
                extra={
                    updateDossierMedical ?
                    (
                        <Space>
                            <Button onClick={onCancelUpdate}>Annuler</Button>
                            <Button onClick={onSaveUpdate} type="primary" className="bg-green-500 border-green-500">
                                Enregister
                            </Button>
                        </Space>
                    )
                    : (
                        <Button onClick={handleUpdate}  type="primary">Modifier</Button>
                    )
                }
                maskClosable={!updateDossierMedical}
            >
                {
                    !updateDossierMedical
                    ? (
                        <DossierMedicalComponent dossierMedical={dossierMedical}  />
                    ) : (
                        <UpdateDossierMedicalDrawerForm form={form} isLoading={isLoading}/>
                    )
                }
            </Drawer>
        </>
    );
}

export default DossierMedicalDrawer;