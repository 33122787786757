import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        fontStyle: 'bold',
        color: '#fff'
    },
    examen: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    result: {
        width: '50%',
    },

});

// @ts-ignore
const ExamenTableBlankSpace = ({rowsCount}) => {
    if(rowsCount <= 0) return  null;

    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map( (x, i) =>
        <View style={styles.row} key={`BR${i}`}>
            <Text style={styles.examen}>-</Text>
            <Text style={styles.result}>-</Text>
        </View>
    )
    return (<>{rows}</> )
};

export default ExamenTableBlankSpace