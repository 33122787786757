import React from 'react';
import {PersonnelMedicalType} from "../../models/PersonnelMedical";
import {Avatar, Descriptions, Divider, Modal, Tag, Typography} from "antd";
import {UserOutlined} from "@ant-design/icons";

interface PersonnelProfileModalProps {
    personnel?: PersonnelMedicalType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}

const { Title, Text } = Typography;
function PersonnelProfileModal({
    personnel, isModalOpen, handleOk, handleCancel
} : PersonnelProfileModalProps) {
    return (
        <Modal
            open={isModalOpen}
            footer={null}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            centered={true}
        >
            <div className="mt-8 flex flex-col items-center">
                <Avatar size={128} icon={<UserOutlined />} className="mb-4" />
                <Descriptions title="Personnel" layout="vertical">
                    <Descriptions.Item label="Nom(s) et prénom(s):"><span className="font-bold">{personnel?.nom + " " + personnel?.prenom}</span></Descriptions.Item>
                    <Descriptions.Item label="Specialité"><span className="font-bold">{personnel?.services?.nom}</span></Descriptions.Item>
                    <Descriptions.Item label="Sexe"><span className="font-bold">{personnel?.sexe}</span></Descriptions.Item>
                    <Descriptions.Item label="Contact"><span className="font-bold">{personnel?.contact}</span></Descriptions.Item>
                    <Descriptions.Item label="Email"><span className="font-bold">{personnel?.email}</span></Descriptions.Item>
                    <Descriptions.Item label="Adresse"><span className="font-bold">{personnel?.adresse}</span></Descriptions.Item>
                </Descriptions>
            </div>
        </Modal>
    );
}

export default PersonnelProfileModal;