import React, {useEffect, useState} from 'react';
import {ActeType} from "../../../models/Acte";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import {useActe} from "../../../api";
import { Tag } from 'antd';

const columns: ColumnConfig<ActeType>[] = [
  {
    title: 'Acte',
    dataIndex: 'observation',
    key: 'observation',
  },
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
  }
];
function TableActe() {
  const { data, isLoading } = useActe();
  const [actes, setActes] = useState<ActeType[]>([]);

  useEffect(() => {
    setActes(data);
  }, [data, isLoading]);


  return (
      <DynamicTable<ActeType>
          queryKey="acteQuery"
          header={
            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4 ">Actes</Tag>
          }
          columns={columns}
          data={actes}
          isLoading={isLoading}
      />
  )
}

export default TableActe