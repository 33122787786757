import React, {Suspense} from 'react';
import {Col, DatePicker, Form, FormInstance, Input, Radio, Row, Select, SelectProps, Spin} from 'antd';
import {emptyFieldError} from "../../utilities/Utils";

const groupeSanguinOption = [
    {label: "O +", value: "O +"},
    {label: "A +", value: "A +"},
    {label: "B +", value: "B +"},
    {label: "O -", value: "O -"},
    {label: "A -", value: "A -"},
    {label: "AB +", value: "AB +"},
    {label: "B -", value: "B -"},
    {label: "AB -", value: "AB -"},
];

interface UpdateDossierMedicalDrawerFormProps {
    form: FormInstance;
    isLoading: boolean;
}

const antecedentOptions : SelectProps['options'] = [];

function UpdateDossierMedicalDrawerForm(
    {form, isLoading}: UpdateDossierMedicalDrawerFormProps
) {

    if(isLoading) {
        return <Spin className="w-full h-full flex justify-center items-center" size="large" />;
    }

    return (
        <>
            <Form layout="vertical" form={form}>
                <h3 className="font-bold mb-4">Patient</h3>
                <Form.Item name="patientId" hidden></Form.Item>
                <Form.Item name="dossierId" hidden></Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="nom"
                            label="Nom"
                            rules={[{ required: true, message: emptyFieldError }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="prenom"
                            label="Prénom"
                            rules={[{ required: true, message: emptyFieldError }]}
                        >
                            <Input
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="dateDeNaissance"
                            label="Date de naissance"
                            rules={[{ required: true, message: emptyFieldError }]}
                        >
                            <DatePicker allowClear={false} className="w-full"/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="sexe"
                            label="Sexe"
                            className="collection-create-form_last-form-item"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Radio.Group className="flex justify-evenly">
                                <Radio value="M">M</Radio>
                                <Radio value="F">F</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="groupeSanguin"
                            label="Groupe Sanguin"
                        >
                            <Select options={groupeSanguinOption} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="electrophorese"
                            label="Electrophorèse"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="fonction"
                            label="Fonction"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="contact"
                            label="Contact"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="adresse"
                            label="Adresse"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <h3 className="font-bold mb-4">Personne à contacter en cas d'urgence</h3>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="personneAContacter"
                            label="Nom(s) et prénom(s)"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lienParente"
                            label="Lien de parenté"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="fonctionPersonne"
                            label="Fonction"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="contactPersonne"
                            label="Contact"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="adressePersonne"
                            label="Adresse"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <h3 className="font-bold mb-4">Antécédents</h3>
                <Form.Item
                    name="antecedentsPersonnels"
                    label="Antécédents Personnels"
                >
                    <Select
                        mode="tags"
                        className="w-full"
                        tokenSeparators={[',', ';']}
                        options={antecedentOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="antecedentsFamiliaux"
                    label="Antécédents Familiaux"
                >
                    <Select
                        mode="tags"
                        className="w-full"
                        tokenSeparators={[',']}
                        options={antecedentOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="antecedentsChurirgicaux"
                    label="Antécédents Churirgicaux"
                >
                    <Select
                        mode="tags"
                        className="w-full"
                        tokenSeparators={[',']}
                        options={antecedentOptions}
                    />
                </Form.Item>
                <Form.Item
                    name="allergies"
                    label="Allergies"
                >
                    <Select
                        mode="tags"
                        className="w-full"
                        tokenSeparators={[',']}
                        options={antecedentOptions}
                    />
                </Form.Item>
            </Form>
        </>
    );
}

export default UpdateDossierMedicalDrawerForm;