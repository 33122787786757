import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faBabyCarriage, faBedPulse, faBrain, faCrutch,  faHeartPulse, faMicroscope, faPersonBreastfeeding, faPersonHalfDress, faStethoscope, faTeethOpen, faVenusMars, faVirus, faXRay } from '@fortawesome/free-solid-svg-icons'
import DashboardServiceItem from '../../components/dashboard/DashboardServiceItem';
import {useService} from "../../api";
import {useEffect, useState} from "react";
interface ServiceListType {
  key: number,
  title: string,
  desc: string,
  icon: JSX.Element
}

function ServiceList() {
  const [services, setServices] = useState<ServiceListType[]>([]);
  const {data} = useService();

  useEffect(() => {
      const newServices: ServiceListType[] = data.map(service => ({
        key: service.id,
        title: service.nom,
        desc: `${service.prixConsultation} FCFA`,
        icon: <FontAwesomeIcon className="text-secondary-light group-hover:text-primary w-5 h-5" icon={faStethoscope} />
      })).slice(0, 14);
      setServices([...newServices]);
  }, [data]);


  return (
    <>
      {
        services.map(service => (
          <DashboardServiceItem key={service.key} title={service.title} desc={service.desc} icon={service.icon}/>
        ))
      }
    </>
  )
}

export default ServiceList