import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import React, {useCallback, useEffect, useState} from "react";
import {FactureType} from "../../models/Facture";
import {ExamenType} from "../../models/Examen";
import {getFullName, getValidDate} from "../../utilities/Utils";
import HeaderPDF from "../HeaderPDF";
import FactureItemsTable from "./FactureItemsTable";
import {FactureItemsType} from "./FactureTableRow";
import {useFacture} from "../../api";
import {ConsultationType} from "../../models/Consultation";
import {BonExamenType} from "../../models/BonExamen";

const borderColor = '#3a0202'
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
        alignSelf: 'center',
        backgroundColor: '#fff',
    },
    view: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    container: {
        marginTop: 10,
        borderWidth: 1,
        borderColor: borderColor,
        borderRadius: 8,
        padding: 8
    },
    rows: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    label: {
        fontWeight: 'bold',
        color: '#3a0202',
    },
    value: {
        flex: 1,
        color: '#000',
        borderBottom: '1pt dashed #3a0202',
        textAlign: 'center',
    },
});


interface FacturePdfInterface {
    facture?: FactureType,
    items: FactureItemsType[]
}
const FacturePdf = ({facture, items}:FacturePdfInterface) => {

    return (
        <Document title={`facture_${facture?.id}`}>
            <Page size={[600, 500]} fixed orientation={"landscape"} style={styles.page}>
                <View style={styles.view} >
                    <HeaderPDF title={"Facture"} />
                    <View style={styles.container}>
                        <View style={styles.rows}>
                            <View style={styles.row}>
                                <Text style={styles.label}>Noms: </Text>
                                <Text style={styles.value}>{getFullName(facture?.patient ?? undefined)}</Text>
                            </View>
                        </View>
                        <View style={styles.rows}>
                            <View style={styles.row}>
                                <Text style={styles.label}>nº TVA intracom: </Text>
                                <Text style={styles.value}>{" "}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>nº TVA intracom: </Text>
                                <Text style={styles.value}>{" "}</Text>
                            </View>
                        </View>
                        <View style={styles.rows}>
                            <View style={styles.row}>
                                <Text style={styles.label}>Le:</Text>
                                <Text style={styles.value}>{facture?.dateCreation ? getValidDate(facture?.dateCreation): ""}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={styles.label}>nº de commande: </Text>
                                <Text style={styles.value}>{' '}</Text>
                            </View>
                        </View>
                    </View>
                    <FactureItemsTable items={items}/>
                </View>
            </Page>
        </Document>
    );
};

interface FacturePdfViewerProps {
    facture?: FactureType
}
const FacturePdfViewer = (
    {facture} : FacturePdfViewerProps
) => {

    const [items, setItems] = useState<FactureItemsType[]>([]);
    const {customQuery} = useFacture();

    const getItems = useCallback( async () => {
        try {
            const newItems = new Set<FactureItemsType>();
            const consultation: ConsultationType = await customQuery(`/${facture?.id}/consultation`);
            if(consultation) {
                newItems.add({
                    price: consultation?.prix,
                    qty: 1,
                    desc: `Consulation en ${consultation?.service?.nom}`
                })
            }
            const bonExamen: BonExamenType = await customQuery(`/${facture?.id}/bon_examen`);
            if(bonExamen) {
                bonExamen.examens?.forEach((examen: ExamenType) => newItems.add({
                    price: 2000,
                    qty: 1,
                    desc: `Examen ${examen?.nom}`
                }));
            }
            setItems(Array.from(newItems))
        } catch(error) { console.log(error) }
    }, [facture])

    useEffect(() => {
        getItems();
    }, [getItems]);

    return (
        <div className="container flex justify-center">
            <PDFViewer
                style={{
                    border: "none",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                }}
                width="100%"
                height={700}
            >
                <FacturePdf facture={facture} items={items} />
            </PDFViewer>
        </div>
    );
};

export default FacturePdfViewer;
