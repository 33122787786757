import React from "react";
import { useRole} from "../../../api";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import {RoleType} from "../../../models/Role";
import {Tag} from "antd";


const columns: ColumnConfig<RoleType>[] = [
    {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        render: record => <span className="text-primary">{record.name}</span>
    },
];

function ListRole() {
    const { data, isLoading } = useRole();

    return (
        <>
            <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
                <DynamicTable<RoleType>
                    queryKey="roleQuery"
                    header={
                        <div className="flex justify-between">
                            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Rôles</Tag>
                        </div>
                    }
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    action={{
                        onDeleteRow: (id) => {}
                    }}
                />
            </div>
        </>
    )
}

export default ListRole