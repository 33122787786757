import React from 'react';
import {BonExamenType} from "../../models/BonExamen";
import {Modal} from "antd";
import BonExamenPdfViewer from "./BonExamenPdf";

interface ShwoPdfModalProps {
    bonExamen?: BonExamenType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}
function ShowBonExamenPdfModal({
    bonExamen, isModalOpen, handleOk, handleCancel
}: ShwoPdfModalProps) {
    return (
        <Modal
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            closable={false}
            width={850}
            bodyStyle={{padding: 0, margin: 0}}
            footer={null}
        >
            <BonExamenPdfViewer bonExamen={bonExamen} />
        </Modal>
    );
}

export default ShowBonExamenPdfModal;