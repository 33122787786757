import React, {useEffect, useState} from 'react';
import {Col, Form, FormInstance, Input, Modal, Radio, Row, Select} from 'antd';
import {PersonnelMedicalType} from "../../models/PersonnelMedical";
import {useRole, useService} from "../../api";
import {Role} from "../../utilities/auth";
import {emptyFieldError} from "../../utilities/Utils";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: PersonnelMedicalType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<PersonnelMedicalType>;
    updated: boolean
}

export const PersonnelFormModal: React.FC<CollectionCreateFormProps> = ({
   open,
   onCreate,
   onCancel,
   form,
    updated
}) => {
    const [hasService, setHasService] = useState(false);
    const {data: roles} = useRole();
    const {data: services} = useService();
    const [roleOption, setRoleOption] = useState<Array<{value: number, label: string}>>();
    const [serviceOption, setServiceOption] = useState<Array<{value: number, label: string}>>();

    const onRoleChange = (value: number) => {
        const role = roles.filter(role => role.id === value)[0];
        setHasService(
            role.name.toLowerCase() == Role.Medecin.toLowerCase() ||
            role.name.toLowerCase() == Role.Infirmier.toLowerCase()
        );
    }

    useEffect(() => {
        const opt: Array<{value: number, label: string}> = [];
        roles.forEach((role) => {
            if(role.name.toLowerCase() != Role.Patient.toLowerCase())
                opt.push({value: role.id, label: role.name})
        });
        setRoleOption([...opt]);
    }, [roles]);

    useEffect(() => {
        const opt: Array<{value: number, label: string}> = [];
        services.forEach(service => opt.push({value: service.id, label: service.nom}));
        setServiceOption([...opt]);
    }, [services]);


    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour du personnel" : "Ajout d'un personnel"}
            okText={updated ? "Modifier" : "Ajouter"}
            cancelText="Annuler"
            onCancel={() => {
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            maskClosable={false}
            width={800}
        >
            <Form
                form={form}
                name="form_in_modal"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="nom"
                            label="Nom"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="prenom"
                            label="Prenom"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="username"
                            label="Nom d'utilisateur"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Mot de passe"
                            name="motDePasse"
                            rules={[{ required: !updated, message: emptyFieldError}]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12} className="flex justify-around">
                        <Form.Item
                            name="sexe"
                            label="Sexe"
                            className="collection-create-form_last-form-item"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Radio.Group>
                                <Radio value="M">M</Radio>
                                <Radio value="F">F</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="contact"
                            label="Contact"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="role"
                            label="role"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Select
                                onChange={onRoleChange}
                                options={roleOption}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="services"
                            label="service"
                            rules={[{ required: hasService, message: emptyFieldError}]}
                        >
                            <Select options={serviceOption} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};