import React, {useEffect, useState} from 'react';
import DashboardStatCard, {DashboardStatCardItemType} from "../../components/dashboard/DashboardStatCard";
import ServiceList from "../../components/service/ServiceList";
import DashboardCalendar from "../../components/dashboard/DashboardCalendar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardUser, faClockRotateLeft, faHouseMedical} from "@fortawesome/free-solid-svg-icons";
import PatientWaitingList from "../../components/patient/PatientWaitingList";
import {useConsultation, useRdv} from "../../api";
import {EtatOperation} from "../../utilities/Utils";


const DashboardInfirmier: React.FC = () => {
    const [items, setItems] = useState<Array<DashboardStatCardItemType>>([]);
    const {customQuery: customConsultationQuery, count: totalConsultations} = useConsultation();
    const {customQuery: customRdvQuery} = useRdv();
    const [totalConsultationsEnAttente, setTotalConsultationsEnAttente] = useState(0);
    const [totalRdv, setTotalRdv] = useState(0);

    useEffect(() => {
        customRdvQuery('/today/count')
            .then((data:any) => setTotalRdv(data))
            .catch(e => console.log(e));
        customConsultationQuery(`/count/${EtatOperation.EN_ATTENTE}`)
            .then((data:any) => setTotalConsultationsEnAttente(data))
            .catch(e => console.log(e));
    }, []);

    useEffect(() => {
        setItems([
            {
                key: 0,
                title: "Consultation",
                description: "Toutes",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faClipboardUser} />,
                value:totalConsultations
            },
            {
                key: 1,
                title: "Rendez-vous",
                description: "En attente",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faClockRotateLeft} />,
                value: totalRdv
            },
            {
                key: 2,
                title: "Salle d'attente",
                description: "En ce moment",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faHouseMedical} />,
                value: totalConsultationsEnAttente
            }
        ]);
    }, [totalRdv, totalConsultationsEnAttente, totalConsultations]);

    return (
        <>
            <div className="mb-10 grid grid-cols-3 gap-2 md:grid-cols-3 lg:gap-5">
                <div className="col-span-2">
                    <div className="mb-6 grid grid-cols-1 gap-2 md:gap-6 md:grid-cols-3">
                        <DashboardStatCard items={items} />
                    </div>
                    <div className="p-4 pb-6 bg-white shadow-sm rounded border border-solid border-transparent">
                        <h1 className="text-lg font-bold mb-6">Service</h1>
                        <div className="grid grid-cols-3 gap-6">
                            <ServiceList />
                        </div>
                    </div>
                </div>
                <div className="col-span-1 p-4 bg-white shadow-sm rounded border border-solid border-transparent">
                    <div className="mb-6">
                        <h1 className="text-lg font-bold mb-6">Aujourd'hui</h1>
                        <DashboardCalendar />
                    </div>
                </div>
            </div>
            <div className="mt-10 p-4 overflow-auto bg-white shadow-sm rounded border border-solid border-transparent">
                <PatientWaitingList />
            </div>
        </>
    )
};


export default DashboardInfirmier;