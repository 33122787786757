import React, { useState } from 'react';
import {Button, Form, Input, Modal, Radio, Select} from 'antd';
import {emptyFieldError} from "../../utilities/Utils";
import {RoleType} from "../../models/Role";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: RoleType) => void;
    onCancel: () => void;
}

export const RoleFormModal: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
}) => {
    const [form] = Form.useForm();

    return (
        <Modal
            open={open}
            title="Nouveau role"
            okText="Ajouter"
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values);
                        form.resetFields();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="role"
                    label="Nom"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};