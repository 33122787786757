import {Layout, Spin} from 'antd';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import DashboardMenu from "../../widgets/DashboardMenu";
import Sider from "antd/es/layout/Sider";
import HeaderBar from "../../widgets/HeaderBar";
import { Content } from 'antd/es/layout/layout';
const DashboardBreadcrumb = React.lazy(()=> import('../../components/dashboard/DashboardBreadcrumb'));
const DashboardFooter = React.lazy(()=> import('../../widgets/DashboardFooter'));

function DashboardIndex() {
    let colorBgContainer = "transparent";
    return (
    <Suspense fallback={<Spin className="w-full h-full flex justify-center items-center" size="large" />}>
        <Layout>
            <HeaderBar />
            <Content style={{ padding: '0 50px' }}>
                <DashboardBreadcrumb />
                <Layout style={{ padding: '0 0', background: colorBgContainer }}>
                    <Sider style={{ background: colorBgContainer }} width={250} >
                        <DashboardMenu />
                    </Sider>
                    <Content style={{ padding: '0 24px' }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Content>
            <DashboardFooter />
        </Layout>
    </Suspense>
  )
}

export default DashboardIndex;