import {Button, message, Tag, Tooltip} from 'antd'
import { useAuth } from '../../../provider/AuthProvider';
import {hasRole, Role} from '../../../utilities/auth'
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useEffect, useState} from "react";
import {useConsultation} from "../../../api";
import {ConsultationType} from "../../../models/Consultation";
import {
    EtatOperation,
    getEtatOperationColorAndName, getFullName,
    onErrorResponse,
    onSuccessResponse
} from "../../../utilities/Utils";
import {useNavigate} from "react-router-dom";
import {AuditOutlined, EyeOutlined, FormOutlined} from "@ant-design/icons";
import ShowConsultationPdfModal from "../../../components/consultation/ShowConsultationPdfModal";
export let consulationColumns: ColumnConfig<ConsultationType>[] = [
    {
        title: 'Patient',
        dataIndex: 'dossierMedical',
        key: 'dossierMedical',
        render: (record) => <span className="text-primary">{getFullName(record?.dossierMedical.patient)}</span>
    },
    {
        title: 'Service',
        dataIndex: 'service',
        key: 'service',
        render: (record) => <span>{record.service.nom}</span>
    },
    {
        title: 'Médecin',
        dataIndex: 'personnelMedical',
        key: 'personnelMedical',
        render: (record) => <span>{getFullName(record.personnelMedical)}</span>
    },
  {
    title: 'Lieu',
    dataIndex: 'lieu',
    key: 'lieu',

  },
  {
    title: 'Prix',
    dataIndex: 'prix',
    key: 'prix',
  },
  {
    title: 'Part Payée Par Patient',
    dataIndex: 'partPayeeParPatient',
    key: 'partPayeeParPatient',
  },
  {
    title: 'Etat',
    dataIndex: 'etat',
    key: 'etat',
    render: (record) => <Tag
        color={getEtatOperationColorAndName(record.etat).color}>
      {getEtatOperationColorAndName(record.etat).name}
    </Tag>
  }
];

function ListConsultation() {
    const [messageApi, contextHolder] = message.useMessage();
  const { user } = useAuth();
  const { data, isLoading, remove } = useConsultation();
  const [consultations,setConsultations] = useState<ConsultationType[]>([]);
  const [consultation, setConsultation] = useState<ConsultationType>();
    const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

    useEffect(() => {
        if(hasRole(user.roles?.name, Role.Medecin)) {
            consulationColumns = consulationColumns.filter(e => e.key !== "partPayeeParPatient");
        }
    }, []);

    useEffect(() => {
            setConsultations(data);
    }, [data]);

  const handleConsulter = (e:  React.MouseEvent<HTMLAnchorElement>, record: ConsultationType) => {
      navigate(`/dashboard/add-consultation/${record.id}`);
  }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onShow = (record: ConsultationType) => {
        setConsultation(record);
        showModal();
    }
    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "La consultation a été supprimée"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression de la consultation a échouée")
            }
        );
    }

    const onCustomRow = (record: ConsultationType) => {
        if(record.etat.toLowerCase() === EtatOperation.EN_ATTENTE.toLowerCase()) {
            return (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Medecin))?
                (
                    <a className="text-green-600 hover:text-green-800" onClick={(e) => handleConsulter(e, record)}>
                        <Tooltip title="Consulter"><AuditOutlined  style={{ fontSize: '18px' }} /></Tooltip>
                    </a>
                ) : null
        } else {
            if(record.etat.toLowerCase() === EtatOperation.TERMINEE.toLowerCase()) {
                return (
                    <>
                        <a className="text-yellow-700 hover:text-yellow-900" onClick={(e) => onShow(record)}>
                            <EyeOutlined style={{ fontSize: '18px' }} />
                        </a>
                        <a className="text-primary hover:text-primary-dark" onClick={(e) => handleConsulter(e, record)}>
                            <FormOutlined style={{ fontSize: '18px' }} />
                        </a>
                    </>
                )
            }
            return (
                <a className="text-primary hover:text-primary-dark" onClick={(e) => handleConsulter(e, record)}>
                    <FormOutlined style={{ fontSize: '18px' }} />
                </a>
            );
        }
    }

  return (
    <>
        {contextHolder}
    <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
      <DynamicTable<ConsultationType>
          queryKey="consultationQuery"
          header={
              <div className="flex justify-between">
                  <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Consultations</Tag>
                  <div className="flex space-x-2">
                      {
                          (hasRole(user.roles?.name, Role.Secretaire) || hasRole(user.roles?.name, Role.Admin))
                              ? (
                                  <Button type="primary" size="middle" className="bg-black border-black" onClick={() => navigate('/dashboard/entamer-consultation')}>
                                      Nouvelle
                                  </Button>
                              ) : null
                      }
                  </div>
              </div>
          }
          columns={consulationColumns}
          data={consultations}
          isLoading={isLoading}
          action={{
            onDeleteRow: (hasRole(user.roles?.name, Role.Medecin) || hasRole(user.roles?.name, Role.Admin)) ? onDelete : undefined,
            customAction: onCustomRow,
          }}
      />
    </div>
        <ShowConsultationPdfModal consultation={consultation} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel} />
  </>
  )
}

export default ListConsultation