import React from 'react';
import {Modal} from "antd";
import RecuPdfViewer from "./RecuPdf";
import {RecuType} from "../../models/Recu";

interface ShwoPdfModalProps {
    recu?: RecuType;
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}
function ShowRecuPdfModal({
    recu, isModalOpen, handleOk, handleCancel
}: ShwoPdfModalProps) {
    return (
        <Modal
               open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
               closable={false}
               width={900}
               bodyStyle={{padding: 0, margin: 0}}
               footer={null}
        >
           <RecuPdfViewer recu={recu} />
        </Modal>
    );
}

export default ShowRecuPdfModal;