import React, {useEffect, useState} from 'react';
import { AutoComplete, Input } from 'antd';
import {usePatient, usePersonnelMedical} from "../api";
import {Link, useNavigate} from "react-router-dom";


const renderTitle = (title: string, link: string) => (
  <span>
    {title}
    <Link
      style={{ float: 'right' }}
      to={`${link}`}
    >
      Voir plus
    </Link>
  </span>
);

const renderItem = (title: string) => ({
  value: title,
  label: (
    <div>
      {title}
    </div>
  ),
});

interface OptionValues {
  label: JSX.Element;
  options: {value: string, label: JSX.Element}[]
}

const SearchInput = () => {
  const {data: personnel} = usePersonnelMedical();
  const {data: patients} = usePatient();
  const [options, setOptions] = useState<OptionValues[]>();
  const navigate = useNavigate();

  useEffect(() => {
    const patientsValues: OptionValues = {
      label: renderTitle('Patients', 'list-patient'),
      options: []
    }
    const personnelValues: OptionValues = {
      label: renderTitle('Personnels', 'list-personnel'),
      options: []
    }
    patientsValues.options = patients.map(patient => renderItem(`${patient.nom} ${patient.prenom}`));
    personnelValues.options = personnel.map(personnel => renderItem(`${personnel.nom} ${personnel.prenom}`));
    setOptions([patientsValues, personnelValues]);

  }, [personnel, patients]);

  const onSelect = (data: any) => {
    patients.forEach((patient) => {
      const fullName = `${patient.nom} ${patient.prenom}`.toLowerCase();
      if(fullName == data.toLowerCase()) {
        navigate(`${patient.id}/dossier-medical`)
      }
    });
    personnel.forEach((pers) => {
      const fullName = `${pers.nom} ${pers.prenom}`.toLowerCase();
      if(fullName == data.toLowerCase()) {
        navigate(`${pers.id}/profil`)
      }
    });
  }

  return (
      <AutoComplete
          className="w-2/3"
          dropdownMatchSelectWidth={500}
          options={options}
          filterOption={true}
          onSelect={onSelect}
          autoClearSearchValue={true}

      >
        <Input.Search size={"middle"} className="bg-gray-200 text-black rounded-lg font-medium placeholder:text-black placeholder:font-medium"
                      placeholder="Rechercher" allowClear />
      </AutoComplete>
  );
}

export default SearchInput;