import {
  Button, Steps, Form, message
} from 'antd';
  import React, { useState } from 'react';
import EntamerConsultationForm from '../../../components/consultation/EntamerConsultationForm';
import InfoFormPatient from "../../../components/consultation/InfoFomPatient";
import {EtatOperation, getValidDate, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {useConsultation, useDossierMedical, usePersonnelMedical, useService} from "../../../api";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
  
  const { Step } = Steps;

  const EntamerConsultation: React.FC = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [current, setCurrent] = useState(0);
    const {one: getDossierMedical} = useDossierMedical();
    const {one: getService} = useService();
    const {one: getPersonnel} = usePersonnelMedical();
    const { add } = useConsultation();
    const [formStep1] = Form.useForm();
    const [formStep2] = Form.useForm();
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
  
    const steps = [
      {
        title: 'Patient',
        form: formStep1,
        description: 'Information basique',
        content: <InfoFormPatient form={formStep1}/>,
      },
      {
        title: 'Consultation',
        form: formStep2,
        description: 'Entamer',
        content: <EntamerConsultationForm form={formStep2} />,
      }
    ];
  
    const next = () => {
      const currentForm = steps[current].form;
      currentForm.validateFields()
          .then((values) => {
            setCurrent(current + 1);
            setFormData({ ...formData, ...values });
          })
          .catch(errInfo => console.log(errInfo));
    };
  
    const prev = () => {
      setCurrent(current - 1);
    };

    const onCreate = async (values: any) => {
      values.etat = EtatOperation.EN_ATTENTE;
      add(
          values,
          {
            onSuccess: (values) => {
              onSuccessResponse(messageApi, values, "La consultation a été entamée");
              setTimeout(() => navigate("/dashboard/list-consultation"), 1500);
            },
            onError: (values) => onErrorResponse(messageApi, values, "L'ajout de la consultation a échoué")
          }
      );
    }
  
    const submit = () => {
      const finalForm = steps[steps.length - 1].form;

      finalForm.validateFields()
      .then(values => {
        const allValues = { ...formData, ...values };
        onCreate(allValues);
      })
      .catch(errInfo => console.log(errInfo))
    }
  
    return (
      <>
        {contextHolder}
        <div className="flex flex-col items-center bg-white p-4 rounded border border-solid border-transparent  border-100">
          <h1 className="text-lg font-bold mt-4 mb-8">Nouvelle consultation</h1>
          <Steps current={current} className="py-4 w-3/4">
            {steps.map(item => (
              <Step key={item.title} title={item.title} description={item.description} />
            ))}
          </Steps>
          <div className="steps-content py-4 w-3/4">

              {steps[current].content}
            </div>
          <div className="steps-action w-3/4 flex justify-end space-x-4">
          {current > 0 && (
              <Button type="default" className="text-primary border-primary" onClick={() => prev()}>
                Précedant
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" className="bg-primary border-primary" onClick={() => next()}>
                Suivant
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary"  className="bg-green-500 border-green-500" onClick={() => submit()}>
                Entamer
              </Button>
            )}         
          </div>
        </div>
      </>
    );
  };
  
  export default EntamerConsultation;