import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Form, FormInstance, Input, Row} from "antd";
import {useExamen} from "../../api";
import {emptyFieldError} from "../../utilities/Utils";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {BonExamenType} from "../../models/BonExamen";

interface BonExamenFormConsultationProps {
    form: FormInstance<BonExamenType>
}
function BonExamenFormConsultation({form}:BonExamenFormConsultationProps) {
    const [examenOptions, setExamenOptions] = useState<{ value: string }[]>([]);
    const { customQuery: examenQuery } = useExamen();

    useEffect(() => {
        return () => {
            examenQuery("/file")
                .then((data: any) => {
                    const ops: { value: string }[] = [];
                    data.forEach((option: string) => ops.push({value: option}));
                    setExamenOptions(ops);
                })
                .catch(e => console.log(e));
        };
    }, []);
    return (
        <Form form={form} layout="vertical">
            <h1 className="my-4">Examens À Effectuer</h1>
            <Form.List name="examens">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row key={key} className="w-full -align-baseline" gutter={10} align="top">
                                <Col span={22}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'nom']}
                                        rules={[{ required: true, message: emptyFieldError }]}
                                    >
                                        <AutoComplete
                                            options={examenOptions}
                                            filterOption={(inputValue, option) =>
                                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                            placeholder="Examen"
                                        />
                                    </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'resultat']}
                                            hidden
                                        >
                                            <Input placeholder="Résultat" />
                                        </Form.Item>
                                </Col>
                                <Col span={2} className="flex justify-center">
                                    <MinusCircleOutlined className="text-secondary" style={{fontSize: 20}} onClick={() => remove(name)} />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" className="hover:text-primary hover:border-primary" onClick={() => add()} block icon={<PlusOutlined />}>
                                Ajouter un examen
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
}

export default BonExamenFormConsultation;