import React, {useEffect} from 'react';
import { DownOutlined, UserOutlined, SettingOutlined, ImportOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {Avatar, Badge, Card, Dropdown, Menu} from 'antd';
import { useAuth } from '../provider/AuthProvider';
import { useNavigate } from 'react-router-dom';
import {hasRole, Role} from "../utilities/auth";

function ProfileDropdown() {
  const { user, signout } = useAuth();
    const navigate = useNavigate();
    const items =
        [
            {
                label: 'Profil',
                icon: <UserOutlined />,
                key: 'profil',
            },
            {
                label: 'Deconnexion',
                icon: <ImportOutlined />,
                key: 'deconnexion',
            },
        ];
  const onClick: MenuProps['onClick'] = ({ key }) => {
    if(key === "deconnexion") {
      signout(() => {
        navigate("/login");
      });
    } else {
        navigate(`${key}`);
    }
  };

    useEffect(() => {
        return () => {
            console.log(user);
            if(hasRole(user?.roles?.name, Role.Admin)) {
                const newLink = {
                    label: 'Parametre',
                    icon: <SettingOutlined />,
                    key: 'admin-app-setting',
                };
                items.splice(1, 0, newLink);
            }
        };
    }, );

  
  const menu = (
    <Menu
      onClick={onClick}
      items={items}
    />
  );

  return (
        <Dropdown overlay={menu} placement="bottom" >
            <div className="flex items-center">
                <span className="mr-1 text-sm text-black font-bold">{user?.roles?.name}</span>
                <Badge dot style={{backgroundColor: "green", borderColor: "green"}}>
                    {
                        user?.photo
                            ? <Avatar size="large" src={<img className="w-10 h-10 bg-black" src={`/images/${user?.photo}`} alt="" />} />
                            : <Avatar size="large" icon={<UserOutlined />} />
                    }
                </Badge>
            </div>
        </Dropdown>
  )
}

export default ProfileDropdown