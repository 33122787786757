import React, {useEffect, useState} from 'react';
import {EllipsisOutlined} from "@ant-design/icons";
import { Menu, MenuProps } from 'antd';
import {navLinks} from "../utilities/navLinks";
import {useAuth} from "../provider/AuthProvider";

function DashboardMenu() {
    const [current, setCurrent] = useState('home');
    const [links, setLinks] = useState<MenuProps['items']>();
    const { user } = useAuth();

    useEffect(() => {
        const menuLinks = allowedLink(user?.roles?.name);
        const items = []; // New menu links arr without can_view propriety
        for(const link of menuLinks) {
            let { key, label, icon, children  } = link;
            if (children) {
                const childrenItems = [];
                for(const subLink of children) {
                    const { key, label, icon, children } = subLink;
                    childrenItems.push({ key, label, icon, children });
                }
                children = childrenItems;
            }
            items.push({ key, label, icon, children });
        }
        setLinks([...items]);
    }, [user?.roles]);

    const allowedLink = (role: string = "") => {
        const links = navLinks.filter(link =>  link.can_view?.includes(`${role}`) || link.can_view?.includes("All"));
        const newLinks = [];
        for (const link of links) {
            const subLinks = link.children?.filter(subLink => subLink.can_view?.includes(`${role}`) || subLink.can_view?.includes("All"));
            if(subLinks) {
                link.children = subLinks;
            }
            newLinks.push(link);
        }
        return newLinks;
    }

    const onClick: MenuProps['onClick'] = e => {
        setCurrent(e.key);
    };

    return (
        <Menu
            onClick={onClick}
            selectedKeys={[current]} items={links}
            overflowedIndicator={<EllipsisOutlined className="w-5 h-5" />}
            mode="inline"
            style={{ height: '100%' }}
        />
    );
}

export default DashboardMenu;