import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {FactureItemsType} from "./FactureTableRow";

const borderColor = '#3a0202'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
        marginVertical: 0,
        paddingVertical: 0,
    },
    desc: {
        width: '70%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 10
    },
    total: {
        width: '30%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

interface FactureTableFooterProps {
    items?: FactureItemsType[]
}

const FactureTableFooter = ({items} : FactureTableFooterProps) => {
    const total = items?.map(item => item.qty * item.price)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    return(
        <View style={styles.row}>
            <Text style={styles.desc}>TOTAL</Text>
            <Text style={styles.total}>{ total ? total : 0}</Text>
        </View>
    )
};

export default FactureTableFooter