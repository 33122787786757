import {Button, Form, message, Tag} from 'antd'
import React, {useState} from 'react'
import { useAuth } from '../../../provider/AuthProvider';
import {hasRole, Role} from '../../../utilities/auth'
import {HospitalisationType} from "../../../models/Hospitalisation";
import {useHospitalisation} from "../../../api";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import {HospitalisationFormModal} from "../../../components/hospitalisation/HospitalisationFormModal";
import {getFullName, onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";

const columns: ColumnConfig<HospitalisationType>[] = [
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    render: record => <span className="text-primary">{record.patient}</span>
  },
  {
    title: 'Medecin',
    dataIndex: 'medecin',
    key: 'medecin',
  },
  {
    title: 'Service',
    dataIndex: 'service',
    key: 'service',
  },
  {
    title: 'Date d\'entrée',
    dataIndex: 'dateEntree',
    key: 'dateEntree',
  },
  {
    title: 'Date de sortie',
    dataIndex: 'dateSortie',
    key: 'dateSortie',
  },
  {
    title: 'Date d\'entrée',
    dataIndex: 'dateEntree',
    key: 'dateEntree',
  },
];
function ListHospitalisation() {
  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useAuth();
  const {data, add, update, remove, isLoading} = useHospitalisation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [updated, setUpdated] = useState<boolean>(false);
  const [hospitalisation, setHospitalisation] = useState<HospitalisationType>();
  const [showHospitalisationModal, setShowHospitalisationModal] = useState(false);

  const onCreate = async (values: any, updated: boolean) => {
    if (updated) {
      update(
          values,
          {
            onSuccess: (values) => onSuccessResponse(messageApi, values, "Le hospitalisation a été modifié"),
            onError: (values) => onErrorResponse(messageApi, values, "La modification du hospitalisation a échouée")
          }
      );
    } else {
      add(
          values,
          {
            onSuccess: (values) => onSuccessResponse(messageApi, values, "L'hospitalisation a été ajouté"),
            onError: (values) => onErrorResponse(messageApi, values, "L'ajout de l'hospitalisation a échoué")
          }
      );
    }
    setOpen(false);
    form.resetFields();
  };

  const onDelete = (id: number) => {
    remove(
        id,
        {
          onSuccess: (values) => onSuccessResponse(messageApi, values, "Le hospitalisation a été supprimé"),
          onError: (values) => onErrorResponse(messageApi, values, "La suppression du hospitalisation a échouée")
        }
    );
  }

  const onShow = (record: HospitalisationType) => {}

  const onUpdate = (record: HospitalisationType) => {
    form.setFieldsValue(record);
    setUpdated(true);
    setOpen(true);
  }
  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  }

  return (
    <>
      {contextHolder}
    <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
      <DynamicTable<HospitalisationType>
          queryKey="hospitalisationQuery"
          header={
            <div className="flex justify-between">
              <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Hospitalisations</Tag>
              {
                (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Secretaire))
                    ?
                    (
                        <Button type="primary" size="middle" className="bg-black border-black"  onClick={() => {
                          setUpdated(false);
                          setOpen(!open);
                        }}>
                          Ajouter
                        </Button>
                    )
                    : null
              }
            </div>
          }
          columns={columns}
          data={data}
          isLoading={isLoading}
          action={{
            onShowRow: onShow,
            onUpdateRow: (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Secretaire)) ? onUpdate : undefined,
            onDeleteRow: (hasRole(user.roles?.name, Role.Admin) || hasRole(user.roles?.name, Role.Secretaire)) ? onDelete : undefined
          }}
      />
      <HospitalisationFormModal
          open={open}
          onCreate={onCreate}
          onCancel={onCancel}
          updated={updated}
          form={form}
      />
    </div>
  </>
  )
}

export default ListHospitalisation