import React, { useState } from 'react';
import { Card, Avatar, Typography, List, Divider, Tabs } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, ToolOutlined, TeamOutlined, CalendarOutlined } from '@ant-design/icons';
import {useAuth} from "../../provider/AuthProvider";
import {getFullName} from "../../utilities/Utils";
import {hasRole, Role} from "../../utilities/auth";

const { TabPane } = Tabs;

interface UserProfilePageProps {
    role: string;
}

const UserProfilePage: React.FC<UserProfilePageProps> = ({ role }) => {
    const [activeTab, setActiveTab] = useState('profile');
    const {user} = useAuth();

    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    return (
        <div className="bg-gray-100">
            <Card className="w-full" style={{minHeight: 480}}>
                <div className="flex">
                    <div className="w-1/4">
                        <Avatar size={120} icon={<UserOutlined />} />
                        <Typography.Title level={4} className="mt-4">{user.username}</Typography.Title>
                        <List>
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<MailOutlined />}
                                    title="Adresse e-mail"
                                    description={user.email}
                                />
                            </List.Item>
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<PhoneOutlined />}
                                    title="Contact"
                                    description={user.contact}
                                />
                            </List.Item>
                        </List>
                    </div>
                    <div className="w-3/4 p-4">
                        <Tabs activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="Profil" key="profile">
                                <List>
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<UserOutlined />}
                                            title="Nom"
                                            description={getFullName(user)}
                                        />
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<MailOutlined />}
                                            title="Adresse e-mail"
                                            description={user.email}
                                        />
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<PhoneOutlined />}
                                            title="Numéro de téléphone"
                                            description={user.contact}
                                        />
                                    </List.Item>
                                </List>
                            </TabPane>
                            {(role === 'admin' || role === 'cashier' || role === 'financial_director' || role === 'ceo') && (
                                <TabPane tab="Statistiques" key="statistics">
                                    <div className="p-4">
                                        <Typography.Title level={5}>Statistiques de performance</Typography.Title>
                                        <div className="flex justify-between mt-4">
                                            <div>
                                                <Typography.Text>Nombre de patients</Typography.Text>
                                                <Typography.Title level={3}>245</Typography.Title>
                                            </div>
                                            <div>
                                                <Typography.Text>Revenu mensuel</Typography.Text>
                                                <Typography.Title level={3}>$25,500</Typography.Title>
                                            </div>
                                            <div>
                                                <Typography.Text>Temps moyen de consultation</Typography.Text>
                                                <Typography.Title level={3}>23 min</Typography.Title>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            )}
                            {(hasRole(role, Role.Secretaire) || hasRole(role, Role.Admin)) && (
                                <TabPane tab="Horaires de travail" key="schedule">
                                    <List>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<CalendarOutlined />}
                                                title="Horaires de travail"
                                                description={""}
                                            />
                                        </List.Item>
                                    </List>
                                </TabPane>
                            )}
                            {(
                                <TabPane tab="Rôle et département" key="role">
                                    <List>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<ToolOutlined />}
                                                title="Rôle"
                                                description={user.roles?.name}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<TeamOutlined />}
                                                title="Service"
                                                description={user.services?.nom}
                                            />
                                        </List.Item>
                                    </List>
                                </TabPane>
                            )}
                        </Tabs>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default UserProfilePage;