import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faClipboardUser,
    faClockRotateLeft,
    faHouseMedical
} from '@fortawesome/free-solid-svg-icons'
import DashboardCalendar from '../../components/dashboard/DashboardCalendar';
import DashboardRDVTimeline from '../../components/dashboard/DashboardRDVTimeline';
import DashboardStatCard, {DashboardStatCardItemType} from '../../components/dashboard/DashboardStatCard';
import ServiceList from '../../components/service/ServiceList';
import PatientWaitingList from "../../components/patient/PatientWaitingList";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import {useConsultation, useRdv} from "../../api";
import {EtatOperation} from "../../utilities/Utils";

function DashboardSecretaire() {
    const [items, setItems] = useState<Array<DashboardStatCardItemType>>([]);
    const {customQuery: customRdvQuery} = useRdv();
    const {customQuery: customConsultationQuery} = useConsultation();
    const [totalConsultations, setTotalConsultations] = useState(0);
    const [totalConsultationsEnAttente, setTotalConsultationsEnAttente] = useState(0);
    const [totalRdv, setTotalRdv] = useState(0);

    useEffect(() => {
        customRdvQuery('/count/today')
            .then((data:any) => setTotalRdv(data))
            .catch(e => console.log(e));
        customConsultationQuery('/count/today')
            .then((data: any) => setTotalConsultations(data))
            .catch(e => console.log(e));
        customConsultationQuery(`/count/today${EtatOperation.EN_ATTENTE}`)
            .then((data:any) => setTotalConsultationsEnAttente(data))
            .catch(e => console.log(e));
    }, []);


    useEffect(() => {
        setItems([
            {
                key: 0,
                title: "Consultation",
                description: "Aujourd'hui",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faClipboardUser} />,
                value: totalConsultations
            },
            {
                key: 1,
                title: "Rendez-vous",
                description: "Aujourd'hui",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faClockRotateLeft} />,
                value: totalRdv
            },
            {
                key: 2,
                title: "Salle d'attente",
                description: "En ce moment",
                icon:  <FontAwesomeIcon className="w-6 h-6" icon={faHouseMedical} />,
                value: totalConsultationsEnAttente
            }
        ]);
    }, [totalRdv, totalConsultationsEnAttente, totalConsultations]);
  return (
    <>
        <div className="mb-10 grid grid-cols-3 gap-2 md:grid-cols-3 lg:gap-5 ">
          <div className="col-span-2">
            <div className="mb-6 grid grid-cols-1 gap-2 md:gap-6 md:grid-cols-3">
              <DashboardStatCard items={items} />
            </div>
            <div className="p-4 pb-6 bg-white shadow-sm rounded border border-solid border-transparent">
              <div className="flex justify-between">
                <h1 className="text-lg font-bold mb-6">Consultation</h1>
                  <Link to="entamer-consultation" className="text-md text-primary font-semibold">
                      <span className="inline-block align-middle mr-1">Nouvelle</span>
                      <PlusOutlined className="inline-block align-middle" style={{fontSize: 14}}/>
                  </Link>
              </div>
              <div className="grid grid-cols-3 gap-6">
                <ServiceList />
              </div>
            </div>
          </div>
          <div className="col-span-1 p-4 bg-white shadow-sm rounded border border-solid border-transparent">
                <h1 className="text-lg font-bold mb-6">Aujourd'hui</h1>
                <DashboardCalendar />
            </div>
        </div>
        <div className="mt-10 p-4 bg-white shadow-sm rounded border border-solid border-gray-100">
            <h1 className="text-lg font-bold mb-6">Rendez-vous en attente</h1>
            <DashboardRDVTimeline />
        </div>
        <div className="mt-10 p-4 overflow-auto bg-white shadow-sm rounded border border-solid border-transparent">
            <PatientWaitingList />
        </div>        
    </>
  )
}

export default DashboardSecretaire