import { useAuth } from '../../../provider/AuthProvider'
import {TypeSoinType} from "../../../models/TypeSoin";
import DynamicTable, {ColumnConfig} from "../../../components/DynamicTable";
import React, {useState} from "react";
import {useTypeSoin} from "../../../api";
import {TypeSoinFormModal} from "../../../components/typeSoin/TypeSoinFormModal";
import {Form, message, Tag} from "antd";
import {onErrorResponse, onSuccessResponse} from "../../../utilities/Utils";
import {hasRole, Role} from "../../../utilities/auth";

const columns: ColumnConfig<TypeSoinType>[] = [
    {
        title: 'Nom',
        dataIndex: 'nom',
        key: 'nom',
        render: record => <span className="text-primary">{record.nom}</span>
    },
    {
        title: 'Prix',
        dataIndex: 'prix',
        key: 'prix',
        render: (record) => <span>{record?.prix} FCFA</span>
    }
];

function ListTypeSoin() {
    const { user } = useAuth();
    const { data, isLoading, update, remove } = useTypeSoin();
    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const onCreate = (values: any) => {
        update(
            values,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le type de soin a été modifié"),
                onError: (values) => onErrorResponse(messageApi, values, "La modification du type de soin a échouée")
            }
        );
        setOpen(false);
    }

    const onDelete = (id: number) => {
        remove(
            id,
            {
                onSuccess: (values) => onSuccessResponse(messageApi, values, "Le type de soin a été supprimé"),
                onError: (values) => onErrorResponse(messageApi, values, "La suppression du type soin a échouée")
            }
        );
    }

    const onUpdate = (record: TypeSoinType) => {
        form.setFieldsValue(record);
        setOpen(true );
    }

    return (
        <>
            {contextHolder}
            <div className="bg-white p-4 rounded border border-solid border-transparent border-100">
                <DynamicTable<TypeSoinType>
                    queryKey="typeSoinQuery"
                    header={
                        <div className="flex justify-between">
                            <Tag color="warning" className="p-1 text-base font-bold mb-4 mr-4">Les soins pris en charge</Tag>
                        </div>
                    }
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    action={
                        hasRole(user.roles.name, Role.Admin) ? {
                            onDeleteRow: onDelete,
                            onUpdateRow: onUpdate
                        } : undefined
                    }
                />
            </div>
            <TypeSoinFormModal
                open={open}
                onCreate={onCreate}
                onCancel={() => setOpen(false)}
                form={form}
                updated={true}
            />
        </>
    )
}

export default ListTypeSoin