import React, {useEffect, useState} from 'react';
import {Col, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Radio, Row, Select} from 'antd';
import {HospitalisationType} from "../../models/Hospitalisation";
import {useChambre, usePersonnelMedical, useRole, useService} from "../../api";
import {Role} from "../../utilities/auth";
import {emptyFieldError} from "../../utilities/Utils";
import locale from "antd/es/date-picker/locale/fr_FR";
import TextArea from "antd/lib/input/TextArea";
import moment, {Moment} from "moment";

const { RangePicker } = DatePicker;
interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: HospitalisationType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<HospitalisationType>;
    updated: boolean
}

const dateFormat = 'YYYY/MM/DD';
export const HospitalisationFormModal: React.FC<CollectionCreateFormProps> = ({
    open,
    onCreate,
    onCancel,
    form,
    updated
}) => {
    const {data: chambres} = useChambre();
    const {filteredData} = usePersonnelMedical();
    const {data: personnel} = filteredData({"role_name": Role.Medecin});
    const [chambreOption, setChambreOption] = useState<Array<{value: number, label: string}>>();
    const [personnelOption, setPersonnelOption] = useState<Array<{value: number, label: string}>>();
    const onSelectChambre = (value: number) => {
        const chambre = chambres.filter((chambre) => chambre.id == value)[0];
        form.setFieldValue("prixChambre", chambre.prix);
    }

    useEffect(() => {
        const opt: Array<{value: number, label: string}> = [];
        chambres.forEach((chambre) => opt.push({value: chambre.id, label: `${chambre.nom} ${chambre.numero}: Chambre ${chambre.type}`}));
        setChambreOption([...opt]);
    }, [chambres]);

    useEffect(() => {
        const opt: Array<{value: number, label: string}> = [{value: 0, label: " "}];
        personnel?.forEach(personnel => opt.push({value: personnel.id, label: `${personnel.nom} ${personnel.prenom}`}));
        setPersonnelOption([...opt]);
    }, [personnel]);

    const onDateChange = (dates: any) => {
        const d = dates as [moment.Moment, moment.Moment];
        const start = d[0];
        const end = d[1];
        console.log(start.diff(end, "days"));
    }

    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour de l'hospitalisation" : "Nouvelle hospitalisation"}
            okText={updated ? "Modifier" : "Enregistrer"}
            cancelText="Annuler"
            onCancel={() => {
                onCancel();
            }}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            maskClosable={false}
            width={800}
        >
            <Form
                form={form}
                name="form_in_modal"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                <Form.Item
                    name="sejour"
                    label="Séjour"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <RangePicker onChange={(dates) => onDateChange(dates)} locale={locale} className="w-full" format={dateFormat} />
                </Form.Item>
                <Form.Item
                    name="objet"
                    label="Objet"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <TextArea rows={2}></TextArea>
                </Form.Item>
                <Form.Item
                    name="medecin"
                    label="Médecin traitant"
                    rules={[{ required: true, message: emptyFieldError}]}
                >
                    <Select
                        options={personnelOption}
                    />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            name="nombreJour"
                            label="Nombre de jour"
                        >
                            <Input disabled suffix="Jour" className="w-full"/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="nbPassageMedecin"
                            label="Nb Passage: medecin traitant"
                        >
                            <InputNumber className="w-full" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="nbPassageMedecinSpecialiste"
                            label="Nb Passage: medecin spécialiste"
                        >
                            <InputNumber className="w-full" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={14}>
                        <Form.Item
                            name="chambre"
                            label="Chambre"
                            rules={[{ required: true, message: emptyFieldError}]}
                        >
                            <Select options={chambreOption} onSelect={onSelectChambre}/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="prixChambre"
                            label="Prix de la Chambre"
                        >
                            <Input disabled  className="w-full" suffix="FCFA"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};