import React from 'react';
import {Badge, Pagination, Tabs, Tag} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import {ConsultationType} from "../../models/Consultation";
import {SoinType} from "../../models/Soin";
import {BonExamenType} from "../../models/BonExamen";
import {getFullName} from "../../utilities/Utils";

interface HistoriqueMedicalSectionProps {
    consultations?: ConsultationType[];
    bonExamens?: BonExamenType[];
    soins?: SoinType[]
}

function HistoriqueMedicalSection({
    consultations, bonExamens, soins
}: HistoriqueMedicalSectionProps) {

    const pageSize = 2;

    const [consultationsPage, setConsultationsPage] = React.useState(1);
    const [soinsPage, setSoinsPage] = React.useState(1);
    const [labResultsPage, setLabResultsPage] = React.useState(1);

    const handleConsultationsPageChange = (page: React.SetStateAction<number>) => {
        setConsultationsPage(page);
    };

    const handleSoinsPageChange = (page: React.SetStateAction<number>) => {
        setSoinsPage(page);
    };

    const handleLabResultsPageChange = (page: React.SetStateAction<number>) => {
        setLabResultsPage(page);
    };

    const renderConsultations = () => {
        const startIndex = (consultationsPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const consultationsToShow = consultations?.slice(startIndex, endIndex);

        return (
            <div className="space-y-4">
                {consultationsToShow?.map((consultation, index) => (
                    <div key={index} className="bg-gray-200 p-4 rounded-lg shadow-sm">
                        <div className="font-bold my-1">Consultation</div>
                        <div className="flex space-x-5 lg:space-x-20">
                            <div className="flex flex-col">
                                <div className="my-1">
                                    <span className="font-semibold">Médecin :</span>{' '}
                                    {getFullName(consultation.personnelMedical)}
                                </div>
                                <div className="my-1">
                                    <span className="font-semibold">Service :</span>{' '}
                                    {consultation.service.nom}
                                </div>
                                <div className="my-1">
                                    <span className="font-semibold">Lieu :</span>{' '}
                                    {consultation.lieu}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="my-1">
                                    <span className="font-semibold">Motif :</span>{' '}
                                    {consultation.motif}
                                </div>
                                <div className="my-1">
                                    <span className="font-semibold">Diagnostic :</span>{' '}
                                    {consultation.diagnostic}
                                </div>
                                <div className="my-1">
                                    <span className="font-semibold">Symptômes :</span>{' '}
                                    {consultation.symptomes?.map(symptome => <Tag className="mr-2">{symptome.observation}</Tag>)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <Pagination
                    className="mt-4 flex justify-end"
                    current={consultationsPage}
                    pageSize={pageSize}
                    total={consultations?.length}
                    onChange={handleConsultationsPageChange}
                />
            </div>
        );
    };

    const renderSoins = () => {
        const startIndex = (soinsPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const soinsToShow = soins?.slice(startIndex, endIndex);

        return (
            <div className="space-y-4">
                {soinsToShow?.map((soin, index) => (
                    <div key={index} className="bg-gray-200 p-4 rounded-lg shadow-sm">
                        <div className="flex flex-col">
                            <div className="my-1">
                                <span className="font-bold">Soin :</span>{' '}
                                {soin.typeSoin?.nom}
                            </div>
                            <div className="my-1">
                                <span className="font-semibold">Service :</span>{' '}
                                {soin.service?.nom}
                            </div>
                            <div className="my-1">
                                <span className="font-semibold">Observation :</span>{' '}
                                {soin.observation}
                            </div>
                        </div>
                    </div>
                ))}
                <Pagination
                    className="mt-4 flex justify-end"
                    current={soinsPage}
                    pageSize={pageSize}
                    total={soins?.length}
                    onChange={handleSoinsPageChange}
                />
            </div>
        );
    };

    const renderLabResults = () => {
        const startIndex = (labResultsPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const labResultsToShow = bonExamens?.slice(startIndex, endIndex);

        return (
            <div className="space-y-4">
                {labResultsToShow?.map((bonExamen, index) => (
                    <div key={index} className="bg-gray-200 p-4 rounded-lg shadow-sm">
                        <div className="font-bold">
                            Bon d'examen
                        </div>
                        {
                            bonExamen.examens?.map((examen, index) => (
                                <div className="flex my-2">
                                    <div className="mr-2">
                                        <span className="font-semibold">Examen :</span>{' '}
                                        {examen.nom}
                                    </div>
                                    <div>
                                        <span className="font-semibold">Résultat :</span>{' '}
                                        {examen.resultat}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ))}
                <Pagination
                    className="mt-4 flex justify-end"
                    current={labResultsPage}
                    pageSize={pageSize}
                    total={bonExamens?.length}
                    onChange={handleLabResultsPageChange}
                />
            </div>
        );
    };


    return (
        <div className="my-8">
            <h2 className="text-xl font-bold mb-4">Historique médical</h2>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Consultations" key="1">
                    <div className="space-y-4">
                        {renderConsultations()}
                    </div>
                </TabPane>
                <TabPane tab="Examens biologiques" key="2">
                    <div className="space-y-4">
                        {renderLabResults()}
                    </div>
                </TabPane>
                <TabPane tab="Soins" key="3">
                    <div className="space-y-4">
                        {renderSoins()}
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default HistoriqueMedicalSection;