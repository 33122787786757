import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Form, FormInstance, Input, Modal, Row, Select} from 'antd';
import {OrdonnanceType} from "../../models/Ordonnance";
import {useExamen, usePatient, useService} from "../../api";
import {emptyFieldError, getFullName} from "../../utilities/Utils";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import PatientAutoComplete from "../patient/PatientAutoComplete";

interface CollectionCreateFormProps {
    open: boolean;
    onCreate: (values: OrdonnanceType, update: boolean) => void;
    onCancel: () => void;
    form: FormInstance<OrdonnanceType>;
    updated: boolean
}

interface OptionValue {
    value: number,
    label: string
}
export const OrdonnanceFormModal: React.FC<CollectionCreateFormProps> = ({
                                                                            open,
                                                                            onCreate,
                                                                            onCancel,
                                                                            form,
                                                                            updated
                                                                        }) => {
    const {data: services} = useService();
    const [serviceOption, setServiceOption] = useState<OptionValue[]>();
    const [isPrescription, setIsPrescription] = useState(false);

    useEffect(() => {
        const opt: OptionValue[] = [];
        services.forEach(service => opt.push({value: service.id, label: service.nom}));
        setServiceOption([...opt]);
    }, [services]);
    
    const onSelect = (nom: string) => {
        form.setFieldValue("patient", nom)
    }

    const onClear = () => {
        form.setFieldValue("patient", null);
    }

    return (
        <Modal
            open={open}
            title={updated ? "Mise à jour de l'ordonnance" : "Prescription"}
            okText={updated ? "Modifier" : "Enregistrer"}
            cancelText="Annuler"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onCreate(values, updated);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
            okButtonProps={{ disabled: !isPrescription && !updated }}
            maskClosable={false}
            width={900}

        >
            <Form
                form={form}
                name="ordonnance"
                layout={"vertical"}
            >
                <Form.Item name="id" hidden={true}><Input /></Form.Item>
                        <Form.Item label="Patient"
                                   name="patient"
                                   rules={[{ required: true, message: "Patient requis!" }]}
                        >
                            <PatientAutoComplete onSelect={onSelect} onClear={onClear}/>
                        </Form.Item>
                <Form.Item
                    label="Service"
                    name='service'
                    rules={[{required: true, message: emptyFieldError}]}
                >
                    <Select options={serviceOption}/>
                </Form.Item>
                <Form.List name="prescriptions">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Row key={key} className="w-full" align={"middle"} gutter={10}>
                                    <Col xs={12} sm={12} md={8}>
                                        <Form.Item
                                            {...restField}
                                            label="Médicament"
                                            name={[name, 'medicament']}
                                            rules={[{ required: true, message: emptyFieldError }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <Form.Item
                                            {...restField}
                                            label="Dose"
                                            name={[name, 'dose']}
                                            rules={[{ required: true, message: emptyFieldError }]}
                                        >
                                            <Input placeholder="2 comprimés" />
                                        </Form.Item>
                                    </Col>
                                    <Col  xs={10} sm={10} md={4}>
                                        <Form.Item
                                            {...restField}
                                            label="Nombre Prise"
                                            name={[name, 'nbPriseParJour']}
                                            rules={[{ required: true, message: emptyFieldError }]}
                                        >
                                            <Input placeholder="2"  suffix="Jour"/>
                                        </Form.Item>
                                    </Col>
                                    <Col  xs={10} sm={10} md={4}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'dureeTraitement']}
                                            label="Durée traitement"
                                            rules={[{ required: true, message: emptyFieldError }]}
                                        >
                                            <Input placeholder="2 jours" />
                                        </Form.Item>
                                    </Col>
                                    <Col  span={2} className="flex justify-center">
                                        <MinusCircleOutlined className="text-secondary" style={{fontSize: 20}} onClick={() => {
                                            remove(name);
                                            setIsPrescription((fields.length - 1) > 0);
                                        }} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" className="hover:text-primary hover:border-primary" onClick={() => {
                                    add()
                                    setIsPrescription(fields.length >= 0);
                                }} block icon={<PlusOutlined />}>
                                    Ajouter une prescription
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};