import dayjs from 'dayjs';
import {PatientType} from "../models/Patient";
import {PersonnelMedicalType} from "../models/PersonnelMedical";
import {AntecedentType, TypeAntecedentType} from "../models/Antecedent";
import {MessageInstance} from "antd/lib/message/interface";

export enum EtatOperation {
    EN_ATTENTE = "EN_ATTENTE",
    EN_COURS = "EN_COURS",
    ANNULEE = "ANNULEE",
    TERMINEE = "TERMINEE"
}

export const getEtatOperationColorAndName = (etat: string, masculin: boolean = false) => {
    let color = ""
    let name = ""
    switch (etat?.toLowerCase()) {
        case EtatOperation.TERMINEE.toLowerCase():
            color = "success";
            name = `Terminé${masculin ? "" : "e"}`;
            break;
        case EtatOperation.EN_ATTENTE.toLowerCase():
            color = "warning";
            name = "En attente";
            break;
        case EtatOperation.EN_COURS.toLowerCase():
            color = "processing";
            name = "En cours";
            break;
        case EtatOperation.ANNULEE.toLowerCase():
            color = "error";
            name = `Annulé${masculin ? "" : "e"}`;
    }
    return {
        "color": color,
        "name": name
    };
}

export enum EtatFacture {
    PAYEE = "PAYEE",
    NON_PAYEE = "NON_PAYEE",
    PAYEE_EN_PARTIE = "PAYEE_EN_PARTIE",
}

export const getEtatFactureColorAndName = (etat: string, masculin: boolean = false) => {
    let color = ""
    let name = ""
    switch (etat?.toLowerCase()) {
        case EtatFacture.PAYEE.toLowerCase():
            color = "success";
            name = `Payé${masculin ? "" : "e"}`;
            break;
        case EtatFacture.NON_PAYEE.toLowerCase():
            color = "error";
            name = `Non payé${masculin ? "" : "e"}`;
            break;
        case EtatFacture.PAYEE_EN_PARTIE.toLowerCase():
            color = "processing";
            name = `Payé${masculin ? "" : "e"} en partie`;
            break;
    }
    return {
        "color": color,
        "name": name
    };
}


export interface FilterType {
    [key: string]: string;
}
export const emptyFieldError = "Ce champ est requis";

export const truncate = (str: string, len: number) => {
    return str?.length > len ? str.substring(0, (len - 5)) + "..." : str;
}

export  const getAge = (date: Date) =>  dayjs().diff(dayjs(dayjs(date).format("DD-MM-YYYY"), "DD-MM-YYYY"), 'years');

export const getValidDate = (date: Date, format?: string) => {
    return dayjs(date).format(format ? format : "DD MMMM YYYY")
}

export const getFullName = (user: PatientType | PersonnelMedicalType | undefined) => (`${user?.nom} ${user?.prenom}`)

export const tagsToAntecedents = (tags: string[] = [], type: TypeAntecedentType) : [] => {
    let antecedentsWithType : any = [];
    antecedentsWithType = tags?.map(antecedent => ({
        nom: antecedent,
        type: type
    }));
    return antecedentsWithType;
}

export const antecedentsToTags = (antecedents: AntecedentType[] = []) : string[] => {
    const tags: string[] = [];
    antecedents.forEach(antecedent => tags.push(antecedent.nom));
    return tags;
}

export const getAntecedentsByType = (antecedents: AntecedentType[] = [], antecedentType: TypeAntecedentType) : AntecedentType[] => {
    const antecedentsByType: AntecedentType[] = [];
    antecedents?.forEach((antecedent) => {
        if(antecedent.type.toLowerCase() === antecedentType.toLowerCase()) {
            antecedentsByType.push(antecedent)
        }
    });
    return  antecedentsByType;
}

const durationMessage = 5;
export const onSuccessResponse = (messageApi: MessageInstance, value: any, message: string) => {
    messageApi.open({
        type: "success",
        content: message,
        duration: durationMessage,
    });
}

export const onErrorResponse = (messageApi: MessageInstance, error: any, message: string) => {
    messageApi.open({
        type: "error",
        content: message,
        duration: durationMessage,
    });
}