import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3a0202'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        marginVertical: 0,
        paddingVertical: 0
    },
    desc: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        color: '#000',
        marginVertical: 0,
        paddingVertical: 0
    },
    qty: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
        color: '#000',
        marginVertical: 0,
        paddingVertical: 0
    },
    price: {
        width: '30%',
        textAlign: 'right',
        paddingRight: 8,
        color: '#000',
        marginVertical: 0,
        paddingVertical: 0
    },
});

export interface FactureItemsType {
    desc: string;
    price: number;
    qty: number;
}

interface FactureTabRowInterface {
    items?: FactureItemsType[]
}

const FactureTableRow = ({items}: FactureTabRowInterface) => {
    const rows = items?.map( (facture, index) =>
        <View style={styles.row} key={index}>
            <Text style={styles.desc}>{facture.desc}</Text>
            <Text style={styles.qty}>{facture.qty}</Text>
            <Text style={styles.price}>{facture.price} FCFA</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};

export default FactureTableRow