import {AutoComplete, Button, Col, Form, FormInstance, Input, Row, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import TextArea from "antd/lib/input/TextArea";
import {emptyFieldError} from "../../utilities/Utils";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useExamen, useSoin, useSymptome} from "../../api";
import {ConsultationType} from "../../models/Consultation";

interface InfoBasicConsultationPros {
    form: FormInstance<ConsultationType>
}
interface OptValue { value: string, label: string }
function InfoBasicConsultation({form}:InfoBasicConsultationPros) {
    const [symptomeOptions, setSymptomeOptions] = useState<OptValue[]>([]);
    const { customQuery: symptomeQuery } = useSymptome();

    useEffect(() => {
        symptomeQuery("/file").then((data: any) => {
            const ops: Set<OptValue> = new Set();
            data.forEach((option: string) => ops.add({value: option, label: option}));
            setSymptomeOptions(Array.from(ops));
        }).catch(error => console.log(error));

    }, []);

    return (
        <Form form={form} layout="vertical">
            <Form.Item
                label="Motif"
                name="motif"
                rules={[{ required: true, message:  emptyFieldError}]}
            >
                <TextArea rows={2} />
            </Form.Item>
            <h1 className="my-4">Symptômes</h1>
            <Form.List name="symptomes">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row key={key} className="w-full" align="top">
                                <Col span={22}>
                                    <Form.Item
                                        {...restField}
                                        label="Symptôme"
                                        name={[name, 'observation']}
                                        rules={[{ required: true, message: emptyFieldError }]}
                                    >
                                        <AutoComplete
                                            options={symptomeOptions}
                                            filterOption={(inputValue, option) =>
                                                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2} className="flex justify-center">
                                    <MinusCircleOutlined className="text-secondary" style={{fontSize: 20}} onClick={() => remove(name)} />
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button type="dashed" className="hover:text-primary hover:border-primary" onClick={() => add()} block icon={<PlusOutlined />}>
                                Ajouter un symptôme
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <Form.Item
                label="Diagnostic"
                name="diagnostic"
            >
                <TextArea rows={2} />
            </Form.Item>
        </Form>
    );
}

export default InfoBasicConsultation;